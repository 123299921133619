import React from "react";
import Card from "@mui/material/Card";
import {CardActionArea, CardActions, CardContent, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import {convertDateTimeToString, convertDateToAge} from "../../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons";
import {faCommentDots, faHeart as faHeartRegular} from "@fortawesome/free-regular-svg-icons";
import MKBox from "../../basic/MKBox";
import MKBadge from "../../basic/MKBadge";
import defaultAvatar from 'assets/images/defaults/default-avatar.jpg';

const ProfileList = ({active, profiles, likes, onLike, onAddFavorite, onViewProfile}) => {
    if (!profiles || profiles.length === 0) return null;

    return (
        <Grid container spacing={2}>
            {profiles.map((profile, index) => (
                <Grid item key={'profile-' + index} xs={12} md={6} lg={4} xl={3}>
                    <Card sx={{maxWidth: 345, m: "auto"}}>
                        <CardActionArea onClick={() => onViewProfile(profile)}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={process.env.REACT_APP_API_URL && profile.avatar
                                    ? (process.env.REACT_APP_API_URL + '/' + profile.avatar)
                                    : defaultAvatar}
                                alt={profile.firstname + "'s photo"}
                                sx={{m: 0, width: '100%', maxWidth: '100%', height: "auto", borderRadius: '.75rem .75rem 0 0'}}
                            />
                            <CardContent sx={{pb: 0}}>
                                <Grid display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6">
                                        {profile.firstname + ', ' + (profile.age ?? convertDateToAge(profile.birthday))}
                                    </Typography>
                                    <MKBox>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            height="auto"
                                            src={`https://flagcdn.com/w20/${profile.country.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${profile.country.code.toLowerCase()}.png 2x`}
                                            alt={profile.country.name}
                                        />
                                    </MKBox>
                                </Grid>
                                <Grid display="flex" justifyContent="space-between" alignItems="center">
                                    <MKBox color="text" sx={{fontSize: 14}}>
                                        <FontAwesomeIcon icon={faCamera}/> <Typography component="span"
                                                                                       sx={{fontSize: 14}}>{profile.photos.length} photos</Typography>
                                    </MKBox>
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                        <CardActions disableSpacing sx={{justifyContent: 'space-between'}}>
                            <Grid display="flex" alignItems="center">
                                <IconButton color="error" component="span" onClick={() => onLike(profile)}>
                                    <FontAwesomeIcon
                                        icon={likes && likes.includes(profile._id) ? faHeartSolid : faHeartRegular}
                                        style={{fontSize: 18}}/>
                                </IconButton>
                                <Typography fontSize={14}>{profile.liked?.length}</Typography>
                                <IconButton color="success" component="span" disabled={!active} onClick={() => onAddFavorite(profile)}>
                                    <FontAwesomeIcon icon={faCommentDots}
                                                     style={{fontSize: 18}}/>
                                </IconButton>
                            </Grid>
                            {profile.user?.userLastAction
                                ? convertDateTimeToString(new Date(profile.user.userLastAction.actionAt)) === 0
                                    ? <MKBadge badgeContent="online" size="xs" container color="success"/>
                                    : <Typography fontSize={13}>
                                        {convertDateTimeToString(new Date(profile.user?.userLastAction?.actionAt))}
                                    </Typography>
                                : null
                            }
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export default ProfileList;