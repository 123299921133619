import moment from "moment";
import Aquarius from 'assets/images/zodiacs/Aquarius.svg';
import Pisces from 'assets/images/zodiacs/Pisces.svg';
import Aries from 'assets/images/zodiacs/Aries.svg';
import Taurus from 'assets/images/zodiacs/Taurus.svg';
import Gemini from 'assets/images/zodiacs/Gemini.svg';
import Cancer from 'assets/images/zodiacs/Cancer.svg';
import Leo from 'assets/images/zodiacs/Leo.svg';
import Virgo from 'assets/images/zodiacs/Virgo.svg';
import Libra from 'assets/images/zodiacs/Libra.svg';
import Scorpio from 'assets/images/zodiacs/Scorpio.svg';
import Sagittarius from 'assets/images/zodiacs/Sagittarius.svg';
import Capricorn from 'assets/images/zodiacs/Capricorn.svg';

export const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}

export const convertDateToAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const convertDateTimeToString = (date) => {
    if (!date) return -1;
    const now = new Date();
    const difference = now - date;
    if (difference < 60000) return 0;
    if (difference < 120000) return 'Seen a minute ago';
    if (difference < 180000) return 'Seen 2 minutes ago';
    if (difference < 240000) return 'Seen 3 minutes ago';
    if (difference < 300000) return 'Seen 4 minutes ago';
    if (difference < 360000) return 'Seen 5 minutes ago';
    if (difference < 420000) return 'Seen 6 minute ago';
    if (difference < 480000) return 'Seen 7 minute ago';
    if (difference < 540000) return 'Seen 8 minutes ago';
    if (difference < 600000) return 'Seen 9 minutes ago';
    if (difference < 660000) return 'Seen 10 minutes ago';
    if (difference < 720000) return 'Seen 11 minutes ago';
    if (difference < 780000) return 'Seen 12 minutes ago';
    if (difference < 840000) return 'Seen 13 minutes ago';
    if (difference < 900000) return 'Seen 14 minutes ago';
    if (difference < 960000) return 'Seen 15 minutes ago';
    if (difference < 1020000) return 'Seen 16 minutes ago';
    if (difference < 1080000) return 'Seen 17 minutes ago';
    if (difference < 1140000) return 'Seen 18 minutes ago';
    if (difference < 1200000) return 'Seen 19 minutes ago';
    if (difference < 1260000) return 'Seen 20 minutes ago';
    if (difference < 1320000) return 'Seen 21 minutes ago';
    if (difference < 1380000) return 'Seen 22 minutes ago';
    if (difference < 1440000) return 'Seen 23 minutes ago';
    if (difference < 1500000) return 'Seen 24 minutes ago';
    if (difference < 1560000) return 'Seen 25 minutes ago';
    if (difference < 1620000) return 'Seen 26 minutes ago';
    if (difference < 1680000) return 'Seen 27 minutes ago';
    if (difference < 1740000) return 'Seen 28 minutes ago';
    if (difference < 1800000) return 'Seen 29 minutes ago';
    if (difference < 1860000) return 'Seen 30 minutes ago';
    if (now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && date.getDate() === now.getDate()) {
        return 'Seen today'
    }
    if (now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && (date.getDate() + 1) === now.getDate()) {
        return 'Seen yesterday'
    } else return `Seen ${moment(date).format('ll')}`;


}

export const convertDateToZodiacSign = (date) => {
    if (!date) return null;
    if (typeof date === 'string') date = new Date(date);
    const days = [21, 20, 21, 21, 22, 22, 23, 24, 24, 24, 23, 22];
    const signs = [
        {name: 'Aquarius', icon: Aquarius},
        {name: 'Pisces', icon: Pisces},
        {name: 'Aries', icon: Aries},
        {name: 'Taurus', icon: Taurus},
        {name: 'Gemini', icon: Gemini},
        {name: 'Cancer', icon: Cancer},
        {name: 'Leo', icon: Leo},
        {name: 'Virgo', icon: Virgo},
        {name: 'Libra', icon: Libra},
        {name: 'Scorpio', icon: Scorpio},
        {name: 'Sagittarius', icon: Sagittarius},
        {name: 'Capricorn', icon: Capricorn}
    ];
    let month = date.getMonth();
    let day = date.getDate();
    if (month === 0 && day <= 20) month = 11;
    else if (day < days[month]) month--;
    return signs[month];
}


export const toastConfig = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
}