import MKButton from "components/basic/MKButton";
import React, {useEffect} from "react";
import NoProfileInfo from "./NoProfileInfo";
import ProfileInfo from "./ProfileInfo";

const ViewProfile = ({profile, back, active, activities, onClickingOnActivity}) => {

    if (!profile) {
        return (<NoProfileInfo title="You dont have profile yet" btnLabel="create profile"/>)
    } else {
        return (
            <>
                {activities && <MKButton variant="outlined" color="dark" onClick={back} sx={{mb: 2}}>
                    Back
                </MKButton>}
                <ProfileInfo profile={profile} active={active} activities={activities} onClickingOnActivity={onClickingOnActivity}/>
            </>
            
        )
    }
}
export default ViewProfile;