import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import useServer from "../hooks/useServer";
import {PROFILE} from "../serverUrls";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import {toast, ToastContainer} from "react-toastify";
import {toastConfig} from "../utils/utils";
import ViewProfile from "../components/sections/myProfile/ViewProfile/ViewProfile";
import bgImage from "../assets/images/backgrounds/bg-search2.jpg";
import MKBox from "../components/basic/MKBox";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import {AuthContext} from "../contexts/auth/AuthContext";

const ProfilePage = () => {
    const params = useParams();
    const [profile, setProfile] = useState();
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [profileResponse, profileError, profileIsLoading, profileSendRequest, profileSetError] = useServer(PROFILE + '/' + params?.id);
    const {getUser} = useContext(AuthContext);

    useEffect(() => {
        if (params.id) profileSendRequest({method: 'get', params: {id: params.id}});
    }, [])

    // On profile Response
    useEffect(() => {
        if (profileResponse) setProfile(profileResponse.data);
    }, [profileResponse])

    // On Profile Error
    useEffect(() => {
        if (profileError) {
            toast.error(profileError.data?.message ?? 'Profile not loaded', toastConfig);
            profileSetError(null);
        }
        return () => profileSetError(null);
    }, [profileError])

    // On Profile Loading
    useEffect(() => {
        if (profileIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [profileIsLoading])

    return (
        <div className='main'>
            <ToastContainer/>
            <MKBox
                minHeight='7em'
                width="100%"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.3),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
            </MKBox>
            <Container style={{height: '100%', padding: '0'}}>
                <Card sx={{
                    p: 2,
                    mx: 0,
                    mt: -2,
                    mb: 2,
                    boxShadow: ({boxShadows: {xxl}}) => xxl,
                }}
                >
                    {profile ?
                        <ViewProfile profile={profile} active={getUser()?.activated}/>
                        : null
                    }
                </Card>
            </Container>
        </div>
    );
};

export default ProfilePage;