import PropTypes from "prop-types";
import MKBox from "components/basic/MKBox";
import MKButton from "components/basic/MKButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faInfo} from "@fortawesome/free-solid-svg-icons";
import MKTypography from "../../basic/MKTypography";
import {Link} from "react-router-dom";

function RotatingCard({ color, profile, startChat, active }) {
  return (
    <MKBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="lg"
      coloredShadow={color}
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={5}
      sx={{
        backgroundImage: `url(${process.env.REACT_APP_API_URL}/${profile.avatar})`,
        backgroundSize: "contain",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backfaceVisibility: "hidden",
        transform: "rotateY(180deg)",
      }}
    >
      <MKBox height="420px" display="flex" flexDirection="column" justifyContent="center" p={3} width="100%">
        <MKBox mt="auto">
          <MKTypography textAlign="center" variant="subtitle1">You can</MKTypography>
          <MKBox display="flex" justifyContent="space-between"  mt={1} mb={2}>
            <MKButton variant="outlined" size="small" disabled={!active} color="success" onClick={() => startChat(profile._id)}>
              <FontAwesomeIcon icon={faComment} style={{marginRight: '8px'}}/>
              Start Chat
            </MKButton>
            <Link to={`/profile/${profile._id}`} target="_blank">
            <MKButton variant="outlined" size="small" color="success">
              <FontAwesomeIcon icon={faInfo} style={{marginRight: '8px'}}/>
              View Profile
            </MKButton></Link>
          </MKBox>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the RotatingCard
RotatingCard.defaultProps = {
  color: "info",
};

// Typechecking props for the RotatingCard
RotatingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  profile: PropTypes.object.isRequired
};

export default RotatingCard;
