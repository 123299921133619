import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import MKBox from "components/basic/MKBox";
import TopNavbarDropdown from "components/blocks/TopNavbar/TopNavbarDropdown";

function TopNavbarMobile({routes, open, onClick}) {
    const renderNavbarItems = routes.map(
        ({name, icon, href, route}) => (
            <TopNavbarDropdown
                key={name + href}
                name={name}
                icon={icon}
                href={href}
                route={route}
                onClick={onClick}
            >
            </TopNavbarDropdown>
        )
    );

    return (
        <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
            <MKBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
                {renderNavbarItems}
            </MKBox>
        </Collapse>
    );
}

// Typechecking props for the DefaultNavbarMobile
TopNavbarMobile.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default TopNavbarMobile;
