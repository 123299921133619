import React, {useContext, useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from "@mui/icons-material/Close";
import {
    Autocomplete,
    ButtonGroup,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    Modal,
    Radio,
    RadioGroup,
    Slide
} from "@mui/material";
import Divider from "@mui/material/Divider";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import MKInput from "components/basic/MKInput";
import MKButton from "components/basic/MKButton";
import PhotoCropper from "../../../blocks/PhotoCropper/PhotoCropper";
import MKDatePicker from "../../../basic/MKDatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faArrowsAltV,
    faBaby,
    faBirthdayCake,
    faBriefcase,
    faBullseye,
    faEdit,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFlag,
    faGlassMartiniAlt,
    faGlobe,
    faGraduationCap,
    faGuitar,
    faLanguage,
    faLink,
    faPaintBrush,
    faPrayingHands,
    faRing,
    faSmoking,
    faTrash,
    faUserCircle,
    faWeight
} from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import 'react-drop-zone/dist/styles.css'
import {Link, useNavigate} from 'react-router-dom';
import PhotoContent from "./PhotoContent";
import {StyledDropZone} from "react-drop-zone";
import {dataURItoBlob, toastConfig} from "../../../../utils/utils";
import moment from "moment";
import useServer from "../../../../hooks/useServer";
import {GET_COMMON_DATA, PROFILE} from "../../../../serverUrls";
import {SpinnerContext} from "../../../../contexts/spinner/Spinner";
import FormData from "form-data";
import {AuthContext} from "../../../../contexts/auth/AuthContext";
import {toast, ToastContainer} from "react-toastify";
import Card from "@mui/material/Card";
import {heightLimits, weightLimits} from "../../../../utils/constants";

const initializeForm = () => {
    return {
        firstname: {touched: false, value: '', valid: false, required: true, maxLength: 30, visible: true},
        lastname: {touched: false, value: '', valid: false, required: true, maxLength: 30, visible: false},
        languages: {touched: false, value: [], valid: false, required: true, visible: true},
        birthday: {touched: false, value: '', valid: false, required: true, visible: false},
        country: {touched: false, value: null, valid: false, required: true, visible: true},
        city: {touched: false, value: '', valid: false, maxLength: 30, visible: true},
        gender: {touched: false, value: '', valid: false, required: true, visible: true},
        seeking: {touched: false, value: '', valid: false, required: true, visible: true},
        goal: {touched: false, value: null, valid: false, required: true, visible: true},
        source: {touched: false, value: null, valid: false, required: true, visible: false},
        maritalStatus: {touched: false, value: null, valid: false, required: true, visible: true},
        childrenAmount: {touched: false, value: null, valid: false, required: true, visible: true},
        education: {touched: false, value: null, valid: false, required: true, visible: true},
        occupation: {touched: false, value: '', valid: false, required: true, maxLength: 30, visible: true},
        height: {touched: false, value: '', valid: false, required: true, visible: true, minValue: heightLimits[0], maxValue: heightLimits[1]},
        weight: {touched: false, value: '', valid: false, required: true, visible: true, minValue: weightLimits[0], maxValue: weightLimits[1]},
        hairColor: {touched: false, value: null, valid: false, required: true, visible: true},
        eyeColor: {touched: false, value: null, valid: false, required: true, visible: true},
        smokingHabit: {touched: false, value: null, valid: false, required: true, visible: true},
        drinkingHabit: {touched: false, value: null, valid: false, required: true, visible: true},
        religion: {touched: false, value: null, valid: false, required: true, visible: true},
        hobbies: {touched: false, value: [], valid: false, required: true, visible: true},
        about: {touched: false, value: '', valid: false, maxLength: 1000, required: true, visible: true},
        photos: {touched: false, value: [], valid: false, visible: true},
        avatar: {touched: false, value: '', valid: false, required: true, visible: true}
    };
}

const profileToForm = profile => {
    let returnedForm = initializeForm();
    Object.keys(returnedForm).forEach(item => {
        if (profile[item]) {
            returnedForm[item].value = profile[item];
        }
    });
    return returnedForm;
}

const ProfileForm = ({inputProfile}) => {
    const [form, setForm] = useState(inputProfile ?
        () => profileToForm(inputProfile) : () => initializeForm());
    const [preview, setPreview] = useState();
    const [avatar, setAvatar] = useState(null);
    const [show, setShow] = useState(false);
    const [editImageIndex, setEditImageIndex] = useState();
    const [errorsCount, setErrorsCount] = useState(0);
    const [agree, setAgree] = useState(true);
    const [submitProfile, setSubmitProfile] = useState();
    const [commonData, setCommonData] = useState();
    const navigate = useNavigate();
    const {user, setUser} = useContext(AuthContext);
    const title = inputProfile ? 'Edit Profile' : 'Create Profile';
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [commonDataResponse, commonDataError, commonDataIsLoading, commonDataSendRequest, commonDataSetError] = useServer(GET_COMMON_DATA);
    const [postProfileResponse, postProfileError, postProfileIsLoading, postProfileSendRequest, postProfileSetError] = useServer(PROFILE);
    const [updateProfileResponse, updateProfileError, updateProfileIsLoading, updateProfileSendRequest, updateProfileSetError] = useServer(PROFILE + '/' + (inputProfile ? inputProfile._id : ''));

    const formToFormData = form => {
        let formData = new FormData();
        Object.keys(form).forEach(item => {
            if (form[item].value) {
                if (typeof form[item].value === "object" && item !== 'photos' && item !== 'avatar' && item !== 'birthday') {
                    formData.append(item, JSON.stringify(form[item].value));
                } else if (item === 'photos') {
                    form[item].value.forEach(photo => {
                        formData.append("photos", photo);
                    })
                } else {
                    formData.append(item, form[item].value)
                }
            }
        })
        return formData;
    }

    const toggleModal = () => setShow(!show);

    const validateField = (field, value) => {
        field = {...field, touched: true, value, valid: true};
        if (field.error) delete field.error;

        if (field.maxLength) {
            if (value.length > field.maxLength) {
                field.valid = false;
                field.error = `Max length is ${field.maxLength} (current is ${value.length})!`;
            }
        }

        if (field.email) {
            let isMatch = value.match(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
            if (!isMatch) {
                field.valid = false;
                field.error = 'Email is not valid!'
            }
        }

        if (field.required) {
            if (!value || value.length === 0) {
                field.valid = false;
                field.error = 'Required!'
            }
        }

        if (field.minValue) {
            if (value < field.minValue) {
                field.valid = false;
                field.error = `Value is less then ${field.minValue}!`
            }
        }

        if (field.maxValue) {
            if (value > field.maxValue) {
                field.valid = false;
                field.error = `Value is greater then ${field.maxValue}!`
            }
        }

        return field;
    }

    const handleChangeInput = e => {
        e.preventDefault();
        const formFieldName = e.target.name;
        const formFieldValue = e.target.value;
        const newForm = {...form};
        if (formFieldName === 'gender' || formFieldName === 'seeking') {
            const value = commonData.genders ? commonData.genders.find(item => item._id === formFieldValue) : '';
            newForm[formFieldName] = validateField(newForm[formFieldName], value);
        } else newForm[formFieldName] = validateField(newForm[formFieldName], formFieldValue);
        setForm(() => (newForm));
    }

    const handleChangeDate = (date) => {
        const newForm = {...form};
        const newDate = validateField(newForm.birthday, date);
        setForm(prevForm => ({...prevForm, birthday: newDate}));
    }

    const handleChangeAutocomplete = (e, value, name) => {
        const newForm = {...form};
        newForm[name] = {
            ...newForm[name], touched: true, valid: true,
            value: (value instanceof Array) ? value.map(val => val) : value
        };

        if (newForm[name].required && (!value || value.length === 0)) {
            newForm[name].valid = false;
            newForm[name].value = null;
            newForm[name].error = "Required!";
        }
        if (value && newForm[name].error) {
            delete newForm[name].error;
        }
        setForm(() => newForm);
    }

    const handleSubmitForm = e => {
        e.preventDefault();
        const newForm = {...form};
        let errors = 0;
        let touches = 0;
        Object.keys(newForm).forEach(key => {
            if (newForm[key].touched) touches++;
            newForm[key] = validateField(newForm[key], newForm[key].value);
            if (newForm[key].error) errors++;
        })
        if (touches === 0) {
            toast.warning('Profile has NOT changed', toastConfig);
        } else {
            setForm(newForm);
            setErrorsCount(errors);
            if (errors === 0) {
                setSubmitProfile(formToFormData(newForm));
            }
        }
    }

    const changePhotos = value => {
        const newValue = [...form.photos.value];
        // delete photo by number
        if (typeof value === 'number') {
            newValue.splice(value, 1);
        }
        // add file to photos
        if (typeof value === 'object') {
            Object.values(value).map(file => {
                if (file.type.includes("image") && newValue.length < 10) {
                    newValue.push(file)
                }
            });
        }
        setForm({...form, photos: {...form.photos, value: newValue, touched: true}});
    }

    const changeAvatar = file => {
        if (!file || file.type.includes("image")) {
            const newForm = {...form};
            newForm.avatar = validateField(newForm.avatar, file ?? '');
            setForm(newForm);
        }
    }

    const editImage = dataURI => {
        const blob = dataURItoBlob(dataURI);
        if (typeof editImageIndex === 'number') {
            setPreview(prevPreview => {
                prevPreview[editImageIndex] = dataURI;
                return prevPreview;
            })
            const file = new File(
                [blob],
                form.photos.value[editImageIndex].name ?? `photo-${editImageIndex}`,
                {type: form.photos.value[editImageIndex].type ?? (dataURI.split(";")[0]).substr(5)}
            );
            setForm(prevProfile => {
                prevProfile.photos.value[editImageIndex] = file;
                prevProfile.photos.touched = true;
                return prevProfile;
            });
        } else if (editImageIndex === 'avatar') {
            const file = new File(
                [blob],
                form.avatar.value.name ?? 'avatar',
                {type: form.avatar.value.type ?? (dataURI.split(";")[0]).substr(5)}
            );
            setForm(prevProfile => {
                prevProfile.avatar.value = file;
                prevProfile.avatar.touched = true;
                return prevProfile;
            })
        }
        toggleModal();
    }

    const showModal = index => {
        setEditImageIndex(index);
        toggleModal();
    }

    const closeCropper = () => {
        if (editImageIndex === 'avatar') {
            changeAvatar();
        }
        toggleModal();
    }

    // Create / delete preview
    useEffect(() => {
        if (form.photos.value.length === 0) {
            setPreview(null)
            return;
        }
        let objectUrls = [];
        form.photos.value.map(photo => {
            if (typeof photo === 'string') {
                objectUrls.push(process.env.REACT_APP_API_URL + '/' + photo);
            } else {
                objectUrls.push(URL.createObjectURL(photo));
            }
        });
        setPreview(objectUrls);
        return () => {
            objectUrls.map(photo => {
                if (typeof photo !== 'string') URL.revokeObjectURL(photo);
            })
        };
    }, [form.photos?.value])

    // Create / delete avatar
    useEffect(() => {
        if (form.avatar.value.length === 0) {
            if (avatar) setAvatar(null);
            return;
        }

        let objectUrl = typeof form.avatar.value === 'string'
            ? process.env.REACT_APP_API_URL + '/' + form.avatar.value
            : URL.createObjectURL(form.avatar.value);
        setAvatar(prevAvatar => {
            if (!prevAvatar && typeof form.avatar.value !== 'string') showModal('avatar');
            return objectUrl
        });

        return () => {
            if (typeof form.avatar.value !== 'string') URL.revokeObjectURL(objectUrl);
        }
    }, [form.avatar?.value])

    // On create / edit profile send request
    useEffect(() => {
        if (submitProfile) {
            if (inputProfile) {
                updateProfileSendRequest({
                    method: 'patch',
                    data: submitProfile
                });
            } else {
                postProfileSendRequest({
                    method: 'post',
                    data: submitProfile
                })
            }
        }
    }, [submitProfile])

    // On create profile receive response
    useEffect(() => {
        if (!postProfileResponse) return;
        let newUser = JSON.parse(user);
        newUser.profile = postProfileResponse.data;
        setUser(JSON.stringify(newUser));
        navigate('/my-profile', {state: postProfileResponse.data});
    }, [postProfileResponse])

    // On update profile receive response
    useEffect(() => {
        if (!updateProfileResponse) return;
        navigate('/my-profile', {state: updateProfileResponse.data});
    }, [updateProfileResponse])

    // On get all data send request
    useEffect(() => commonDataSendRequest({method: 'get', data: null}), [])

    // On get all data receive response
    useEffect(() => {
        if (commonDataResponse) setCommonData(commonDataResponse.data);
    }, [commonDataResponse])

    // On Errors
    useEffect(() => {
        if (commonDataError) {
            toast.error(commonDataError.data?.message ?? ((typeof commonDataError === 'string')
                ? commonDataError : 'Something went wrong'), toastConfig);
            commonDataSetError(null);
        }
        if (updateProfileError) {
            toast.error(updateProfileError.data?.message ?? ((typeof updateProfileError === 'string') ?
                updateProfileError : 'Something went wrong'), toastConfig);
            updateProfileSetError(null);
        }
        if (postProfileError) {
            toast.error(postProfileError.data?.message ?? ((typeof postProfileError === 'string') ?
                postProfileError : 'Something went wrong'), toastConfig);
            postProfileSetError(null);
        }
        return () => {
            commonDataSetError(null);
            updateProfileSetError(null);
            postProfileSetError(null);
        }
    }, [commonDataError, updateProfileError, postProfileError])

    // On Loading
    useEffect(() => {
        if (commonDataIsLoading || updateProfileIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);

        return () => setShowSpinner(false);
    }, [commonDataIsLoading, updateProfileIsLoading])

    if (!form || !commonData) return null;
    return (
        <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
            <Grid container item xs={12} justifyContent="center" flexDirection="column">
                <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <MKBox
                        shadow="lg"
                        borderRadius="xl"
                        bgColor="secondary"
                        variant="gradient"
                        coloredShadow="success"
                        sx={{
                            px: {xs: 6, sm: 10},
                            py: {xs: 2, sm: 1},
                            mb: {xs: 0, lg: 2},
                            mt: {xs: 0, lg: -4}
                        }}
                    >
                        <MKTypography variant="h4" textAlign="center" color="white">
                            Tell Us About You
                        </MKTypography>
                    </MKBox>
                    <MKTypography variant="overline">
                        * - required field, <FontAwesomeIcon icon={faEyeSlash}/> - invisible for others
                    </MKTypography>
                    <MKBox component="form" method="post" autoComplete="off">
                        <MKBox py={1} px={{xs: 3, md: 6, lg: 6, xl: 8}}>
                            <Grid container spacing={2} sx={{mb: 2}}>
                                {avatar && form.avatar.value
                                    ? (<Grid item xs={12} textAlign={'center'} marginBottom={3}>
                                        <MKBox
                                            component="img"
                                            src={avatar}
                                            alt="avatar"
                                            width="300px"
                                            borderRadius="lg"
                                            shadow="lg"
                                        />
                                        <ButtonGroup
                                            variant="outlined"
                                            size="small"
                                            style={{display: 'block', width: '200px', margin: "auto"}}
                                        >
                                            <MKButton size="small" variant="gradient" color="warning"
                                                      style={{width: "50%"}}
                                                      onClick={() => showModal('avatar')}
                                            >
                                                <FontAwesomeIcon icon={faEdit} className="me-2"/>Edit
                                            </MKButton>
                                            <MKButton size="small" variant="gradient" color="error"
                                                      style={{width: "50%"}}
                                                      onClick={() => changeAvatar()}
                                            >
                                                <FontAwesomeIcon icon={faTrash} className="me-2"/>Delete
                                            </MKButton>
                                        </ButtonGroup>
                                    </Grid>)
                                    : (<Grid item xs={12} textAlign={'center'} marginBottom={3}>
                                        <StyledDropZone
                                            accept="image/*"
                                            onDrop={file => changeAvatar(file)}
                                            children={"Add Profile Photo *"}
                                            className="avatar-dropzone"
                                        />
                                        {form.avatar.error && (
                                            <FormHelperText
                                                style={{textAlign: "center", marginTop: ".75rem"}}>
                                                <FontAwesomeIcon icon={faExclamationCircle}
                                                                 className="me-2"/>{form.avatar.error}
                                            </FormHelperText>
                                        )}
                                    </Grid>)
                                }

                                {/*  FirstName   LastName */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faUserCircle}/>
                                        <MKInput
                                            id="firstname"
                                            name="firstname"
                                            label={`First Name ${form.firstname.required ? '*' : ''}`}
                                            onChange={e => handleChangeInput(e)}
                                            value={form.firstname.value}
                                            success={form.firstname.touched && form.firstname.valid}
                                            error={form.firstname.touched && !form.firstname.valid}
                                            variant="standard"
                                            autoComplete="new-password"
                                            fullWidth
                                        />
                                        {!form.firstname.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.firstname.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faUserCircle}/>
                                        <MKInput
                                            id="lastname"
                                            name="lastname"
                                            label={`Last Name ${form.lastname.required ? '*' : ''}`}
                                            onChange={e => handleChangeInput(e)}
                                            value={form.lastname.value}
                                            success={form.lastname.touched && form.lastname.valid}
                                            error={form.lastname.touched && !form.lastname.valid}
                                            variant="standard"
                                            autoComplete="new-password"
                                            fullWidth
                                        />
                                        {!form.lastname.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.lastname.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*  Languages   Date of Birth*/}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faLanguage}/>
                                        <Autocomplete
                                            id="language"
                                            name="language"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'languages')}
                                            options={commonData?.languages}
                                            autoHighlight
                                            fullWidth
                                            multiple
                                            value={form.languages?.value}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option, {selected}) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                                        checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                                        style={{marginRight: 8}}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Languages ${form.languages.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.languages.touched && form.languages.valid}
                                                    error={form.languages.touched && !form.languages.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.languages.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.languages.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6} mt={'auto'} mb={0}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faBirthdayCake}/>
                                        <MKDatePicker
                                            id="birthday"
                                            name="birthday"
                                            onChange={date => handleChangeDate(date)}
                                            value={form.birthday.value}
                                            input={{
                                                label: `Birthday ${form.birthday.required ? '*' : ''}`,
                                                variant: 'standard',
                                                fullWidth: true,
                                                success: form.birthday.touched && form.birthday.valid,
                                                error: form.birthday.touched && !form.birthday.valid,
                                                value: form.birthday.value ? moment(new Date(form.birthday.value)).format('YYYY-MM-DD') : ''
                                            }}
                                        />
                                        {!form.birthday.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.birthday.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*  Country   City  */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faGlobe}/>
                                        <Autocomplete
                                            id="country"
                                            name="country"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'country')}
                                            options={commonData?.countries}
                                            autoHighlight
                                            fullWidth
                                            value={form.country?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={option.code ? `https://flagcdn.com/w20/${option.code.toLowerCase()}.png` : ''}
                                                        srcSet={option.code ? `https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x` : ''}
                                                        alt={option.name}
                                                    />
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    variant="standard"
                                                    success={form.country.touched && form.country.valid}
                                                    error={form.country.touched && !form.country.valid}
                                                    label={`Country ${form.country.required ? '*' : ''}`}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.country.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.country.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faFlag}/>
                                        <MKInput
                                            id="city"
                                            name="city"
                                            onChange={handleChangeInput}
                                            value={form.city.value}
                                            success={form.city.touched && form.city.valid}
                                            error={form.city.touched && !form.city.valid}
                                            variant="standard"
                                            InputProps={{autoComplete: "new-password"}}
                                            fullWidth
                                            label={`City ${form.city.required ? '*' : ''}`}
                                        />
                                        {!form.city.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.city.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*  Gender   Seeking  */}
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel className="formContext-label" htmlFor="gender">
                                            I am
                                            &nbsp;{form.gender.visible ? '' :
                                            <FontAwesomeIcon className="invisible-icon"
                                                             icon={faEyeSlash}/>}&nbsp;
                                            {form.gender.required ? '*' : ''}
                                        </FormLabel>
                                        <RadioGroup
                                            id="gender"
                                            name="gender"
                                            value={form.gender.value._id ?? ''}
                                            onChange={e => handleChangeInput(e)}
                                        >
                                            {commonData?.genders ? commonData.genders.map((item, index) => (
                                                    <FormControlLabel key={'gender' + index}
                                                                      value={item._id} control={<Radio/>}
                                                                      label={<Typography style={{
                                                                          fontSize: '0.8rem',
                                                                          display: 'inline',
                                                                          marginBottom: '0'
                                                                      }}>{item.name}</Typography>}/>
                                                ))
                                                : null
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                    <FormHelperText>{form.gender.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel className="formContext-label" htmlFor="seeking">
                                            Seeking
                                            &nbsp;{form.seeking.visible ? '' :
                                            <FontAwesomeIcon className="invisible-icon"
                                                             icon={faEyeSlash}/>}&nbsp;
                                            {form.seeking.required ? '*' : ''}
                                        </FormLabel>
                                        <RadioGroup
                                            id="seeking"
                                            name="seeking"
                                            value={form.seeking.value._id ?? ''}
                                            onChange={e => handleChangeInput(e)}
                                        >
                                            {commonData?.genders ? commonData.genders.map((item, index) => (
                                                    <FormControlLabel key={'seeking' + index}
                                                                      value={item._id} control={<Radio/>}
                                                                      label={<Typography style={{
                                                                          fontSize: '0.8rem',
                                                                          display: 'inline',
                                                                          marginBottom: '0'
                                                                      }}>{item.name}</Typography>}/>
                                                ))
                                                : null
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                    <FormHelperText>{form.seeking.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*  Goal  Source  */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faBullseye}/>
                                        <Autocomplete
                                            id="goal"
                                            name="goal"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'goal')}
                                            options={commonData?.goals}
                                            autoHighlight
                                            fullWidth
                                            value={form.goal?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    label={`Goal ${form.goal.required ? '*' : ''}`}
                                                    {...params}
                                                    variant="standard"
                                                    success={form.goal.touched && form.goal.valid}
                                                    error={form.goal.touched && !form.goal.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.goal.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.goal.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faLink}/>
                                        <Autocomplete
                                            id="source"
                                            name="source"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'source')}
                                            options={commonData?.sources ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.source?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Source ${form.source.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.source.touched && form.source.valid}
                                                    error={form.source.touched && !form.source.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />

                                        {!form.source.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.source.error ?? ''}</FormHelperText>
                                </Grid>

                                {/* MARITAL STATUS  Children  */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faRing}/>
                                        <Autocomplete
                                            id="maritalStatus"
                                            name="maritalStatus"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'maritalStatus')}
                                            options={commonData?.maritalStatuses ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.maritalStatus?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Marital Status ${form.maritalStatus.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.maritalStatus.touched && form.maritalStatus.valid}
                                                    error={form.maritalStatus.touched && !form.maritalStatus.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.maritalStatus.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.maritalStatus.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faBaby}/>
                                        <Autocomplete
                                            id="childrenAmount"
                                            name="childrenAmount"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'childrenAmount')}
                                            options={commonData?.children ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.childrenAmount?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Children ${form.childrenAmount.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.childrenAmount.touched && form.childrenAmount.valid}
                                                    error={form.childrenAmount.touched && !form.childrenAmount.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.childrenAmount.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.childrenAmount.error ?? ''}</FormHelperText>
                                </Grid>

                                {/* Education   Occupation  */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faGraduationCap}/>
                                        <Autocomplete
                                            id="education"
                                            name="education"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'education')}
                                            options={commonData?.educations ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.education?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Education ${form.education.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.education.touched && form.education.valid}
                                                    error={form.education.touched && !form.education.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.education.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.education.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faBriefcase}/>
                                        <MKInput
                                            id="occupation"
                                            name="occupation"
                                            label={`Occupation ${form.occupation.required ? '*' : ''}`}
                                            onChange={handleChangeInput}
                                            value={form.occupation?.value}
                                            success={form.occupation.touched && form.occupation.valid}
                                            error={form.occupation.touched && !form.occupation.valid}
                                            variant="standard"
                                            InputProps={{autoComplete: "new-password"}}
                                            fullWidth
                                        />
                                    </MKBox>

                                    <FormHelperText sx={{ml: 4}}>{form.occupation.error ?? ''}</FormHelperText>
                                </Grid>

                                {/* Height   Weight   */}
                                <Grid item xs={12} md={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faArrowsAltV}/>
                                        <MKInput
                                            id="height"
                                            name="height"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            label={`Height (cm) ${form.height?.required ? '*' : ''}`}
                                            onChange={e => handleChangeInput(e)}
                                            value={form.height?.value}
                                            success={form.height?.touched && form.height?.valid}
                                            error={form.height?.touched && !form.height?.valid}
                                            variant="standard"
                                            autoComplete="new-password"
                                            fullWidth
                                        />
                                        {!form.height.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.height?.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faWeight}/>
                                        <MKInput
                                            id="weight"
                                            name="weight"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            label={`Weight (kg) ${form.weight.required ? '*' : ''}`}
                                            onChange={e => handleChangeInput(e)}
                                            value={form.weight.value}
                                            success={form.weight?.touched && form.weight?.valid}
                                            error={form.weight?.touched && !form.weight?.valid}
                                            variant="standard"
                                            autoComplete="new-password"
                                            fullWidth
                                        />
                                        {!form.weight.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.weight.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*// HAIR COLOR     EYE COLOR   */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faPaintBrush}/>
                                        <Autocomplete
                                            id="hairColor"
                                            name="hairColor"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'hairColor')}
                                            options={commonData?.hairColors ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.hairColor?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Hair Color ${form.hairColor.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.hairColor.touched && form.hairColor.valid}
                                                    error={form.hairColor.touched && !form.hairColor.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.hairColor.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.hairColor.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faEye}/>
                                        <Autocomplete
                                            id="eyeColor"
                                            name="eyeColor"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'eyeColor')}
                                            options={commonData?.eyeColors ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.eyeColor?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Eye Color ${form.eyeColor.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.eyeColor.touched && form.eyeColor.valid}
                                                    error={form.eyeColor.touched && !form.eyeColor.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.eyeColor.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.eyeColor.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*// Smoking     Drinking Habit COLOR   */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faSmoking}/>
                                        <Autocomplete
                                            id="smokingHabit"
                                            name="smokingHabit"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'smokingHabit')}
                                            options={commonData?.smokingHabits ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.smokingHabit?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Smoking Habit ${form.smokingHabit.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.smokingHabit.touched && form.smokingHabit.valid}
                                                    error={form.smokingHabit.touched && !form.smokingHabit.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.smokingHabit.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.smokingHabit.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faGlassMartiniAlt}/>
                                        <Autocomplete
                                            id="drinkingHabit"
                                            name="drinkingHabit"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'drinkingHabit')}
                                            options={commonData?.drinkingHabits ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.drinkingHabit?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Drinking Habit ${form.drinkingHabit.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.drinkingHabit.touched && form.drinkingHabit.valid}
                                                    error={form.drinkingHabit.touched && !form.drinkingHabit.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.drinkingHabit.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.drinkingHabit.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*// Religion     Hobbies   */}
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faPrayingHands}/>
                                        <Autocomplete
                                            id="religion"
                                            name="religion"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'religion')}
                                            options={commonData?.religions ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.religion?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MKBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <FontAwesomeIcon icon={faArrowRight} className="me-1"/>
                                                    {option.name}
                                                </MKBox>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Religion ${form.religion.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.religion.touched && form.religion.valid}
                                                    error={form.religion.touched && !form.religion.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.religion.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>
                                    <FormHelperText sx={{ml: 4}}>{form.religion.error ?? ''}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MKBox sx={{display: 'flex', alignItems: 'flex-end', mt: 'auto'}}>
                                        <FontAwesomeIcon className="formContext-label" icon={faGuitar}/>
                                        <Autocomplete
                                            id="hobbies"
                                            name="hobbies"
                                            onChange={(e, value) => handleChangeAutocomplete(e, value, 'hobbies')}
                                            options={commonData?.hobbies ?? []}
                                            autoHighlight
                                            fullWidth
                                            value={form.hobbies?.value}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            multiple
                                            disableCloseOnSelect
                                            renderOption={(props, option, {selected}) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                                        checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                                        style={{marginRight: 8}}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) =>
                                                <MKInput
                                                    {...params}
                                                    label={`Hobbies ${form.hobbies.required ? '*' : ''}`}
                                                    variant="standard"
                                                    success={form.hobbies.touched && form.hobbies.valid}
                                                    error={form.hobbies.touched && !form.hobbies.valid}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        {!form.hobbies.visible &&
                                            <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}
                                    </MKBox>

                                    <FormHelperText sx={{ml: 4}}>{form.hobbies.error ?? ''}</FormHelperText>
                                </Grid>

                                {/*//     PHOTO   */}
                                <Grid item xs={12}>
                                    <StyledDropZone
                                        accept="image/*"
                                        multiple
                                        onDrop={files => changePhotos(files)}
                                        children={preview ? `Downloaded ${preview.length} of 10` : "Add Your Images (up to 10)"}
                                    />
                                    {preview && form.photos.value?.length > 0 &&
                                        <PhotoContent photos={preview} onCLick={changePhotos} show={showModal}/>}
                                </Grid>

                                {/*//     ABOUT ME   */}
                                <Grid item xs={12}>
                                    <InputLabel className="formContext-label" htmlFor="about">
                                        About Me
                                        &nbsp;{form.about.visible ? '' :
                                        <FontAwesomeIcon className="invisible-icon" icon={faEyeSlash}/>}&nbsp;
                                        {form.about.required ? '*' : ''}
                                    </InputLabel>
                                    <MKInput
                                        id="about"
                                        name="about"
                                        variant="standard"
                                        success={form.about.touched && form.about.valid}
                                        error={form.about.touched && !form.about.valid}
                                        rows={8}
                                        InputLabelProps={{shrink: true}}
                                        multiline
                                        fullWidth
                                        value={form.about.value ?? ''}
                                        onChange={e => handleChangeInput(e)}
                                    />
                                    <FormHelperText>{form.about.error ?? ''}</FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {/*  Agreement  */}
                                <Grid item xs={12} sx={{mb: 3, ml: -1}}>
                                    <Switch checked={agree} onChange={() => setAgree(!agree)}/>
                                    <MKTypography
                                        component="label"
                                        variant="button"
                                        color="text"
                                        fontWeight="regular"
                                        htmlFor="agree"
                                        sx={{userSelect: "none", cursor: "pointer"}}
                                    >
                                        I agree to the{" "}
                                        <MKTypography component={Link} to="/policy" target="_blank"
                                                      variant="button" fontWeight="regular">
                                            <u>Terms and Conditions</u>
                                        </MKTypography>
                                        .
                                    </MKTypography>
                                </Grid>
                                {/*  Errors  */}
                                <Grid item xs={12}>
                                    {errorsCount > 0 &&
                                        <Typography
                                            align="center"
                                            marginBottom={3}
                                            fontSize="1rem"
                                            color="red"
                                        >
                                            <FontAwesomeIcon icon={faExclamationTriangle}/> found
                                            errors: {errorsCount}
                                        </Typography>
                                    }
                                </Grid>
                                {/*  Submit Button  */}
                                <Grid item xs={12}>
                                    <MKButton type="button" variant="gradient" color="success" size="small"
                                              disabled={!agree || postProfileIsLoading}
                                              fullWidth onClick={e => handleSubmitForm(e)}>
                                        {title}
                                    </MKButton>
                                </Grid>
                            </Grid>
                        </MKBox>
                    </MKBox>
                </MKBox>

                <Modal open={show} onClose={closeCropper}
                       sx={{display: "grid", placeItems: "center", overflow: "scroll"}}>
                    <Slide direction="down" in={show} timeout={1000}>
                        <MKBox
                            position="relative"
                            width="90%"
                            display="flex"
                            flexDirection="column"
                            borderRadius="xl"
                            bgColor="white"
                            shadow="xl"
                        >
                            <MKBox display="flex" alginItems="center" justifyContent="space-between" px={2} py={1}>
                                <MKTypography variant="h5">Edit Image</MKTypography>
                                <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={closeCropper}/>
                            </MKBox>
                            <Divider sx={{my: 0}}/>

                            {((preview && preview.length > 0) || avatar) &&
                                <PhotoCropper
                                    photo={typeof editImageIndex === 'number' && preview ? preview[editImageIndex] : avatar}
                                    isAvatar={typeof editImageIndex !== 'number'}
                                    onClose={closeCropper}
                                    onSave={image => editImage(image)}/>}

                            <Divider sx={{my: 0}}/>
                        </MKBox>
                    </Slide>
                </Modal>
            </Grid>
        </Card>
    )
}

export default ProfileForm;