import {useCallback, useContext, useEffect, useState} from "react";
import axios from "axios";
import {AuthContext} from "../contexts/auth/AuthContext";

const useServer = url => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState({});
    const {logout} = useContext(AuthContext);

    const sendRequest = useCallback((options = {}) => {
        setOptions(options);
        setIsLoading(true);
    }, []);

    useEffect(() => {
        if (!isLoading) return;
        axios(url, options)
            .then(res => setResponse(res))
            .catch(err => setError(err.response ?? err))
            .finally(() => setIsLoading(false))
    }, [isLoading, options, url])

    useEffect(() => {
        if (!error) return;
        if (error.status && error.status === 401) logout();
    }, [error])
    return [response, error, isLoading, sendRequest, setError];
}

export default useServer;