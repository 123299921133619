import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import MKTypography from 'components/basic/MKTypography';
import MKBox from 'components/basic/MKBox';
import withStyles from '@material-ui/core/styles/withStyles';
import defaultChatMsgStyles from '@mui-treasury/styles/chatMsg/default';
import moment from 'moment';

const ChatMsg = withStyles(defaultChatMsgStyles, {name: 'ChatMsg'})(props => {
    const {
        classes,
        avatar,
        messages,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        getTypographyProps,
    } = props;
    const attachClass = index => {
        if (index === 0) {
            return classes[`${side}First`];
        }
        if (index === messages.length - 1) {
            return classes[`${side}Last`];
        }
        return '';
    };

    let lastDate;

    return (
        <Grid
            container
            spacing={2}
            justifyContent={side === 'right' ? 'flex-end' : 'flex-start'}
            {...GridContainerProps}
        >
            {side === 'left' && (
                <Grid item {...GridItemProps}>
                    <Avatar
                        src={avatar}
                        {...AvatarProps}
                        className={cx(classes.avatar, AvatarProps.className)}
                    />
                </Grid>
            )}
            <Grid item xs={8}>
                {messages?.map((msg, i) => {
                    const TypographyProps = getTypographyProps(msg, i, props);
                    let showDate;
                    if (i !== 0 && moment(lastDate).format('l') === moment(msg.date).format('l')) {
                        showDate = false;
                    } else showDate = true;
                    lastDate = msg.date;

                    return (
                        <div key={msg.id || i} className={classes[`${side}Row`]} style={{margin: '0!important'}}>
                            {showDate ?
                                <MKBox sx={{display: 'flex'}}>
                                    <MKTypography variant="button" color="text" fontWeight="bold"
                                                  sx={{
                                                      ml: side === 'right' ? {
                                                          xs: '0',
                                                          sm: '10px',
                                                          md: '20px',
                                                          lg: '30px',
                                                          xl: '40px'
                                                      } : 'auto',
                                                      mr: side === 'right' ? 'auto' : '102px',
                                                      my: 1,
                                                      border: '1px solid #c3c3c3',
                                                      borderRadius: '23px',
                                                      padding: '5px 10px'
                                                  }}>
                                        {moment(msg.date).calendar(null, {
                                            lastDay: '[Yesterday]',
                                            sameDay: '[Today]',
                                            nextDay: '[Tomorrow]',
                                            lastWeek: '[last] dddd',
                                            nextWeek: 'dddd',
                                            sameElse: 'L'
                                        })}</MKTypography>
                                </MKBox>
                                : ''
                            }
                            <MKBox display="flex" flexWrap="nowrap" justifyContent={side === 'right' ? 'end' : 'start'}>
                                {side === 'right'
                                    ? <MKTypography variant="overline" color="text" fontWeight="bold"
                                                    sx={{mx: 1, my: 'auto', whiteSpace: 'nowrap'}}>
                                        {moment(msg.date).format('h:mm a')}
                                    </MKTypography>
                                    : ''
                                }
                                <MKTypography
                                    align={'left'}
                                    {...TypographyProps}
                                    className={cx(
                                        classes.msg,
                                        classes[side],
                                        attachClass(i),
                                        TypographyProps.className
                                    )}
                                >
                                    {msg.img ? <img height="100" width="100"
                                                    src={process.env.REACT_APP_API_URL + '/' + msg.img}
                                                    alt="gift"/> : msg.text}
                                </MKTypography>

                                {side !== 'right'
                                    ? <MKTypography variant="overline" color="text" fontWeight="bold"
                                                    sx={{mx: 1, my: 'auto', whiteSpace: 'nowrap'}}>
                                        {moment(msg.date).format('h:mm a')}
                                    </MKTypography>
                                    : ''
                                }
                            </MKBox>
                        </div>
                    );
                })}
            </Grid>
        </Grid>
    );
});
ChatMsg.propTypes = {
    avatar: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.any),
    side: PropTypes.oneOf(['left', 'right']),
    GridContainerProps: PropTypes.shape({}),
    GridItemProps: PropTypes.shape({}),
    AvatarProps: PropTypes.shape({}),
    getTypographyProps: PropTypes.func,
};
ChatMsg.defaultProps = {
    avatar: '',
    messages: [],
    side: 'left',
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({}),
};
export default ChatMsg;