import React, {useContext, useEffect, useState} from 'react';
import TopNavbar from "../TopNavbar/TopNavbar";
import routes from "../../../routesForTopMenu";
import {useLocation} from "react-router-dom";
import {AuthContext} from "../../../contexts/auth/AuthContext";
import {links} from "../../../utils/constants";
import {toast} from "react-toastify";
import {NotificationContext} from "../../../contexts/notifications/NotificationContext";

const Header = () => {
    const [isTransparent, setIsTransparent] = useState(true);
    const location = useLocation();
    const isAuthPage = location.pathname === links.login || location.pathname === links.register;
    const isCommunicationPage = location.pathname === links.myProfile_communication;
    const {isAuthenticated, getUser} = useContext(AuthContext);
    const [topMenuRoutes, setTopMenuRoutes] = useState(routes);
    const {notification, setNotification, getNotification, notificationError, notificationSetError} = useContext(NotificationContext);

    // On change location -> send request to get notifications
    useEffect(() => {
        if (isAuthenticated && getUser()?.profile && location.pathname !== links.signOut) {
            getNotification();
        } else if (notification) setNotification({});
    }, [location])

    // On Error
    useEffect(() => {
        if (notificationError) {
            toast.error(notificationError.data?.message ?? 'Something went wrong');
            notificationSetError(null);
            return () => notificationSetError(null);
        }
    }, [notificationError])

    useEffect(() => {
        const scroll = () => {
            if (window.scrollY > 50) setIsTransparent(false)
            else setIsTransparent(true)
        }
        window.addEventListener('scroll', scroll);
        return () => {
            window.removeEventListener('scroll', scroll);
        }
    })

    useEffect(() => {
        if (isAuthenticated) {
            setTopMenuRoutes(() => routes.filter(route => {
                return route.href !== links.login && route.href !== links.register;
            }))
        } else {
            setTopMenuRoutes(() => routes.filter(route => {
                return route.href !== links.search &&
                    route.href !== links.subscriptions &&
                    route.href !== links.myProfile &&
                    route.href !== links.signOut;
            }))
        }
    }, [isAuthenticated])

    if (isAuthPage || isCommunicationPage) return null;
    return (
        <>
            <TopNavbar
                routes={topMenuRoutes}
                transparent={isTransparent}
                sticky
                light={isTransparent}
                notification={notification}
            />
        </>

    )
}

export default Header;