import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/basic/MKBox";
import HorizontalTeamCard from "components/cards/HorizontalTeamCard";
import {FadeLoader} from "react-spinners";
import React from "react";

function Team({ members }) {

  return (
    <MKBox
      component="section"
      position="relative"
      py={4}
    >
      <Container>
        <Grid container spacing={3}>

          {(members && members.length > 0) ? members.map((member, index) => (
            <Grid item xs={12} lg={6} key={'team-' + index}>
              <MKBox mb={1}>
                <HorizontalTeamCard
                  image={member.img ? process.env.REACT_APP_API_URL + '/' + member.img : ''}
                  name={member.name}
                  position={{ color: "info", label: member.position }}
                  description={member.description}
                />
              </MKBox>
            </Grid>
          ))
              :
              <MKBox textAlign="center" width="100%" my={20}>
                <FadeLoader radius={5}/>
              </MKBox>
          }
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
