import React, {useContext, useEffect, useState} from 'react';
import MKTypography from "../../../basic/MKTypography";
import {Container, Tab, Tabs} from "@mui/material";
import Grid from "@mui/material/Grid";
import MKBadge from "../../../basic/MKBadge";
import MKBox from "../../../basic/MKBox";
import MKButton from "../../../basic/MKButton";
import MKInput from "../../../basic/MKInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList, faMinus, faPlus, faShoppingCart, faTrash} from "@fortawesome/free-solid-svg-icons";
import useServer from "../../../../hooks/useServer";
import {MEMBERSHIP, PRODUCT, STRIPE_CHARGE} from "../../../../serverUrls";
import {SpinnerContext} from "../../../../contexts/spinner/Spinner";
import {toast} from "react-toastify";
import {toastConfig} from "../../../../utils/utils";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {useLocation, useNavigate} from "react-router-dom";
import Card from "@mui/material/Card";

const options = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
        base: {
            iconColor: "rgb(240, 57, 122)",
            color: "rgb(240, 57, 122)",
            fontSize: "16px",
            fontFamily: '"Open Sans", sans-serif',
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "#CFD7DF"
            }
        },
        invalid: {
            color: "#e5424d",
            ":focus": {
                color: "#303238"
            }
        }
    }
};

const Cart = () => {
    const [form, setForm] = useState([]);
    const [productsResponse, productsError, productsIsLoading, productsSendRequest, productsSetError] = useServer(PRODUCT);
    const [paymentResponse, paymentError, paymentIsLoading, paymentSendRequest, paymentSetError] = useServer(STRIPE_CHARGE);
    const [membershipsResponse, membershipsError, membershipsIsLoading, membershipsSendRequest, membershipsSetError] = useServer(MEMBERSHIP);

    const [list, setList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [activeTab, setActiveTab] = useState(0);
    const [membershipList, setMembershipList] = useState();
    const [membership, setMembership] = useState();
    const {state} = useLocation();

    const handleTabType = ({currentTarget}, newValue) => {
        setActiveTab(newValue);
    };

    const changeQty = (value, index, type) => {
        if (value < 0 || value > 100) return;
        if (type === 'form') {
            let newForm = [...form];
            newForm[index].qty = value;
            setForm(newForm);
        } else if (type === 'list') {
            let newList = [...list];
            if (value === 0 || value === '') {
                newList.filter(list => list._id !== newList[index]._id);
            } else newList[index].qty = value;
            setList(newList);
        }
    }

    const addToList = index => {
        if (index === null) {
            setMembership(membershipList[activeTab]);
            return;
        }

        if (form[index].qty === 0) return;
        let newList = [...list];
        let newForm = [...form];
        if (newList.length > 0 && newForm[index] && newList.find(order => order?._id === newForm[index]._id)) {
            newList.map(item => {
                if (item._id === newForm[index]._id) return item.qty += newForm[index].qty;
            });
            setList([...newList]);
        } else {
            newList.push({...form[index]});
            setList(newList);
        }
        resetForm();
    }

    const removeFromList = (inputItem) => {
        if (!inputItem && membership) setMembership(null);
        else setList(list.filter(item => item._id !== inputItem._id));
    }

    const resetForm = () => {
        if (form?.length > 0) form.map(input => input.qty = 0);
    }

    const payNow = async e => {
        e.preventDefault();
        if (!elements) return;
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (!error) paymentSendRequest({method: 'post', data: {membership, items: list, id: paymentMethod.id}});
        else toast.error(error.message, toastConfig);
    }

    // Calculate total amount
    useEffect(() => {
        if (list?.length === 0 && !membership) return setTotalAmount(0);
        let amount = 0;
        list.forEach(item => {
            amount += item.qty * item.price;
        })
        if (membership && membership.price && membership.months) amount += membership.price * membership.months;
        return setTotalAmount(Math.ceil(amount * 100) / 100);
    }, [list, membership])

    // Request to get products
    useEffect(() => {
        productsSendRequest({method: 'get'});
        membershipsSendRequest({method: 'get'});
    }, [])

    // On Product Response
    useEffect(() => {
        if (productsResponse) setForm(productsResponse.data.map(product => ({...product, qty: 0})));
    }, [productsResponse, paymentResponse, membershipsResponse])

    // On Payment Response
    useEffect(() => {
        if (paymentResponse) {
            navigate('/my-profile', {
                state:
                    {
                        path: 'balance',
                        myProfile: paymentResponse.data?.profile,
                        message: paymentResponse.data?.message
                    }
            });
        }
    }, [paymentResponse])

    // On Memberships Response
    useEffect(() => {
        if (membershipsResponse) setMembershipList(membershipsResponse.data.sort((a, b) => a.months - b.months));
    }, [membershipsResponse])

    // On Loading
    useEffect(() => {
        if (productsIsLoading || paymentIsLoading || membershipsIsLoading) setShowSpinner(true);
        else if (showSpinner === true) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [productsIsLoading, paymentIsLoading, membershipsIsLoading])

    // On Errors
    useEffect(() => {
        if (productsError) {
            toast.error(productsError.data?.message, toastConfig);
            productsSetError(null);
        }
        if (paymentError) {
            toast.error(paymentError.data?.message, toastConfig);
            paymentSetError(null);
        }
        if (membershipsError) {
            toast.error(membershipsError.data?.message, toastConfig);
            membershipsSetError(null);
        }

        return () => {
            productsSetError(null);
            paymentSetError(null);
            membershipsSetError(null);
        }
    }, [productsError, paymentError, membershipsError])

    // On state
    useEffect(() => {
        if (state) {
            if (state.membership) setMembership(state.membership);
            if (state.product) setList(prev => [...prev, {...state.product, qty: 1}]);
        }
    }, [state])

    return (
        <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
            <MKBox>
                <MKTypography variant="h4" color="text" fontWeight="bold" textAlign="center" sx={{my: 3}}>
                    <FontAwesomeIcon icon={faShoppingCart}/> My Cart
                </MKTypography>
                <Container>
                    {membership &&
                        <Grid container alignItems="center">
                            <Grid item xs={12} lg={7}>
                                <MKTypography variant="body2" color="text" fontWeight="bold">
                                    Membership for {membership?.months} month(s)
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12} lg={5} sx={{
                                ml: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <MKBox sx={{display: 'flex', alignItems: 'center'}}>
                                </MKBox>

                                <MKBox sx={{display: "flex"}}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold"
                                                  textAlign="end">
                                        ${membership.price && membership.months ? membership.price * membership.months : 0}

                                    </MKTypography>
                                    <MKButton size="small" variant="contained" color="light" iconOnly
                                              sx={{ml: 1}} onClick={() => removeFromList(null)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </MKButton>
                                </MKBox>
                            </Grid>
                        </Grid>
                    }
                    {list && list.length > 0 &&
                        <>
                            {list.map((item, index) => (
                                <Grid container alignItems="center" key={'order-' + index}>
                                    <Grid item xs={12} lg={7}>
                                        <MKTypography variant="body2" color="text" fontWeight="bold">
                                            {item.name}
                                        </MKTypography>
                                    </Grid>
                                    <Grid item xs={12} lg={5} sx={{
                                        ml: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <MKBox sx={{display: 'flex', alignItems: 'center'}}>
                                            <MKTypography variant="body2" color="text" fontWeight="bold"
                                                          sx={{ml: 'auto', mr: 3}}>
                                                QTY:
                                            </MKTypography>
                                            <MKBox sx={{display: "flex"}}>
                                                <MKButton size="small" variant="contained" color="light" iconOnly
                                                          onClick={() => changeQty(item.qty - 1, index, 'list')}
                                                >
                                                    <FontAwesomeIcon icon={faMinus}/>
                                                </MKButton>
                                                <MKInput type="number" value={item.qty !== 0 ? item.qty : ''}
                                                         variant="standard" sx={{width: '1.5em', mx: 1}}
                                                         onChange={e => changeQty(+e.target?.value, index, 'list')}/>
                                                <MKButton size="small" variant="contained" color="light" iconOnly
                                                          onClick={() => changeQty(item.qty + 1, index, 'list')}
                                                >
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </MKButton>
                                            </MKBox>
                                        </MKBox>

                                        <MKBox sx={{display: "flex"}}>
                                            <MKTypography variant="body2" color="text" fontWeight="bold"
                                                          textAlign="end">
                                                ${(item.price * item.qty).toFixed(2)}
                                            </MKTypography>
                                            <MKButton size="small" variant="contained" color="light" iconOnly
                                                      sx={{ml: 1}} onClick={() => removeFromList(item)}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </MKButton>
                                        </MKBox>
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    }
                    {totalAmount > 0
                        ? <>
                            <hr/>
                            <Grid container alignItems="center">
                                <Grid item xs={12}
                                      sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        Total amount:
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        ${totalAmount}
                                    </MKTypography>
                                </Grid>
                            </Grid>
                            <hr/>

                            <MKBox textAlign="end">
                                <form onSubmit={payNow}>
                                    <MKTypography variant="body2" textAlign="start">Card Details</MKTypography>
                                    <CardElement id="card-element" options={options}/>
                                    <MKButton
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="success"
                                    >
                                        Pay now
                                    </MKButton>
                                </form>
                            </MKBox>
                        </>
                        : <MKTypography variant="body2" textAlign="center">Empty</MKTypography>
                    }
                </Container>
            </MKBox>
            <MKBox>
                <MKTypography variant="h4" color="text" textAlign="center" fontWeight="bold" sx={{my: 3}}>
                    <FontAwesomeIcon icon={faClipboardList}/> Make Order
                </MKTypography>
                <Container>
                    {!membership &&
                        <Grid container alignItems="center">
                            <Grid item xs={12} lg={8} xl={9}>
                                <MKBox sx={{display: 'flex', alignItems: 'center'}}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        Premium membership
                                    </MKTypography>
                                    <Tabs value={activeTab} onChange={handleTabType}
                                          sx={{height: 'auto', ml: 'auto', mr: 2}}>

                                        {membershipList && membershipList.map((item, index) => (
                                            <Tab
                                                id={`${item.months}-${index}`}
                                                key={`${item.months}-${index}`}
                                                label={<MKBox py={0.2} px={1}
                                                              color="inherit">{item.months} mo</MKBox>}/>
                                        ))
                                        }
                                    </Tabs>
                                </MKBox>
                            </Grid>

                            <Grid item xs={12} lg={4} xl={3} sx={{ml: "auto"}}>
                                <MKBox ml="auto" sx={{
                                    py: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        ${membershipList?.length ? membershipList[activeTab]?.price * membershipList[activeTab]?.months : '0.00'}
                                    </MKTypography>

                                    <MKButton variant="gradient" size="small" color="warning"
                                              onClick={() => addToList(null)}
                                              sx={{boxShadow: "none"}}
                                              disabled={membership || !membershipList?.length}
                                    >
                                        Add to cart
                                    </MKButton>
                                </MKBox>
                            </Grid>

                        </Grid>
                    }
                    {form && form.length > 0 && form.map((product, index) => (
                        <Grid container alignItems="center" key={'product' + index}>
                            <Grid item xs={12} lg={8} xl={9}>
                                <MKBox sx={{display: 'flex', alignItems: 'center'}}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        {product.name}
                                    </MKTypography>
                                    <MKBadge badgeContent={product.price} container color="success" variant="contained"
                                             sx={{ml: 'auto', mr: 3}}/>
                                    x
                                    <MKInput
                                        type="number"
                                        value={product.qty !== 0 ? product.qty : ''}
                                        onChange={e => changeQty(+e.target?.value, index, 'form')}
                                        variant="standard"
                                        sx={{width: '50px', mx: 3}}
                                        InputProps={{inputProps: {min: 0, max: 100}}}
                                    />
                                </MKBox>
                            </Grid>

                            <Grid item xs={12} lg={4} xl={3} sx={{ml: "auto"}}>
                                <MKBox ml="auto" sx={{
                                    py: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        ${(product.price * product.qty).toFixed(2)}
                                    </MKTypography>

                                    <MKButton variant="gradient" size="small" color="warning"
                                              onClick={() => addToList(index)}
                                              sx={{boxShadow: "none"}}
                                              disabled={product.qty === 0}
                                    >
                                        Add to cart
                                    </MKButton>
                                </MKBox>
                            </Grid>

                        </Grid>

                    ))}
                </Container>
            </MKBox>

        </Card>

    );
};

export default Cart;