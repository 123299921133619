import React, {useEffect, useRef, useState} from 'react';
import {Backdrop, Fade, Modal} from "@mui/material";
import Box from "@mui/material/Box";
import MKTypography from "../../../basic/MKTypography";
import Stack from "@mui/material/Stack";
import MKInput from "../../../basic/MKInput";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const InputCodeModal = ({open, onComplete, onClose}) => {
    const CHARACTERS_NUMBER = 4;
    const initialInputs = new Array(CHARACTERS_NUMBER).fill('', 0, CHARACTERS_NUMBER);
    const [inputValues, setInputValues] = useState(initialInputs);
    const inputRefs = useRef([]);

    const renderInputs = (number) => {
        let result = [];
        for (let i = 0; i < number; i++) {
            result.push(<MKInput key={'input-' + i} type="text" inputRef={element => inputRefs.current[i] = element}
                                 value={inputValues[i]} variant="standard"
                                 onChange={e => changeInput(e, i)}
                                 sx={{width: '2rem'}} autoFocus={i === 0}
                                 InputProps={{
                                     autoComplete: 'new-password',
                                     style: {fontSize: '2rem', textAlign: 'center!important'},
                                     inputProps: {
                                         style: {textAlign: 'center'}
                                     }
                                 }}
            />)
        }
        return result;
    }

    const changeInput = (event, index) => {
        event.preventDefault();
        let newInputValues = [...inputValues];
        switch (event.target.value?.length) {
            case 0:
                newInputValues[index] = '';
                setInputValues(newInputValues);
                inputRefs.current[index - 1]?.focus();
                break;
            case 1:
                newInputValues[index] = event.target.value;
                setInputValues(newInputValues);
                inputRefs.current[index + 1]?.focus();
                break;
            case 2:
                newInputValues[index] = event.target.value[1];
                setInputValues(newInputValues);
                inputRefs.current[index + 1]?.focus();
                break;
            case CHARACTERS_NUMBER:
                setInputValues(event.target.value.split(''));
                break;
            default:
                newInputValues[index] = event.target.value[0];
                setInputValues(newInputValues);
                inputRefs.current[index + 1]?.focus();
                break;
        }
    }

    useEffect(() => {
        if (inputValues.join('').length === 4) {
            onComplete(inputValues.join(''));
            setInputValues(initialInputs);
        }
    }, [inputValues])

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <MKTypography variant="h6" sx={{textAlign: 'center', mb: 3}}>
                        Check email and enter code here
                    </MKTypography>
                    <Stack direction="row" justifyContent="space-between">
                        {renderInputs(CHARACTERS_NUMBER)}
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    );
};

export default InputCodeModal;