import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import theme from "assets/theme";
import {AuthStateProvider} from "./contexts/auth/AuthState";
import {ScreenSizeProvider} from "./contexts/screenSize/ScreenSizeState";
import SpinnerProvider from "./contexts/spinner/Spinner";
import './index.scss';
import {TokenInterceptor} from "./interceptors/TokenInterceptor";
import MainRoutes from './routes';
import {NotificationStateProvider} from "./contexts/notifications/NotificationState";
import * as ReactDOM from "react-dom";

// Token interceptor runs with every request
TokenInterceptor();

const App = () => {
    return (
        <AuthStateProvider>
            <NotificationStateProvider>
                <ScreenSizeProvider>
                    <SpinnerProvider>
                        <React.StrictMode>
                            <ThemeProvider theme={theme}>
                                <CssBaseline/>
                                <Router>
                                    <MainRoutes/>
                                </Router>
                            </ThemeProvider>
                        </React.StrictMode>
                    </SpinnerProvider>
                </ScreenSizeProvider>
            </NotificationStateProvider>
        </AuthStateProvider>
    )
}

ReactDOM.render(<App/>, document.getElementById('root'));