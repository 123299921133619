import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import SimpleInfoCard from "components/cards/SimpleInfoCard";
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import * as brandsIcons from "@fortawesome/free-brands-svg-icons";
import {FadeLoader} from "react-spinners";

const Advantages = ({advantages}) => {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          mb={3}
          textAlign="center"
        >
          <MKTypography variant="h3" textTransform="uppercase">
            Our Advantages
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {
            advantages && advantages.length > 0
              ? advantages.map((advantage, index) => (
                <Grid item xs={12} md={6} lg={4} key={'advantage-' + index}>
                  <SimpleInfoCard
                    color={advantage.color ?? 'info'}
                    icon={(regularIcons[advantage.icon] || solidIcons[advantage.icon] || brandsIcons[advantage.icon]) ?? solidIcons['faCoffee']}
                    title={advantage.title ?? 'title'}
                    description={advantage.description ?? 'Description'}
                    direction={advantage.direction ?? 'center'}
                  />
                </Grid>
              ))
              :
                <MKBox textAlign="center" width="100%" my={20}>
                  <FadeLoader radius={5}/>
                </MKBox>
          }

        </Grid>
      </Container>
    </MKBox>
  );
}

export default Advantages;