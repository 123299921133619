import {useState, useEffect} from "react";

const useLocalStorage = (key, initialValue = '') => {
    const [value, setValue] = useState(() => {
        return initialValue || localStorage.getItem(key)
    });

    useEffect(() => {
        if (localStorage.getItem(key) === value) return;
        localStorage.setItem(key, value);
    }, [key, value])

    return [value, setValue];
}

export default useLocalStorage;