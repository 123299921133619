import React from 'react';
import MKBox from "../../basic/MKBox";
import MKTypography from "../../basic/MKTypography";
import MKButton from "../../basic/MKButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";

const EmptyCard = ({color, image, title, onStartGame}) => {
    return (
        <MKBox
            display="flex"
            justifyContent="center"
            alignContent="center"
            borderRadius="lg"
            coloredShadow={color}
            width="100%"
            position="relative"
            zIndex={2}
            sx={{
                backgroundImage: ({palette: {gradients}, functions: {linearGradient, rgba}}) =>
                    `${linearGradient(
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
                    )}, url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backfaceVisibility: "hidden",
            }}
        >
            <MKBox height="420px" display="flex" flexDirection="column" justifyContent="center" px={3} width="100%">
                <MKBox mx="auto" mt={16}>
                    <MKTypography variant="h1" color="white" textTransform="capitalize">{title ?? ''}</MKTypography>
                </MKBox>
                {onStartGame &&
                    <MKButton variant="outlined" sx={{mt: 'auto', mb: 4}} onClick={onStartGame}>
                        <FontAwesomeIcon icon={faPlay} style={{marginRight: "8px"}}/>
                        Start game
                    </MKButton>
                }
            </MKBox>
        </MKBox>
    );
};

export default EmptyCard;