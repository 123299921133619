import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";

function Privacy() {
    return (
        <div className='main'>
            <MKBox component="section" pt={20} pb={12}>
                <Container>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <Card>
                                <MKBox
                                    variant="gradient"
                                    bgColor="dark"
                                    borderRadius="lg"
                                    coloredShadow="dark"
                                    p={3}
                                    mt={-3}
                                    mx={2}
                                >
                                    <MKTypography variant="h3" color="white">
                                        Privacy & Policy
                                    </MKTypography>
                                    <MKTypography variant="body2" color="white" opacity={0.8}>
                                        Last modified: April 01 2022
                                    </MKTypography>
                                </MKBox>
                                <MKBox pb={6} px={6}>
                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Introduction
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        Idealcouple.com is highly committed to protecting your privacy. We want you to spend more time meeting
                                        new people through our site and not to worry about your privacy.
                                    </MKTypography>
                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Managing Your Information
                                    </MKTypography>
                                    <MKTypography component="div" variant="body2" color="text" fontWeight="regular">
                                        <ol>
                                            <li>
                                                Idealcouple.com was designed and built to provide an easy service for members to meet like minded
                                                members online in the areas of match making.
                                            </li>
                                            <li>
                                                All private information such as lastname, e-mail address etc. are kept confidential it is not available
                                                to users or other members of this site. It is not possible for a user and member using the site to be
                                                able to obtain the true identity of an individual unless the member makes this available through the
                                                messaging etc.
                                            </li>
                                            <li>
                                                Information made available to other members is strictly the information you see when clicking on members
                                                profiles and this is done through a selected ID.
                                            </li>
                                            <li>
                                                We will never sell or otherwise share your name, e-mail address, or other personal information with any
                                                other business or marketing campaign without your permission.
                                            </li>
                                            <li>
                                                Am I protected by Law? We're subject to it just like everyone else. We're required to give information
                                                to legal authorities if they have the proper authorization such as a search warrant or court order.
                                                Your private information is held safely on our servers.
                                            </li>
                                            <li>
                                                Your individual use of Idealcouple.com and your interaction with others through Idealcouple.com will
                                                be kept strictly private.
                                            </li>
                                            <li>
                                                If our privacy policy changes in a way that will materially affect our users, we will either notify
                                                them by e-mail or post a notice of the changes on the site.
                                            </li>
                                            <li>
                                                We take our Privacy Policy very seriously. We want you to have confidence whenever you use
                                                Idealcouple.com. If you have questions or concerns, please use this e-mail: info@idealcouple.com to
                                                contact us.
                                            </li>

                                        </ol>
                                    </MKTypography>
                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Security
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text" fontWeight="regular">
                                        We shall not be hold responsible for any content that appears on your profile.
                                        You agree to protect and defend us against all claims that is rising on your
                                        profile. No link(s) should appear on any Website that may be interpreted as
                                        libelous, obscene or criminal, or which infringes, otherwise violates, or
                                        advocates the infringement or other violation of, any third party rights.
                                    </MKTypography>
                                </MKBox>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </div>
    );
}

export default Privacy;
