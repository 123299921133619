// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 PRO React examples
import CenteredFooter from "./CenteredFooter";
import {useLocation} from "react-router-dom";

function Footer() {
    const location = useLocation();
    const isAuthPage = location.pathname === '/login' || location.pathname === '/register';
    const isCommunicationPage = location.pathname === '/my-profile/communication';
    const company = { href: "/", name: "IdealCouple.com" };
    const links = [
        { href: "/", name: "Homepage" },
        { href: "/about", name: "About Us" },
        { href: "/policy", name: "Privacy and Policy" },
        { href: "/", name: "Blog" },
        { href: "/", name: "License" },
    ];
    const socials = [
        { icon: <FacebookIcon fontSize="small" />, link: "https://www.facebook.com" },
        {icon: <TwitterIcon fontSize="small" />, link: "https://twitter.com"},
        {icon: <InstagramIcon fontSize="small" />, link: "https://www.instagram.com"},
        {icon: <PinterestIcon fontSize="small" />, link: "https://ro.pinterest.com"}
    ];

    if (isAuthPage || isCommunicationPage) return null;
    return <CenteredFooter company={company} links={links} socials={socials} />;
}

export default Footer;