import {faFacebook, faGoogle, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBadge from "components/basic/MKBadge";
import HappyStories from "components/sections/home/HappyStories";
import {AuthContext} from "contexts/auth/AuthContext";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Rellax from "rellax";
import bgImage from "../assets/images/backgrounds/bg-home.jpg";
import MKBox from "../components/basic/MKBox";
import MKButton from "../components/basic/MKButton";
import MKTypography from "../components/basic/MKTypography";
import Advantages from '../components/sections/home/Advantages';
import Intro from '../components/sections/home/Intro';
import Stats from "../components/sections/home/Stats";
import useServer from "../hooks/useServer";
import {ADVANTAGE, ARTICLE, STORY} from "../serverUrls";
import {toast, ToastContainer} from "react-toastify";

const HomePage = () => {
    const headerRef = useRef(null);
    const titleRef = useRef(null);
    const {isAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();
    const [advantages, setAdvantages] = useState([]);
    const [stories, setStories] = useState([]);
    const [articles, setArticles] = useState([]);
    const [advantagesResponse, advantagesError, , advantagesSendRequest, advantagesSetError] = useServer(ADVANTAGE);
    const [articlesResponse, articlesError, , articlesSendRequest, articlesSetError] = useServer(ARTICLE);
    const [storiesResponse, storiesError, , storiesSendRequest, storiesSetError] = useServer(STORY);

    // Setting up rellax
    useEffect(() => {
        const headerParallax = new Rellax(headerRef.current, {
            speed: -6,
        });
        const titleParallax = new Rellax(titleRef.current, {
            speed: -1,
        });
        return () => {
            headerParallax.destroy();
            titleParallax.destroy();
        }
    }, []);

    // Load advantages
    useEffect(() => {
        advantagesSendRequest({method: 'get'});
        storiesSendRequest({method: 'get'});
        articlesSendRequest({method: 'get'});
    }, [])

    // On Advantages Response
    useEffect(() => {
        if (advantagesResponse) setAdvantages(advantagesResponse.data);
    }, [advantagesResponse]);

    // On Stories Response
    useEffect(() => {
        if (storiesResponse) setStories(storiesResponse.data);
    }, [storiesResponse]);

    // On Articles Response
    useEffect(() => {
        if (articlesResponse) setArticles(articlesResponse.data);
    }, [articlesResponse]);

    // On Advantages Error
    useEffect(() => {
        if (advantagesError) {
            toast.error(advantagesError.data?.message ?? 'Something went wrong');
            advantagesSetError(null);
        }
        if (storiesError) {
            toast.error(storiesError.data?.message ?? 'Something went wrong');
            storiesSetError(null);
        }
        return () => {
            advantagesSetError(null);
            storiesSetError(null);
        }
    }, [advantagesError, storiesError])

    return (
        <div className='main'>
            <ToastContainer/>
            <MKBox
                ref={headerRef}
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <MKBox
                        ref={titleRef}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{px: 2, textAlign: "center"}}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({breakpoints, typography: {size}}) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            An Ideal Couple
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Discover Online Dating with Ideal Couple
                        </MKTypography>
                        <MKButton color="success" onClick={() => navigate(isAuthenticated ? '/search' : '/register')}>
                            <FontAwesomeIcon icon={isAuthenticated ? faSearch : faPlus} style={{marginRight: '10px'}}/>
                            {isAuthenticated ? 'start search' : 'join now'}
                        </MKButton>

                        <MKTypography variant="h6" color="white" mt={8} mb={1}>
                            Find us on
                        </MKTypography>
                        <MKBox display="flex" justifyContent="center" alignItems="center">
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <FontAwesomeIcon icon={faFacebook}/>
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <FontAwesomeIcon icon={faInstagram}/>
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <FontAwesomeIcon icon={faTwitter}/>
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#">
                                <FontAwesomeIcon icon={faGoogle}/>
                            </MKTypography>
                        </MKBox>
                    </MKBox>
                </Container>
            </MKBox>

            <Container>
                <Card
                    sx={{
                        p: 2,
                        mx: {xs: 2, lg: 3},
                        mt: -8,
                        mb: -3,
                        backgroundColor: "white",
                        boxShadow: ({boxShadows: {xxl}}) => xxl,
                    }}
                >
                    <Intro articles={articles}/>
                </Card>
            </Container>

            <MKBox className="container-fluid bg-dark" py={12}>
                <Container>
                    <MKBox textAlign="center">
                        <MKBadge
                            badgeContent="Statistics"
                            variant="gradient"
                            color="info"
                            size="sm"
                            container
                            sx={{mb: 4}}
                        />
                    </MKBox>
                    <Stats/>
                </Container>
            </MKBox>

            <Container>
                <Card
                    sx={{
                        p: 2,
                        mx: {xs: 2, lg: 3},
                        mt: -8,
                        mb: -3,
                        zIndex: 1,
                        backgroundColor: "white",
                        boxShadow: ({boxShadows: {xxl}}) => xxl,
                    }}
                >
                    <Advantages advantages={advantages}/>
                </Card>
            </Container>

            <MKBox className="container-fluid bg-dark" py={12}>
                <Container>
                    <MKBox textAlign="center" width={{xs: '100%', lg: '50%'}} mb={6} mx="auto">
                        <MKBadge
                            badgeContent="Testimonials"
                            variant="gradient"
                            color="info"
                            size="sm"
                            container
                            sx={{mb: 4}}
                        />
                        <MKTypography variant="h2" color="white" mb={1}>
                            Happy ending stories
                        </MKTypography>
                        <MKTypography variant="body1" color="white" fontWeight="light">
                            Our subscribers share their happy love stories. We sincerely believe that all your dreams
                            will come true.
                        </MKTypography>
                    </MKBox>
                </Container>
            </MKBox>
            <MKBox/>

            <Container>
                <Card
                    sx={{
                        px: 2,
                        mx: {xs: 2, lg: 3},
                        mt: -8,
                        mb: -2,
                        zIndex: 1,
                        backgroundColor: "white",
                        boxShadow: ({boxShadows: {xxl}}) => xxl,
                    }}
                >
                    <HappyStories stories={stories}/>
                </Card>
            </Container>

            <MKBox className={"container-fluid bg-dark py-5"}>

            </MKBox>
        </div>

    )
}

export default HomePage;