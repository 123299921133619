import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import MKBox from "../../basic/MKBox";
import MKTypography from "../../basic/MKTypography";
import {FadeLoader} from "react-spinners";
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import MKButton from "../../basic/MKButton";
import {Link} from "react-router-dom";

const Intro = ({articles}) => {

    // install SwiperJS modules
    SwiperCore.use([Autoplay, Navigation]);

    const renderCard = article => (
        <>
            <MKBox textAlign="center" paddingTop={5}>
                <MKTypography variant="h3" textTransform="uppercase">
                    {article?.title ?? ''}
                </MKTypography>
            </MKBox>
            <Grid container spacing={3} alignItems="center" sx={{mb: 6}} mt={2}>
                <Grid item xs={12} lg={6} mr={{xs: 0, lg: "auto"}} display="flex" mb="auto">
                    <MKBox display="flex" flexDirection="column">
                        <MKBox
                            component="img"
                            src={article?.img ? process.env.REACT_APP_API_URL + '/' + article.img : ''}
                            alt={"title"}
                            width="100%"
                            borderRadius="xl"
                            maxHeight="37.5rem"
                        />
                        {
                            <Link to={`/articles/${article._id}`}>
                                <MKButton
                                    size="small"
                                    sx={{mt: -10, ml: 1}}
                                >Read More</MKButton>
                            </Link>
                        }
                    </MKBox>
                </Grid>
                <Grid item xs={12} lg={6} mr={{xs: 0, lg: "auto"}} position="relative">
                    <MKTypography component="div" variant="body2" color="text" fontWeight="bold">
                        <div className="content"
                             dangerouslySetInnerHTML={{__html: article?.short ?? ''}}></div>
                    </MKTypography>
                </Grid>
            </Grid>
        </>
    )

    return (
        <Container>
            {
                articles && articles.length > 0 ?
                    (articles.length === 1 ? renderCard(articles[0])
                            :
                            <>
                                <Swiper
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    modules={[Pagination]} className="mySwiper"
                                    autoplay={{delay: 3500}}
                                    speed={1800}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    loop
                                >
                                    {articles.map((article, i) => (
                                        <SwiperSlide key={i}>{renderCard(article)}</SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                    )
                    : <MKBox display="flex">
                        <MKBox textAlign="center" width="100%" my={20}>
                            <FadeLoader radius={5}/>
                        </MKBox>
                    </MKBox>
            }
        </Container>
    )
}

export default Intro;