import {AuthContext} from "./AuthContext";
import {useCallback, useReducer, useState} from "react";
import {AuthReducer} from "./AuthReducer";
import axios from "axios";
import {CHANGE_USER, LOGIN, LOGOUT} from "./AuthConstants";
import useLocalStorage from "../../hooks/useLocalStorage";
import moment from "moment";
import {FORGOT_PASSWORD, LOGIN_URL, REGISTER_URL, RESET_PASSWORD} from "../../serverUrls";

export const AuthStateProvider = ({children}) => {
    const [storageToken, setStorageToken] = useLocalStorage('token');
    const [storageExpirationDate, setStorageExpirationDate] = useLocalStorage('expirationDate');
    const [storageUser, setStorageUser] = useLocalStorage('user');
    const [error, setError] = useState();

    const [state, dispatch] = useReducer(AuthReducer, {token: null, isAuthenticated: false, user: null, expirationDate: null});
    const [isLoading, setIsLoading] = useState(false);

    const login = async (inputs) => {
        setIsLoading(true);
        await axios.post(LOGIN_URL, inputs)
            .then(response => onResponse(response))
            .catch(error => setError(error.response ?? error))
            .finally(() => setIsLoading(false))
    }

    const onResponse = response => {
        const token = response.data.token.split('Bearer ')[1];
        const expirationDate = moment().add(response.data.expiresIn ?? 0, 'seconds').toDate();
        const user = JSON.stringify(response.data.user);
        setStorageExpirationDate(expirationDate);
        setStorageToken(token);
        setStorageUser(user);
        dispatch(autoLogout(response.data.expiresIn));
        authSuccess({token, user, expirationDate});
    }

    const setUser = user => {
        if (typeof user !== 'string') user = JSON.stringify(user);
        setStorageUser(user);
        dispatch({
            type: CHANGE_USER,
            payload: user
        })
    }

    const authSuccess = (data) => {
        dispatch({
            type: LOGIN,
            payload: data
        });
    }

    const logout = useCallback(() => {
        setStorageToken('');
        setStorageExpirationDate('');
        setStorageUser('');
        dispatch({type: LOGOUT});
    },[setStorageToken, setStorageExpirationDate]);

    const register = async (inputs) => {
        setIsLoading(true);
        await axios.post(REGISTER_URL, inputs)
            .then(response => onResponse(response))
            .catch(error => setError(error.response?.data ?? error))
            .finally(() => setIsLoading(false))
    }
    const deleteUser = () => {

    }

    const autoLogin = () => {
        if (!storageToken || !storageExpirationDate || !storageUser) {
            logout()
        } else {
            if (new Date(storageExpirationDate) < new Date()) {
                logout();
            } else {
                authSuccess({...state, token: storageToken, expirationDate: storageExpirationDate, user: storageUser});
                autoLogout((new Date(storageExpirationDate).getTime() - new Date().getTime()) / 1000);
            }
        }
    }

    const autoLogout = (time) => {
        return () => {
            setTimeout(() => {
                logout()
            }, time * 1000)
        }
    }

    const forgotPasswordRequest = async email => {
        let result = null;
        setIsLoading(true);
        await axios.post(FORGOT_PASSWORD, {email})
            .then(response => result = response)
            .catch(error => setError(error.response ?? error))
            .finally(() => setIsLoading(false))
        return result;
    }

    const resetPassword = async params => {
        const {password, token} = params;
        setIsLoading(true);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }

        await axios.post(RESET_PASSWORD, {password}, {headers})
            .then(response =>  onResponse(response))
            .catch(error => setError(error.response ?? error))
            .finally(() => setIsLoading(false))
    }

    const {token, isAuthenticated, user} = state;
    const getUser = () => JSON.parse(user);

    return (
        <AuthContext.Provider value={{login, autoLogin, logout, forgotPasswordRequest, resetPassword, register,
            deleteUser, token, isAuthenticated, isLoading, user, getUser, setUser, error, setError}}>
            {children}
        </AuthContext.Provider>
    )
}