import { faFacebook, faGoogle, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import bgImage from "assets/images/backgrounds/bg-about-us.jpg";
import MKBadge from "components/basic/MKBadge";
import MKBox from "components/basic/MKBox";
import MKButton from "components/basic/MKButton";
import MKTypography from "components/basic/MKTypography";
import Information from "components/sections/aboutUs/Information";
import Newsletter from "components/sections/aboutUs/Newsletter";
import Team from "components/sections/aboutUs/Team";
import { AuthContext } from "contexts/auth/AuthContext";
import { demoTeam } from "data";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rellax from "rellax";
import * as Typed from "typed.js";
import { aboutUsPhrases } from "utils/constants";
import useServer from "../hooks/useServer";
import {CONCEPT, MEMBER} from "../serverUrls";
import {toast} from "react-toastify";

function AboutUsPage() {
    const headerRef = useRef(null);
    const typedJSRef = useRef(null);
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    const [team, setTeam] = useState([]);

    const [concepts, setConcepts] = useState([]);
    const [members, setMembers] = useState([]);


    const [conceptsResponse, conceptsError, , conceptsSendRequest, conceptsSetError] = useServer(CONCEPT);
    const [membersResponse, membersError, , membersSendRequest, membersSetError] = useServer(MEMBER);

    // Setting up rellax
    useEffect(() => {
        const parallax = new Rellax(headerRef.current, {
            speed: -6,
        });
        return () => parallax.destroy();
    }, []);

    // Setting up typedJS
    useEffect(() => {
        const typedJS = new Typed(typedJSRef.current, {
            strings: aboutUsPhrases,
            typeSpeed: 50,
            backSpeed: 50,
            backDelay: 1200,
            startDelay: 500,
            loop: true,
        });

        return () => typedJS.destroy();
    }, []);

    // Team info loading
    useEffect(() => {
        setTeam(demoTeam);
        membersSendRequest({method: 'get'});
        conceptsSendRequest({method: 'get'});
    }, [])

    // On Concepts Response
    useEffect(() => {
        if (conceptsResponse) setConcepts(conceptsResponse.data);
    }, [conceptsResponse])

    // On Members Response
    useEffect(() => {
        if (membersResponse) setMembers(membersResponse.data);
    }, [membersResponse])

    // On Errors
    useEffect(() => {
        if (conceptsError) {
            toast.error(conceptsError.data?.message ?? 'Something went wrong');
            conceptsSetError(null);
        }
        if (membersError) {
            toast.error(membersError.data?.message ?? 'Something went wrong');
            membersSetError(null);
        }
        return () => {
            conceptsSetError(null);
            membersSetError(null);
        }
    }, [conceptsError, membersError])

    return (
        <div className="main">
            <MKBox
                ref={headerRef}
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ px: 2, textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Dating Agency Since 1997
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            <span ref={typedJSRef} />
                        </MKTypography>
                        <MKButton color="success" onClick={() => navigate(isAuthenticated ? '/search' : '/register')}>
                            <FontAwesomeIcon icon={isAuthenticated ? faSearch : faPlus} style={{ marginRight: '10px' }} />
                            {isAuthenticated ? 'start search' : 'join now'}
                        </MKButton>
                        <MKTypography variant="h6" color="white" mt={8} mb={1}>
                            Find us on
                        </MKTypography>
                        <MKBox display="flex" justifyContent="center" alignItems="center">
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <FontAwesomeIcon icon={faFacebook} />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <FontAwesomeIcon icon={faInstagram} />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <FontAwesomeIcon icon={faTwitter} />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#">
                                <FontAwesomeIcon icon={faGoogle} />
                            </MKTypography>
                        </MKBox>
                    </MKBox>
                </Container>
            </MKBox>
            <Container>
                <Card
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 3 },
                        mt: -8,
                        mb: -2,
                        zIndex: 2,
                        boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    }}
                >
                    <Information concepts={concepts}/>
                </Card>


            </Container>
            <MKBox className="container-fluid bg-dark" py={12}>
                <Container>
                    <MKBox textAlign="center" width={{ xs: '100%', lg: '50%' }} mb={6} mx="auto">
                        <MKBadge
                            badgeContent="Our Team"
                            variant="gradient"
                            color="info"
                            size="sm"
                            container
                            sx={{ mb: 4 }}
                        />

                        <MKTypography variant="h2" color="white" mb={1}>
                            Creative members
                        </MKTypography>
                        <MKTypography variant="body1" color="white" fontWeight="light">
                            Our subscribers share their happy love stories. We sincerely believe that all your dreams will come true.
                        </MKTypography>
                    </MKBox>

                </Container>
            </MKBox>
            <Container>
                <Card
                    sx={{
                        p: 0,
                        mx: { xs: 2, lg: 3 },
                        mt: -8,
                        mb: -2,
                        boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    }}
                >
                    <Team members={members} />
                </Card>
            </Container>
            <MKBox className="container-fluid bg-dark" py={12}>
                <Container>
                    <MKBox textAlign="center">
                        <MKBadge
                            badgeContent="Subscribe"
                            variant="gradient"
                            color="info"
                            size="sm"
                            container
                            sx={{ mb: 4 }}
                        />
                        <Newsletter />
                    </MKBox>
                </Container>
            </MKBox>
        </div>
    );
}

export default AboutUsPage;
