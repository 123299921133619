import React, {useContext, useEffect, useState} from 'react';
import useServer from "../../../../hooks/useServer";
import {PROFILE_ME, PROFILE_RANDOM} from "../../../../serverUrls";
import {toast} from "react-toastify";
import {toastConfig} from "../../../../utils/utils";
import {initialSearchProfilesPagination as initialPagination, messages} from "../../../../utils/constants";
import MKBox from "../../../basic/MKBox";
import RotatingCard from "../../../cards/RotatingCard";
import RotatingCardFront from "../../../cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../../cards/RotatingCard/RotatingCardBack";
import MKTypography from "../../../basic/MKTypography";
import {Stack} from "@mui/material";
import MKButton from "../../../basic/MKButton";
import {faAngleRight, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import emptyCardImage from "../../../../assets/images/defaults/default-avatar.jpg";
import "swiper/css";
import "swiper/css/effect-cards";
import {SpinnerContext} from "../../../../contexts/spinner/Spinner";
import EmptyCard from "../../../cards/RotatingCard/EmptyCard";

const StartGame = ({inputMyProfile, inputProfiles, changeList, inputMatches, startChat, active}) => {
    const [profiles, setProfiles] = useState([]);
    const [currentProfile, setCurrentProfile] = useState();
    const [myProfile, setMyProfile] = useState(inputMyProfile);
    const [inTheList, setInTheList] = useState(null);
    const [profilesResponse, profilesError, profilesIsLoading, profilesSendRequest, profilesSetError] = useServer(PROFILE_RANDOM);
    const [myProfileResponse, myProfileError, myProfileIsLoading, myProfileSendRequest, myProfileSetError] = useServer(PROFILE_ME);
    const [spinner, setSpinner] = useContext(SpinnerContext);

    // Set Current Profile On inputProfiles change
    useEffect(() => {
        if (inputProfiles && inputProfiles.length > 0) {
            setProfiles(inputProfiles);
            setCurrentProfile(inputProfiles[0]);
        }
    }, [inputProfiles])

    const showNextProfile = () => {
        const index = profiles.indexOf(currentProfile);
        if ((index + 1) < profiles.length) {
            setCurrentProfile(profiles[index + 1]);
        } else {
            getRandomProfiles();
        }
    }

    // Check current profile
    useEffect(() => {
        if (inputMatches && currentProfile) {
            if (inputMatches.waiting.find(item => item._id === currentProfile._id)) {
                setInTheList('waiting');
            } else if (inputMatches.favorite.find(item => item._id === currentProfile._id)) {
                setInTheList('favorite');
            } else if (inputMatches.interested.find(item => item._id === currentProfile._id)) {
                setInTheList('interested');
            } else setInTheList(null);
        }
    }, [currentProfile, inputMatches])

    // Get random profiles
    const getRandomProfiles = () => profilesSendRequest({method: 'get', params: initialPagination});

    // On Load page
    useEffect(() => {
        if (!myProfile) myProfileSendRequest({method: 'get'});
    }, [])

    // On my profile response
    useEffect(() => {
        if (myProfileResponse) setMyProfile(myProfileResponse.data);
    }, [myProfileResponse])

    // On Profiles Response
    useEffect(() => {
        if (profilesResponse) {
            setProfiles(profilesResponse.data.profiles);
            setCurrentProfile(profilesResponse.data.profiles[0]);
        }
    }, [profilesResponse])

    // On Loading
    useEffect(() => {
        if (profilesIsLoading || myProfileIsLoading) setSpinner(true);
        else if (spinner) setSpinner(false);
        return () => setSpinner(false);
    }, [profilesIsLoading, myProfileIsLoading])

    // On Error
    useEffect(() => {
        if (profilesError) {
            toast.error(profilesError.data?.message ?? 'Profiles NOT loaded', toastConfig);
            profilesSetError(null);
        }
        if (myProfileError) {
            toast.error(myProfileError.data?.message ?? 'Profile NOT loaded', toastConfig);
            myProfileSetError(null);
        }
        return () => {
            profilesSetError(null);
            myProfileSetError(null);
        }

    }, [profilesError, myProfileError])

    return (
        <MKBox>
            {profiles && profiles.length > 0 ?
                <MKBox>
                    <MKBox width="320px" mx="auto" mb={6}>
                        {currentProfile &&
                            <RotatingCard>
                                <RotatingCardFront color="success" profile={currentProfile}/>
                                <RotatingCardBack
                                    color="success"
                                    profile={currentProfile}
                                    startChat={startChat}
                                    active={active}
                                />
                            </RotatingCard>
                        }
                    </MKBox>

                    <MKBox my={3} textAlign="center">
                        {inTheList
                            ? <MKTypography variant="h5">This profile is already in the {inTheList} list</MKTypography>
                            : <MKTypography variant="h5">Are you interested in meeting this person?</MKTypography>
                        }
                    </MKBox>
                    <MKBox maxWidth="320px" margin="auto">
                        <Stack direction="row" justifyContent={inTheList ? 'center' : 'space-between'} alignItems="center" sx={{my: 2}}>
                            {!inTheList
                                ?
                                <>
                                    <MKButton color="success" size="small" sx={{mx: 1}}
                                              disabled={!active}
                                              onClick={() => {
                                                  showNextProfile();
                                                  changeList('favorite', 'add', currentProfile._id);
                                              }}>
                                        <FontAwesomeIcon icon={faCheck}
                                                         style={{marginRight: '5px'}}/>
                                        yes
                                    </MKButton>
                                    <MKButton color="info" size="small" sx={{mx: 1}}
                                              disabled={!active}
                                              onClick={() => {
                                                  showNextProfile();
                                                  changeList('waiting', 'add', currentProfile._id);
                                              }}>
                                        <FontAwesomeIcon icon={faClock}
                                                         style={{marginRight: '5px'}}/>
                                        maybe
                                    </MKButton>
                                </>
                                : null
                            }

                            <MKButton color="error" size="small" sx={{mx: 1, px: 3}} onClick={showNextProfile}>
                                <FontAwesomeIcon icon={faTimes}
                                                 style={{marginRight: '5px'}}/>
                                next
                            </MKButton>
                        </Stack>
                    </MKBox>
                    {!active ?
                        <MKBox my={3} textAlign="center">
                            <MKTypography variant="button" color="error" sx={{m: 'auto'}}>
                                {messages.notActivateAccount}
                            </MKTypography>
                        </MKBox>
                        : null
                    }
                </MKBox> :
                <MKBox width="320px" mx="auto" mb={6}>
                    <EmptyCard color="success" title="ready?" image={emptyCardImage}
                               onStartGame={() => getRandomProfiles()}/>
                </MKBox>
            }
        </MKBox>
    );
};

export default StartGame;