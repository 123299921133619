import Grid from "@mui/material/Grid";
import DefaultCounterCard from "components/cards/DefaultCounterCard";

function Stats() {
    return (

        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={4}>
                <DefaultCounterCard
                    count={1580}
                    title="Accounts"
                    description="Of “high-performing” level are led by a certified project manager"
                />
            </Grid>
            <Grid item xs={12} md={4} display="flex">
                <DefaultCounterCard
                    count={825}
                    title="Dates"
                    description="That meets quality standards required by our users"
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DefaultCounterCard
                    count={30}
                    title="Weddings"
                    description="Actively engage team members that finishes on time"
                />
            </Grid>
        </Grid>

    );
}

export default Stats;