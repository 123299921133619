import {faCheckCircle, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import {
    faClock,
    faCog,
    faComment,
    faDollarSign,
    faEnvelope,
    faExclamationTriangle,
    faEye,
    faPen,
    faPlayCircle,
    faShoppingCart
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container, Tooltip} from "@mui/material";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import defaultUserImage from "assets/images/defaults/default-user.jpg";
import moment from "moment";
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Navigate, NavLink, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import bgImage from "../assets/images/backgrounds/bg-my-page.jpg";
import MKBox from "../components/basic/MKBox";
import MKButton from "../components/basic/MKButton";
import MKTypography from "../components/basic/MKTypography";
import Balance from "../components/sections/myProfile/Balance/Balance";
import Cart from "../components/sections/myProfile/Cart/Cart";
import Communication from "../components/sections/myProfile/Communication/Communication";
import InputCodeModal from "../components/sections/myProfile/EditProfile/InputCodeModal";
import ProfileForm from "../components/sections/myProfile/EditProfile/ProfileForm";
import Settings from "../components/sections/myProfile/Settings/Settings";
import ViewProfile from "../components/sections/myProfile/ViewProfile/ViewProfile";
import {AuthContext} from "../contexts/auth/AuthContext";
import {ScreenSizeContext} from "../contexts/screenSize/ScreenSizeContext";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import useServer from "../hooks/useServer";
import {ACTIVATE_ACCOUNT, CONFIRM_EMAIL, PROFILE_ME} from "../serverUrls";
import {toastConfig} from "../utils/utils";
import LetsMatch from "../components/sections/myProfile/LetsMatch/LetsMatch";
import breakpoints from "../assets/theme/base/breakpoints";
import {links} from "../utils/constants";
import Rellax from "rellax";

const profileRoutes = [
    {path: '', name: 'view profile', icon: (<FontAwesomeIcon icon={faEye}/>)},
    {
        path: links.myProfile_edit,
        name: 'edit profile',
        icon: (<FontAwesomeIcon icon={faPen} style={{marginRight: 2}}/>)
    },
    {
        path: links.myProfile_balance,
        name: 'balance',
        icon: (<FontAwesomeIcon icon={faDollarSign} style={{marginRight: 2}}/>)
    },
    {
        path: links.myProfile_cart,
        name: 'cart',
        icon: (<FontAwesomeIcon icon={faShoppingCart} style={{marginRight: 2}}/>)
    },
    {
        path: links.myProfile_communication,
        name: 'chat',
        icon: (<FontAwesomeIcon icon={faComment} style={{marginRight: 2}}/>)
    },
    {
        path: links.myProfile_letsMatch,
        name: "let's match",
        icon: (<FontAwesomeIcon icon={faPlayCircle} style={{marginRight: 2}}/>)
    },
    {path: links.myProfile_settings, name: 'settings', icon: (<FontAwesomeIcon icon={faCog} style={{marginRight: 2}}/>)}
];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const MyProfilePage = () => {
    const headerRef = useRef(null);
    const [myProfile, setMyProfile] = useState(null);
    const [showEnterCodeModal, setShowEnterCodeModal] = useState(false);
    const [myProfileResponse, myProfileError, myProfileIsLoading, myProfileSendRequest, myProfileSetError] = useServer(PROFILE_ME);
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const {getUser, setUser} = useContext(AuthContext);
    const {screenSizeValue} = useContext(ScreenSizeContext);
    const [activateAccountResponse, activateAccountError, activateAccountIsLoading, activateAccountSendRequest, activateAccountSetError] = useServer(ACTIVATE_ACCOUNT);
    const [confirmEmailResponse, confirmEmailError, confirmEmailIsLoading, confirmEmailSendRequest, confirmEmailSetError] = useServer(CONFIRM_EMAIL);
    const activateAccount = () => activateAccountSendRequest({method: 'post', data: {activate: true}});
    const {pathname, state} = useLocation();
    const navigate = useNavigate();
    const isCommunicationPage = pathname === links.myProfile_communication;
    const isLetsMatchPage = pathname === links.myProfile_letsMatch;

    const changeUserActivation = checked => {
        activateAccountSendRequest({method: 'post', data: {activate: checked}});
    }

    const confirmEmail = code => {
        confirmEmailSendRequest({method: 'post', data: {code}});
        setShowEnterCodeModal(false);
    }

    // Setting up rellax
    useEffect(() => {
        const parallax = new Rellax(headerRef.current, {
            speed: -6,
        });
        return () => parallax.destroy();
    }, []);

    // On Confirm Email Response
    useEffect(() => {
        if (confirmEmailResponse) {
            toast.success(confirmEmailResponse.data?.message ?? 'Account activated', toastConfig);
            setUser({...getUser(), activated: true});
        }
    }, [confirmEmailResponse])

    // On activate / deactivate account response
    useEffect(() => {
        if (activateAccountResponse) {
            if (activateAccountResponse.data.message) {
                let email = activateAccountResponse.data.message?.accepted[0];
                toast.success(`Email sent to ${email}`, toastConfig);
                setShowEnterCodeModal(true);
            } else {
                setUser(activateAccountResponse.data);
            }
        }
        return () => setShowEnterCodeModal(false);
    }, [activateAccountResponse])

    // On Loading
    useEffect(() => {
        if (myProfileIsLoading || confirmEmailIsLoading || activateAccountIsLoading) setShowSpinner(true);
        else if (showSpinner === true) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [myProfileIsLoading, confirmEmailIsLoading, activateAccountIsLoading])

    // On User change get myProfile send request
    useEffect(() => {
        if (getUser().profile) myProfileSendRequest({method: 'get'});
    }, [state])

    // If location state exists
    useEffect(() => {
        if (state) {
            if (state === 'reset') {
                setMyProfile(null);
            } else if (state.path && state.path === links.balance) {
                navigate(`${links.myProfile_balance}?show=${state.message}`, {state: {myProfile: state.myProfile}});
            }
        }
    }, [state])

    // On MyProfile Response
    useEffect(() => {
        if (myProfileResponse) {
            setMyProfile(myProfileResponse.data);
            if (state?.path === links.cart) {
                navigate(links.myProfile_cart, {state: {product: state.product, membership: state.membership}});
            }
            if (state?.path === links.communication) {
                navigate(links.myProfile_communication, {state: {selectedProfileId: state.profileId}});
            }
            if (state?.path === links.letsMatch) {
                navigate(links.myProfile_letsMatch, {state: {profiles: state.profiles}});
            }
        }
    }, [myProfileResponse])

    // On Errors
    useEffect(() => {
        if (myProfileError && myProfileError.status !== 404) {
            toast.error(myProfileError.data?.message, toastConfig);
            myProfileSetError(null);
        }
        if (confirmEmailError) {
            toast.error(confirmEmailError.data?.message, toastConfig);
            confirmEmailSetError(null);
        }
        if (activateAccountError) {

            toast.error(activateAccountError.data?.message, toastConfig);
            activateAccountSetError(null);
        }

        return () => {
            myProfileSetError(null);
            confirmEmailSetError(null);
            activateAccountSetError(null);
        }
    }, [myProfileError, confirmEmailError, activateAccountError])

    return (
        <div className='main'>
            <ToastContainer/>
            <MKBox
                ref={headerRef}
                position="absolute"
                minHeight={isCommunicationPage ? '1rem' : '100vh'}
                width="100%"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.3),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
            </MKBox>
            <Container style={{height: isCommunicationPage ? 'calc(100vh - 1rem)' : '100%', padding: '0'}}>
                <MKBox display={'flex'} flexDirection={{xs: 'column', lg: isLetsMatchPage ? 'column' : 'row'}}
                       height="100%"
                       sx={{mt: 14}}
                >
                    <MKBox sx={{p: 1}}>
                        <Card sx={{
                            p: 2,
                            mx: 0,
                            mt: -2,
                            mb: 2,
                            boxShadow: ({boxShadows: {xxl}}) => xxl,
                        }}
                        >
                            <MKBox display={{xs: 'none', lg: isLetsMatchPage ? 'none' : 'block'}}>
                                <MKBox
                                    component="img"
                                    src={myProfile && myProfile.avatar ? process.env.REACT_APP_API_URL + '/' + myProfile.avatar : defaultUserImage}
                                    alt={"user photo"}
                                    width="100%"
                                    borderRadius="xl"
                                    maxHeight="16.5rem"
                                />
                                <MKBox component="ul" sx={{listStyle: 'none', p: 0, m: 0}}>
                                    <MKBox component="li" sx={{display: 'flex', pt: 1}}>
                                        <FontAwesomeIcon className="formContext-icon" icon={faEnvelope}
                                                         style={{marginRight: '.8rem'}}/>
                                        <MKTypography variant="button" color="text">{getUser()?.email}</MKTypography>
                                    </MKBox>

                                    <MKBox component="li" sx={{display: 'flex', pt: 1}}>
                                        <FontAwesomeIcon className="formContext-icon" icon={faClock}
                                                         style={{marginRight: '.8rem'}}/>
                                        <MKTypography variant="button"
                                                      color="text">{moment(getUser()?.registeredAt ?? null).format('l')}</MKTypography>
                                    </MKBox>

                                    <MKBox component="li" sx={{display: 'flex', pt: 1, pb: 2}}>
                                        <FontAwesomeIcon className="formContext-icon"
                                                         icon={getUser().activated ? faCheckCircle : faTimesCircle}
                                                         style={{marginRight: '.8rem'}}/>
                                        <MKTypography variant="button"
                                                      color="text">{getUser().activated ? 'activated' : 'not activated'}</MKTypography>
                                    </MKBox>
                                </MKBox>
                            </MKBox>
                            {!getUser()?.activated &&
                                <MKButton variant="outlined" color="error"
                                          fullWidth
                                          sx={{my: 0, display: "inline-flex", justifyContent: "space-between"}}
                                          onClick={activateAccount}>
                                    <FontAwesomeIcon icon={faExclamationTriangle}/> Activate user
                                </MKButton>
                            }
                            <MKBox component="section" py={1}>
                                <Stack direction={{xs: 'row', lg: isLetsMatchPage ? 'row' : 'column'}}
                                       alignItems="stretch" spacing={1}
                                >
                                    {profileRoutes.map((route, index) => (
                                        <Fragment key={`${route.name}-mini-${index}`}>

                                            <MKBox sx={{display: {xs: 'flex', lg: 'none'}}}
                                                   component={NavLink} to={route.path}
                                                   width="100%">
                                                <Tooltip title={route.name} component="div">
                                                    <MKButton
                                                        variant={pathname === route.path ? "contained" : "outlined"}
                                                        color="success"
                                                        iconOnly={screenSizeValue < breakpoints.values.md}
                                                        disabled={!myProfile}
                                                    >
                                                        {route.icon}
                                                    </MKButton>
                                                </Tooltip>
                                            </MKBox>


                                            <MKBox display={{xs: 'none', lg: 'flex'}}
                                                   component={NavLink} to={route.path}
                                                   width="100%">
                                                <MKButton
                                                    variant={pathname === route.path ? "contained" : "outlined"}
                                                    color="success"
                                                    fullWidth
                                                    style={{display: "inline-flex", justifyContent: "space-between"}}

                                                >
                                                    {route.icon}
                                                    {route.name === 'edit profile' && !myProfile ? "add profile" : route.name}
                                                </MKButton>
                                            </MKBox>
                                        </Fragment>
                                    ))}
                                </Stack>
                            </MKBox>
                        </Card>
                    </MKBox>
                    <MKBox sx={{p: 1, width: '100%', height: '100%'}}>
                        <Routes>
                            <Route path="" element={<ViewProfile profile={myProfile} active={getUser().activated}/>}/>
                            <Route path={links.communication}
                                   element={
                                       !myProfile
                                           ? <Navigate replace to={links.myProfile}/>
                                           :
                                           <Communication myProfileState={{myProfile, setMyProfile}}
                                                          screenSizeValue={screenSizeValue}/>
                                   }
                            />
                            <Route path={links.letsMatch}
                                   element={
                                       !myProfile
                                           ? <Navigate replace to={links.myProfile}/>
                                           : <LetsMatch myProfileState={{myProfile, setMyProfile}}/>
                                   }
                            />
                            <Route path={links.cart}
                                   element={!myProfile
                                       ? <Navigate replace to={links.myProfile}/>
                                       : <Elements stripe={stripePromise}>
                                           <Cart/>
                                       </Elements>
                                   }
                            />
                            <Route path={links.edit} element={<ProfileForm inputProfile={myProfile}/>}/>
                            <Route path={links.balance}
                                   element={!myProfile
                                       ? <Navigate replace to={links.myProfile}/>
                                       : <Balance myProfileState={{myProfile, setMyProfile}}/>
                                   }
                            />
                            <Route path={links.settings}
                                   element={<Settings myProfileState={{myProfile, setMyProfile}} userState={{getUser, setUser}}
                                                      changeUserActivation={changeUserActivation}/>}
                            />
                        </Routes>
                    </MKBox>
                </MKBox>
            </Container>
            <InputCodeModal open={showEnterCodeModal} onComplete={confirmEmail}
                            onClose={() => setShowEnterCodeModal(false)}/>
        </div>
    )
}

export default MyProfilePage;