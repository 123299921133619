import React, {useContext, useEffect, useRef, useState} from "react";
import MKTypography from "../../../basic/MKTypography";
import MKBox from "../../../basic/MKBox";
import MKInput from "../../../basic/MKInput";
import MKButton from "../../../basic/MKButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faTimes} from "@fortawesome/free-solid-svg-icons";
import useServer from "hooks/useServer";
import {CHANGE_PASSWORD, CHANGE_SUBSCRIPTION, DELETE_ME, PROFILE_BLOCK, PROFILE_ME} from "serverUrls";
import {toast} from "react-toastify";
import {convertDateToAge, toastConfig} from "utils/utils";
import {SpinnerContext} from "../../../../contexts/spinner/Spinner";
import {links, passwordMinLength} from "utils/constants";
import FormData from "form-data";
import Swal from 'sweetalert2';
import {Grid, List, ListItem, Switch} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MKAvatar from "../../../basic/MKAvatar";
import defaultAvatar from "../../../../assets/images/defaults/default-avatar.jpg";
import Card from "@mui/material/Card";
import moment from "moment";

const Settings = ({myProfileState, userState, changeUserActivation}) => {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [oldPasswordInputType, setOldPasswordInputType] = useState('password');
    const [newPasswordInputType, setNewPasswordInputType] = useState('password');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [userIsActive, setUserIsActive] = useState(false);
    const [deleteProfileButtonDisable, setDeleteProfileButtonDisable] = useState(true);
    const [deleteUserButtonDisable, setDeleteUserButtonDisable] = useState(true);
    const [subscribedForNewslettersToggle, setSubscribedForNewslettersToggle] = useState(userState.getUser()?.subscribedForNewsletters);
    const [subscribedForNotificationsToggle, setSubscribedForNotificationsToggle] = useState(userState.getUser()?.subscribedForNotifications);
    const [changePasswordResponse, changePasswordError, changePasswordIsLoading, changePasswordSendRequest, changePasswordSetError] = useServer(CHANGE_PASSWORD);
    const [subscriptionResponse, subscriptionError, subscriptionIsLoading, subscriptionSendRequest, subscriptionSetError] = useServer(CHANGE_SUBSCRIPTION);
    const [removeBlockedResponse, removeBlockedError, removeBlockedIsLoading, removeBlockedSendRequest, removeBlockedSetError] = useServer(PROFILE_BLOCK);
    const [deleteProfileResponse, deleteProfileError, deleteProfileIsLoading, deleteProfileSendRequest, deleteProfileSetError] = useServer(PROFILE_ME);
    const [deleteUserResponse, deleteUserError, deleteUserIsLoading, deleteUserSendRequest, deleteUserSetError] = useServer(DELETE_ME);

    const deleteProfileInput = useRef();
    const deleteUserInput = useRef();
    const navigate = useNavigate();

    const toggleOldPassword = () => {
        setShowOldPassword(!showOldPassword);
        if (oldPasswordInputType === 'password') setOldPasswordInputType('text');
        else setOldPasswordInputType('password');
    }

    const toggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
        if (newPasswordInputType === 'password') setNewPasswordInputType('text');
        else setNewPasswordInputType('password');
    }

    const onInputName = e => {
        e.preventDefault();
        const value = e.target?.value;
        const firstname = myProfileState?.myProfile?.firstname;
        const lastname = myProfileState?.myProfile?.lastname;
        const checkValue = firstname + ' ' + lastname;
        if (checkValue && value === checkValue) setDeleteProfileButtonDisable(false);
        else if (!deleteProfileButtonDisable) setDeleteProfileButtonDisable(true);
    }

    const onInputEmail = e => {
        e.preventDefault();
        const value = e.target?.value;
        const checkValue = userState.getUser()?.email;
        if (checkValue && value === checkValue) setDeleteUserButtonDisable(false);
        else if (!deleteUserButtonDisable) setDeleteUserButtonDisable(true);
    }

    const changePassword = e => {
        e.preventDefault();
        if (oldPassword && newPassword) {
            if (oldPassword === newPassword) return toast.error('New password is the same as old one.', toastConfig);
            let data = new FormData();
            data.append("oldPassword", oldPassword);
            data.append("newPassword", newPassword);
            data = JSON.stringify({oldPassword, newPassword});
            changePasswordSendRequest({method: 'post', data});
        } else toast.error('Passwords should NOT be empty', toastConfig);
    }

    const deleteMyProfile = e => {
        Swal.fire({
            title: 'Delete your profile!',
            text: 'Your profile will be deleted! Continue?',
            icon: 'question',
            confirmButtonText: 'Yes, please',
            showCancelButton: false,
            showDenyButton: true,
            denyButtonText: `Don't delete`
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProfileSendRequest({method: 'delete'});
            } else if (result.isDenied) {
                resetForm();
                Swal.fire('Profile is NOT deleted', '', 'info')
            }
        })
    }

    const deleteUser = e => {
        Swal.fire({
            title: 'Delete user account!',
            text: 'Your user account will be deleted! Continue?',
            icon: 'question',
            confirmButtonText: 'Yes, please',
            showCancelButton: false,
            showDenyButton: true,
            denyButtonText: `Don't delete`
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserSendRequest({method: 'delete'});
            } else if (result.isDenied) {
                resetForm();
                Swal.fire('User account is NOT deleted', '', 'info')
            }
        })
    }

    const removeBlockedProfile = id => {
        if (id) removeBlockedSendRequest({method: 'delete', data: {id}});
    }

    const resetForm = () => {
        setOldPassword('');
        setNewPassword('');
        setDeleteProfileButtonDisable(true);
        deleteProfileInput.current.value = '';
        deleteUserInput.current.value = '';
    }

    useEffect(() => {
        if (userState.getUser()) {
            setUserIsActive(userState.getUser()?.activated);
        }
    }, [userState.getUser()])

    // On switch subscribedForNewsletters toggle
    useEffect(() => {
        if (userState.getUser() && userState.getUser()?.subscribedForNewsletters !== subscribedForNewslettersToggle) {
            subscriptionSendRequest({
                method: 'post',
                data: {field: 'subscribedForNewsletters', value: subscribedForNewslettersToggle}
            });
        }

    }, [subscribedForNewslettersToggle])

    // On switch subscribedForNotifications toggle
    useEffect(() => {
        if (userState.getUser() && userState.getUser()?.subscribedForNotifications !== subscribedForNotificationsToggle) {
            subscriptionSendRequest({
                method: 'post',
                data: {field: 'subscribedForNotifications', value: subscribedForNotificationsToggle}
            });
        }
    }, [subscribedForNotificationsToggle])

    // On Subscription Response
    useEffect(() => {
        if (subscriptionResponse && subscriptionResponse.data) {
            userState.setUser(subscriptionResponse.data);
        }
    }, [subscriptionResponse])

    // On remove blocked profile response
    useEffect(() => {
        if (removeBlockedResponse) {
            userState.setUser({...userState.getUser(), profile: removeBlockedResponse.data});
            myProfileState.setMyProfile(removeBlockedResponse.data);
        }
    }, [removeBlockedResponse])

    // On Change Password Response
    useEffect(() => {
        if (changePasswordResponse) {
            resetForm();
            toast.success(changePasswordResponse.data?.message, toastConfig);
        }
    }, [changePasswordResponse])

    // On Delete Profile Response
    useEffect(() => {
        if (deleteProfileResponse) {
            resetForm();
            Swal.fire({
                icon: 'success',
                text: deleteProfileResponse.data.message ?? 'Profile has been deleted!'
            }).then((result) => {
                if (result.isConfirmed) {
                    userState.setUser({...userState.getUser(), profile: null});
                    navigate(links.myProfile, {state: 'reset'});
                }
            })
        }
    }, [deleteProfileResponse])

    // On Delete Account Response
    useEffect(() => {
        if (deleteUserResponse) {
            resetForm();
            Swal.fire({
                icon: 'success',
                text: deleteUserResponse.data.message ?? 'User account has been deleted!'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(links.signOut);
                }
            })
        }
    }, [deleteUserResponse])

    // On Error
    useEffect(() => {
        if (changePasswordError) {
            toast.error(changePasswordError.data?.message, toastConfig);
            changePasswordSetError(null);
        }
        if (deleteProfileError) {
            toast.error(deleteProfileError.data?.message, toastConfig);
            deleteProfileSetError(null);
        }
        if (removeBlockedError) {
            toast.error(removeBlockedError.data?.message, toastConfig);
            removeBlockedSetError(null);
        }
        if (subscriptionError) {
            toast.error(subscriptionError.data?.message, toastConfig);
            subscriptionSetError(null);
        }
        if (deleteUserError) {
            toast.error(deleteUserError.data?.message, toastConfig);
            deleteUserSetError(null);
        }
        return () => {
            changePasswordSetError(null);
            deleteProfileSetError(null);
            removeBlockedSetError(null);
            subscriptionSetError(null);
            deleteUserSetError(null);
        }

    }, [changePasswordError, deleteProfileError, removeBlockedError, subscriptionError, deleteUserError])

    // On Loading
    useEffect(() => {
        if (changePasswordIsLoading || deleteProfileIsLoading || removeBlockedIsLoading || deleteUserIsLoading) setShowSpinner(true);
        else if (showSpinner === true) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [changePasswordIsLoading, deleteProfileIsLoading, removeBlockedIsLoading, deleteUserIsLoading])

    return (
        <>
            {/* Basic Info */}
            <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
                <MKTypography variant="h5" sx={{fontWeight: 'bold', mb: 2}}>Basic Info</MKTypography>
                <MKBox display="flex" justifyContent="space-between">
                    <MKBox display="flex" alignItems="center">
                        <MKAvatar alt="avatar" size="xl"
                                  src={myProfileState.myProfile ? process.env.REACT_APP_API_URL + '/' + myProfileState.myProfile.avatar : defaultAvatar}
                                  sx={{mr: 2}}/>
                        <MKBox>
                            <MKBox display="flex">
                                <MKTypography variant="h6">
                                    {myProfileState.myProfile ? myProfileState.myProfile.firstname + ' ' + myProfileState.myProfile.lastname : 'User (No Profile)'}
                                </MKTypography>
                            </MKBox>
                            <MKBox display="flex" flexDirection="column">
                                <MKTypography variant="button">member
                                    since: {moment(userState.getUser()?.registeredAt).format('MMM d, yyyy')}</MKTypography>
                                <MKTypography variant="button">email: {userState.getUser().email}</MKTypography>
                                <MKTypography
                                    variant="button">age: {myProfileState.myProfile ? convertDateToAge(myProfileState.myProfile.birthday) : '?'}</MKTypography>
                                <MKTypography
                                    variant="button">location: {myProfileState.myProfile ? myProfileState.myProfile.city + ' (' + myProfileState.myProfile.country?.name + ')' : ''}</MKTypography>
                            </MKBox>
                        </MKBox>
                    </MKBox>
                    <MKBox>
                        <MKBox display="flex" alignItems="center">
                            <MKTypography variant="caption">{userIsActive ? 'active' : 'not active'}</MKTypography>
                            <Switch
                                checked={userIsActive}
                                onChange={e => {
                                    changeUserActivation(e.target.checked);
                                    setUserIsActive(!userIsActive); // ???
                                }}
                            />
                        </MKBox>
                        <MKTypography variant="caption">Profile
                            is {userIsActive ? 'visible' : 'invisible'}</MKTypography>
                    </MKBox>
                </MKBox>
            </Card>
            {/* Change password */}
            <Card sx={{p: 2, mx: 0, my: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <MKTypography variant="h5" sx={{fontWeight: 'bold', mb: 2}}>Change Password</MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={9} xl={10}>
                        <MKBox component="form" method="post" autocomplete="off" display="flex"
                               justifyContent={{xs: 'space-between', xl: 'start'}}>
                            <MKBox display="flex" alignItems="center">
                                <MKInput type={oldPasswordInputType}
                                         label="Old Password"
                                         variant="standard"
                                         inputProps={{autoComplete: 'off'}}
                                         value={oldPassword}
                                         onChange={e => setOldPassword(e.target.value)}
                                />
                                <MKBox sx={{px: 2}} onClick={toggleOldPassword}>
                                    {showOldPassword
                                        ? <FontAwesomeIcon icon={faEye} style={{fontSize: '14px', color: '#fe9c17'}}/>
                                        : <FontAwesomeIcon icon={faEyeSlash}
                                                           style={{fontSize: '14px', color: '#a8a8a8'}}/>
                                    }
                                </MKBox>
                            </MKBox>
                            <MKBox display="flex" alignItems="center">
                                <MKInput type={newPasswordInputType}
                                         label="New Password"
                                         variant="standard"
                                         inputProps={{autoComplete: 'off'}}
                                         value={newPassword}
                                         onChange={e => setNewPassword(e.target.value)}
                                />
                                <MKBox sx={{px: 2}} onClick={toggleNewPassword}>
                                    {showNewPassword
                                        ? <FontAwesomeIcon icon={faEye} style={{fontSize: '14px', color: '#fe9c17'}}/>
                                        : <FontAwesomeIcon icon={faEyeSlash}
                                                           style={{fontSize: '14px', color: '#a8a8a8'}}/>
                                    }
                                </MKBox>
                            </MKBox>
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={3} xl={2}>
                        <MKBox display="flex" alignItems="center" height="100%" sx={{mb: 1, mt: 1}}>
                            <MKButton size="small" variant="gradient" color="warning" onClick={changePassword}
                                      sx={{ml: 'auto', width: '8em'}}
                                      disabled={
                                          oldPassword?.length < passwordMinLength ||
                                          newPassword?.length < passwordMinLength ||
                                          changePasswordIsLoading
                                      }
                            >
                                Change
                            </MKButton>
                        </MKBox>
                    </Grid>
                </Grid>
            </Card>
            {/* Notifications */}
            <Card sx={{p: 2, mx: 0, my: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
                <MKTypography variant="h5" sx={{mb: 2, fontWeight: 'bold'}}>
                    Notifications
                </MKTypography>
                <List>
                    <hr/>
                    <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <MKBox>
                            <MKTypography variant="h6" color="text" fontWeight="bold">
                                Activities
                            </MKTypography>
                            <MKTypography variant="button" color="text" fontWeight="medium">
                                Notify when another user sent you a new message.
                            </MKTypography>
                        </MKBox>
                        <MKBox>
                            <Switch checked={subscribedForNewslettersToggle}
                                    onChange={e => setSubscribedForNewslettersToggle(e.target.checked)}
                            />
                        </MKBox>
                    </ListItem>
                    <hr/>
                    <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <MKBox>
                            <MKTypography variant="h6" color="text" fontWeight="bold">
                                Newsletters
                            </MKTypography>
                            <MKTypography variant="button" color="text" fontWeight="medium">
                                Notify about last news and updating.
                            </MKTypography>
                        </MKBox>
                        <MKBox>
                            <Switch checked={subscribedForNotificationsToggle}
                                    onChange={e => setSubscribedForNotificationsToggle(e.target.checked)}
                            />
                        </MKBox>
                    </ListItem>
                </List>
            </Card>
            {/* Blocked */}
            <Card sx={{p: 2, mx: 0, my: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
                <MKTypography variant="h5" sx={{mb: 2, fontWeight: 'bold'}}>
                    Blocked Contacts
                </MKTypography>
                {myProfileState.myProfile?.blocked && myProfileState.myProfile.blocked.length ?
                    <MKBox display="flex" flexWrap="wrap">
                        {myProfileState.myProfile.blocked.map(block =>
                            <MKBox key={'blocked-' + block._id} display="flex" flexDirection="column"
                                   alignItems="center"
                                   margin={1}>
                                <MKAvatar
                                    src={block.profile?.avatar ? process.env.REACT_APP_API_URL + '/' + block.profile.avatar : defaultAvatar}
                                    alt={block.firstname}
                                    size="lg"/>
                                <MKButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => removeBlockedProfile(block)}
                                    iconOnly
                                    circular
                                    sx={{mt: -2, ml: 4}}
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </MKButton>
                            </MKBox>
                        )}
                    </MKBox>
                    : null
                }
            </Card>

            {/* Delete Profile */}
            <Card sx={{p: 2, mx: 0, my: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <MKTypography variant="h5" sx={{fontWeight: 'bold', mb: 2}}>Delete Data</MKTypography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <MKBox display="flex" alignItems="center" height="100%">
                            <MKTypography variant="h6" color="text" fontWeight="bold">Delete Profile</MKTypography>
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9}>
                        <MKBox display="flex" justifyContent="end">
                            <MKBox display="flex" alignItems="center" width="100%">
                                <MKInput type="text" label="Type your first and lastname" variant="standard"
                                         inputRef={deleteProfileInput} sx={{minWidth: '220px', width: 'inherit'}}
                                         onChange={e => onInputName(e)}/>
                            </MKBox>

                            <MKBox display="flex" alignItems="center" height="100%" sx={{mb: 1, mt: 1}}>
                                <MKButton size="small" variant="gradient" color="warning"
                                          sx={{ml: 'auto', width: '8em'}}
                                          onClick={deleteMyProfile}
                                          disabled={deleteProfileButtonDisable}
                                >
                                    Delete
                                </MKButton>
                            </MKBox>
                        </MKBox>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3}>
                        <MKBox display="flex" alignItems="center" height="100%">
                            <MKTypography variant="h6" color="text" fontWeight="bold">Delete User</MKTypography>
                        </MKBox>
                    </Grid>

                    <Grid item xs={12} sm={8} lg={9}>
                        <MKBox display="flex" justifyContent="end">
                            <MKBox display="flex" alignItems="center" width="100%">
                                <MKInput type="text" label="Type your email" variant="standard"
                                         inputRef={deleteUserInput} sx={{minWidth: '220px', width: 'inherit'}}
                                         onChange={e => onInputEmail(e)}/>
                            </MKBox>

                            <MKBox display="flex" alignItems="center" height="100%" sx={{mb: 1, mt: 1}}>
                                <MKButton size="small" variant="gradient" color="warning"
                                          sx={{ml: 'auto', width: '8em'}}
                                          onClick={deleteUser}
                                          disabled={deleteUserButtonDisable}
                                >
                                    Delete
                                </MKButton>
                            </MKBox>
                        </MKBox>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}
export default Settings;