import {
    faBaby,
    faBriefcase,
    faBrush,
    faBullseye, faEye, faGlassMartini,
    faGraduationCap, faPrayingHands,
    faRuler, faSearch,
    faSmoking, faVenusMars,
    faWeight
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Stack, Tab, Table, TableBody, TableCell, TableRow, Tabs} from "@mui/material";
import React, {useState} from 'react';
import defaultUserImage from "../../../../assets/images/defaults/default-user.jpg";
import {convertDateToAge, convertDateToZodiacSign} from "../../../../utils/utils";
import MKBadge from "../../../basic/MKBadge";
import MKBox from "../../../basic/MKBox";
import MKButton from "../../../basic/MKButton";
import MKTypography from "../../../basic/MKTypography";
import MasonryImageList from "./MasonryImageList";
import Card from "@mui/material/Card";
import {messages} from "../../../../utils/constants";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <MKBox sx={{p: 3}}>
                    {children ?? ''}
                </MKBox>
            )}
        </div>
    );
}

const ProfileInfo = ({profile, active, activities, onClickingOnActivity}) => {
    const [activeTab, setActiveTab] = useState(0);
    const zodiacSign = profile.zodiac ? null : convertDateToZodiacSign(profile?.birthday);
    const handleTabType = (event, newValue) => setActiveTab(newValue);

    const generateActivities = activities ? activities.map((activity, index) => (
        <MKButton
            key={'activity-' + index}
            variant="outlined"
            color="success"
            fullWidth sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}
            disabled={!active}
            onClick={() => onClickingOnActivity(profile?._id, activity.link)}
        >
            <FontAwesomeIcon icon={activity.icon} style={{marginRight: '1rem'}}/>
            {activity.label}
        </MKButton>
    )) : null;

    if (!profile) return null;
    return (
        <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
            <MKBox display="flex" flexDirection={{xs: 'column', md: 'row'}}>
                <MKBox sx={{mx: 'auto', maxWidth: '17.5rem'}}>
                    <MKBox
                        component="img"
                        src={profile.avatar ? process.env.REACT_APP_API_URL + '/' + profile.avatar : defaultUserImage}
                        alt={"user photo"}
                        maxWidth="100%"
                        borderRadius="xl"
                    />

                    <MKBox sx={{my: 2}}>
                        <Stack direction="column" spacing={1}>
                            {generateActivities}
                            {!active ?
                                <MKBox mb={1} textAlign="center">
                                    <MKTypography variant="button" color="error" sx={{m: 'auto'}}>
                                        {messages.notActivateAccount}
                                    </MKTypography>
                                </MKBox>
                                : null
                            }
                        </Stack>
                    </MKBox>
                </MKBox>

                <MKBox width="100%" sx={{pl: {xs: 0, md: 2}}}>
                    <MKBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <MKTypography variant="h4">
                            Hi, my name is {profile.firstname}
                        </MKTypography>
                    </MKBox>
                    <MKBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', pl: 1, mt: 1}}>
                        <MKBox
                            component="img"
                            src={zodiacSign?.icon}
                            alt={zodiacSign?.name}
                            sx={{mr: 1}}
                            width="20px"
                        />
                        <MKTypography variant="body2">
                            {`age: ${profile.age ?? convertDateToAge(profile.birthday)}`}
                        </MKTypography>
                        <MKTypography variant="body2" sx={{ml: 'auto'}}>
                            {`from: ${profile.country?.name} (${profile.city}) `}
                        </MKTypography>
                        <MKBox
                            component="img"
                            height="auto"
                            src={profile.country?.code ? `https://flagcdn.com/w20/${profile.country?.code?.toLowerCase()}.png` : ''}
                            srcSet={profile.country?.code ? `https://flagcdn.com/w40/${profile.country?.code?.toLowerCase()}.png 2x` : ''}
                            alt={profile.country?.name}
                            sx={{pl: 1}}
                        >
                        </MKBox>
                    </MKBox>

                    <MKBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', pl: 1}}>
                        <MKTypography variant="body2">
                            I speak: {profile.languages?.map((language, index) => (
                            <MKBadge key={'language-' + index} badgeContent={language.name} variant="contained"
                                     color="success" size="xs" container sx={{mr: 1}}/>))}
                        </MKTypography>
                    </MKBox>

                    <Table sx={{width: '100%', my: 2}} size="small" padding="checkbox"
                           className="profile-data-table bg-light">
                        <TableBody>
                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faVenusMars}
                                                         style={{marginRight: '.5rem', width: '20px'}}/>
                                        Gender
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.gender?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faSearch} style={{marginRight: '.5rem', width: '20px'}}/>
                                        Seeking
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.seeking?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faBullseye}
                                                         style={{marginRight: '.5rem', width: '20px'}}/>
                                        Goal
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.goal?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faBaby} style={{marginRight: '.7rem', width: '20px'}}/>
                                        Children
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.childrenAmount?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faGraduationCap}
                                                         style={{marginRight: '.7rem', width: '20px'}}/>
                                        Education
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.education?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faBriefcase}
                                                         style={{marginRight: '.7rem', width: '20px'}}/>
                                        Occupation
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.occupation ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faRuler} style={{marginRight: '.7rem', width: '20px'}}/>
                                        Height
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.height ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faWeight} style={{marginRight: '.7rem', width: '20px'}}/>
                                        Weight
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.weight ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faBrush} style={{marginRight: '.7rem', width: '20px'}}/>
                                        Hair Color
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.hairColor?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faEye} style={{marginRight: '.7rem', width: '20px'}}/>
                                        Eye Color
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.eyeColor?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faSmoking}
                                                         style={{marginRight: '.7rem', width: '20px'}}/>
                                        Smoking Habit
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.smokingHabit?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faGlassMartini}
                                                         style={{marginRight: '.7rem', width: '20px'}}/>
                                        Drinking Habit
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.drinkingHabit?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>
                                    <MKTypography variant="body 2" color="text" fontWeight="bold">
                                        <FontAwesomeIcon icon={faPrayingHands}
                                                         style={{marginRight: '.7rem', width: '20px'}}/>
                                        Religion
                                    </MKTypography>
                                </TableCell>
                                <TableCell>
                                    <MKTypography variant="body2" color="text">
                                        {profile.religion?.name ?? '-'}
                                    </MKTypography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </MKBox>
            </MKBox>
            <MKBox display="flex" flexDirection="column" justifyContent="start" sx={{width: '100%'}}>
                <MKBox>
                    <Tabs value={activeTab} onChange={handleTabType}>
                        <Tab label="About Me" sx={{zIndex: 0}}/>
                        <Tab label={`Photos (${profile?.photos?.length ?? 0})`}/>
                    </Tabs>
                </MKBox>
                <TabPanel value={activeTab} index={0}>
                    <MKBox>
                        <MKTypography variant="h5" color="text">
                            Hobbies
                        </MKTypography>
                        {profile.hobbies?.map((hobby, index) => (
                            <MKBadge key={'hobby-' + index} badgeContent={hobby.name} color="light" container
                                     sx={{mr: 1}}/>
                        ))}
                    </MKBox>
                    <MKBox sx={{mt: 3}}>
                        <MKTypography variant="h5" color="text">
                            Let me introduce myself
                        </MKTypography>
                        <MKTypography variant="body2">
                            {profile.about ?? ''}
                        </MKTypography>
                    </MKBox>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <MasonryImageList itemData={profile.photos}/>
                </TabPanel>
            </MKBox>
        </Card>
    );
};
export default ProfileInfo;