import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHome,
    faRibbon,
    faSearch,
    faSignInAlt, faSignOutAlt,
    faUserCircle,
    faUserPlus,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {faBell} from "@fortawesome/free-solid-svg-icons/faBell";
import MKBadge from "./components/basic/MKBadge";
import {links} from "./utils/constants";


const routes = [
    {
        name: "Home",
        icon: <FontAwesomeIcon icon={faHome}/>,
        href: links.main
    },
    {
        name: "About Us",
        icon: <FontAwesomeIcon icon={faUsers}/>,
        href: links.about
    },
    {
        name: "Login",
        icon: <FontAwesomeIcon icon={faSignInAlt}/>,
        href: links.login
    },
    {
        name: "Sign Up",
        icon: <FontAwesomeIcon icon={faUserPlus}/>,
        href: links.register
    },
    {
        name: "Search",
        icon: <FontAwesomeIcon icon={faSearch}/>,
        href: links.search
    },
    {
        name: "Subscriptions",
        icon: <FontAwesomeIcon icon={faRibbon}/>,
        href: links.subscriptions
    },
    {
        name: "My Profile",
        icon: <FontAwesomeIcon icon={faUserCircle}/>,
        href: links.myProfile
    },
    {
        name: "Sign out",
        icon: <FontAwesomeIcon icon={faSignOutAlt}/>,
        href: links.signOut
    }
];

export default routes;
