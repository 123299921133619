import React, {useEffect, useReducer} from 'react';
import {NotificationContext} from "./NotificationContext";
import {NotificationReducer} from "./NotificationReducer";
import {ADD_NOTIFICATION} from "./NotificationConstants";
import useServer from "../../hooks/useServer";
import {NOTIFICATION} from "../../serverUrls";

export const NotificationStateProvider = ({children}) => {
    const [state, dispatch] = useReducer(NotificationReducer, {notification: null}, () => ({notification: null}));
    const [notificationResponse, notificationError, notificationIsLoading, notificationSendRequest, notificationSetError] = useServer(NOTIFICATION);

    const getNotification = () => notificationSendRequest({method: 'get'});

    const clearMessagesBySender = id => notificationSendRequest({method: 'post', data: {clear: 'messages', id}});

    const clearInterested = () => notificationSendRequest({method: 'post', data: {clear: 'interested'}});

    const clearAllNotification = () => notificationSendRequest({method: 'post', data: {clear: 'all'}});

    const setNotification = notification => {
        if (typeof notification !== 'string') {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: notification
            });
        }
    }

    // On notifications response
    useEffect(() => {
        if (notificationResponse) setNotification(notificationResponse.data);
    }, [notificationResponse])

    const {notification} = state;

    return (
        <NotificationContext.Provider value={{notification, setNotification: setNotification, getNotification, clearAllNotification,
            clearMessagesBySender, clearInterested, notificationError, notificationSetError, notificationIsLoading}}>
            {children}
        </NotificationContext.Provider>
    );
};