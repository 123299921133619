import React, {useEffect, useRef, useState} from 'react';
import {faAngleUp} from '@fortawesome/free-solid-svg-icons';
import MKButton from 'components/basic/MKButton';
import ChatMsg from './ChatMsg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Chat = ({ messages, avatar, profileId, loadMoreMessages, showMoreButton }) => {
    const [scrollToBottom, setScrollToBottom] = useState(true);

    const chat = useRef(null);
    useEffect(() => {
        if (messages && chat && scrollToBottom) chat.current?.scrollIntoView({ behavior: 'smooth' });
        return () => setScrollToBottom(true);
    }, [messages])

    const handleOnLoadMoreMessages = () => {
        setScrollToBottom(false);
        loadMoreMessages();
    }

    const getText = message => {
        let result = '';
        if (message.iceBreaker && message.iceBreaker.name) { result = message.iceBreaker.name; return result; }
        if (message.greeting && message.greeting.name) { result = message.greeting.name; return result; }
        if (message.letter) { result = message.letter; return result; }
        return result;
    }

    const getImage = message => {
        let result = null;
        if (message.action && message.action.img) { result = message.action.img; return result; }
        if (message.gift && message.gift.img) { result = message.gift.img; return result; }
        return result;
    }

    const generateDialog = () => {
        let dialog = [];
        messages.map((message, index) => {
            if (index === 0) {
                if (showMoreButton) {
                    dialog.push(
                        <MKButton
                            key={'loadMoreButton'}
                            size="small"
                            variant="contained"
                            color="info" circular
                            iconOnly
                            sx={{ ml: 'calc(50% - .5em)' }}
                            onClick={handleOnLoadMoreMessages}
                        >
                            <FontAwesomeIcon icon={faAngleUp} />
                        </MKButton>
                    )
                }
                dialog.push(
                    <ChatMsg key={'message-' + index}
                        avatar={avatar ? process.env.REACT_APP_API_URL + '/' + avatar : ''}
                        side={(message.sender?._id === profileId) ? 'right' : 'left'}
                        messages={[{ 
                            text: getText(message),
                            date: message.sentAt,
                            img: getImage(message)
                        }]}
                    />
                );
            } else if (message.sender?._id === messages[index - 1].sender?._id) {
                if (dialog[dialog.length - 1]) {
                    dialog[dialog.length - 1].props?.messages?.push(
                        {
                            text: getText(message),
                            date: message.sentAt,
                            img: getImage(message)
                        }
                    );
                }
            } else {
                dialog.push(
                    <ChatMsg
                        key={'message-' + index}
                        avatar={avatar ? process.env.REACT_APP_API_URL + '/' + avatar : ''}
                        side={(message.sender?._id === profileId) ? 'right' : 'left'}
                        messages={[{ 
                            text: getText(message), 
                            date: message.sentAt,
                            img: getImage(message)
                        }]}
                    />
                );
            }
            if (index === messages.length - 1) {
                dialog.push(<div key={'last'} ref={chat}></div>)
            }
        });
        return dialog;
    }

    return (
        <>
            {generateDialog().map(item => (item))}
        </>
    );
};

export default Chat;