import PropTypes from "prop-types";
import {Link, NavLink} from "react-router-dom";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";

function TopNavbarDropdown({name, icon, light, href, route, ...rest}) {
    const routeComponent = {component: Link, to: route};

    return (
        <>
            <MKBox
                className="topNavbar-link"
                component={NavLink}
                to={href}
                {...rest}
                mx={1}
                p={1}
                display="flex"
                alignItems="baseline"
                color={light ? "white" : "dark"}
                opacity={light ? 1 : .9}
                sx={{cursor: "pointer", userSelect: "none"}}
                {...(route && routeComponent)}
            >
                <MKTypography
                    variant="body2"
                    lineHeight={1}
                    color={"inherit"}
                    sx={{alignSelf: "center", "& *": {verticalAlign: "middle"}}}
                >
                    {icon}
                </MKTypography>
                { name ?
                    <MKTypography
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color={"inherit"}
                        sx={{fontWeight: "100%", ml: 1, mr: 0.25}}
                    >
                        {name}
                    </MKTypography>
                    : null
                }
            </MKBox>
        </>
    );
}

// Setting default values for the props of DefaultNavbarDropdown
TopNavbarDropdown.defaultProps = {
    light: false,
    href: "",
    route: "",
};

// Typechecking props for the DefaultNavbarDropdown
TopNavbarDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    light: PropTypes.bool,
    href: PropTypes.string,
    route: PropTypes.string,
};

export default TopNavbarDropdown;
