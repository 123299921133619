import {ScreenSizeContext} from "./ScreenSizeContext";
import {useEffect, useReducer} from "react";
import {ScreenSizeReducer} from "./ScreenSizeReducer";
import {SCREEN_LG, SCREEN_MD, SCREEN_SM, SCREEN_XL, SCREEN_XS, SCREEN_XXL} from "./ScreenSizeConstants";
import breakpoints from "../../assets/theme/base/breakpoints";

export const ScreenSizeProvider = ({children}) => {
    const [state, dispatch] = useReducer(ScreenSizeReducer, {screenSizeType: null, screenSizeValue: window.innerWidth});

    useEffect(() => {
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.sm) dispatch({type: SCREEN_XS, payload: window.innerWidth});
            if (window.innerWidth >= breakpoints.values.sm && window.innerWidth < breakpoints.values.md) dispatch({type: SCREEN_SM, payload: window.innerWidth});
            if (window.innerWidth >= breakpoints.values.md && window.innerWidth < breakpoints.values.lg) dispatch({type: SCREEN_MD, payload: window.innerWidth});
            if (window.innerWidth >= breakpoints.values.lg && window.innerWidth < breakpoints.values.xl) dispatch({type: SCREEN_LG, payload: window.innerWidth});
            if (window.innerWidth >= breakpoints.values.xl && window.innerWidth < breakpoints.values.xxl) dispatch({type: SCREEN_XL, payload: window.innerWidth});
            if (window.innerWidth >= breakpoints.values.xxl) dispatch({type: SCREEN_XXL, payload: window.innerWidth});
        }

        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();

        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    const {screenSizeType, screenSizeValue} = state;

    return (
        <ScreenSizeContext.Provider value={{screenSizeType, screenSizeValue}}>
            {children}
        </ScreenSizeContext.Provider>
    )
}