import React from 'react';
import {Card, CardActions, CardContent, Grid} from "@mui/material";
import MKTypography from "../../../basic/MKTypography";
import MKButton from "../../../basic/MKButton";
import image from 'assets/images/defaults/noProfile.jpg';
import {useNavigate} from "react-router-dom";

const NoProfileInfo = ({title, btnLabel}) => {
    const navigate = useNavigate();
    return (
        <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
            <Grid container item xs={12}>
                <Card
                    sx={({
                             functions: {rgba, linearGradient},
                             palette: {black},
                             borders: {borderRadius},
                         }) => ({
                        backgroundImage: image ? `${linearGradient(
                            rgba(black.main, 0.5),
                            rgba(black.main, 0.5)
                        )}, url(${image})` : null,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: borderRadius.xl,
                        width: "100%"
                    })}>
                    <CardContent>
                        <MKTypography variant="h4" color="white" textAlign="center" sx={{mt: 2}}>
                            {title}
                        </MKTypography>
                    </CardContent>
                    <CardActions>
                        <MKButton onClick={() => navigate('/my-profile/edit')} size="small" variant="contained"
                                  color="success" sx={{mx: 'auto', mb: 1}}>
                            {btnLabel}
                        </MKButton>
                    </CardActions>
                </Card>
            </Grid>
        </Card>
    );
};

export default NoProfileInfo;