import axios from "axios";

const urls = [
    '/api/auth/signin',
    '/api/auth/signup',
    '/api/auth/forgot-password',
    '/api/auth/reset-password',
    '/api/story',
    '/api/advantage',
    '/api/concept',
    '/api/article',
    '/api/member'
];

export function TokenInterceptor() {
    axios.interceptors.request.use(
        (req) => {
            const controller = new AbortController();
            const token = localStorage.getItem('token');
            if (!token && !urls.some(url => req.url.includes(url))) {
                return controller.abort()
            } else {
                if (req.url.includes('/api/')) req.url = process.env.REACT_APP_API_URL + req.url;
                if (!req.url.includes('/api/auth/reset-password')) req.headers['Authorization'] = `Bearer ${token}`;
                req.headers['Content-Type'] = req.data instanceof FormData ?
                    'application/x-www-form-urlencoded'
                    : 'application/json';
                return req;
            }
        },
        (err) => {
            return Promise.reject(err);
        }
    );
}