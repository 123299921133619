import React from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {ButtonGroup} from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/basic/MKBox";
import MKButton from "components/basic/MKButton";

// Icons
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

const PhotoContent = ({photos, onCLick, show}) => {
    return (
        <MKBox component="section" py={6}>
            <Container>
                <Grid container spacing={3}>
                    {photos && photos.length > 0
                        ? photos.map((photo, index) => (
                                <Grid item xs={12} md={6} lg={6} sx={{mb: 2}} key={'photo-' + index}>
                                    <MKBox
                                        width="100%"
                                        height="auto"
                                        borderRadius="lg"
                                        shadow="md"
                                        className={"d-flex justify-content-center"}
                                    >
                                        <img
                                            src={photo}
                                            srcSet={`${photo} 2x`}
                                            alt={'photo-' + index}
                                            width={"100%"}
                                            loading="lazy"
                                            style={{borderRadius: '0.5rem'}}
                                        />
                                    </MKBox>
                                    <ButtonGroup
                                        variant="outlined"
                                        size="small"
                                        style={{display: 'block', width: 'auto', margin: "auto"}}
                                    >
                                        <MKButton size="small" variant="gradient" color="warning"
                                                  style={{width: "50%"}}
                                                  onClick={() => {
                                                      show(index)
                                                  }}
                                        >
                                            <FontAwesomeIcon icon={faEdit} className="me-2"/> Edit
                                        </MKButton>
                                        <MKButton size="small" variant="gradient" color="error"
                                                  style={{width: "50%"}}
                                                  onClick={() => {
                                                      onCLick(index)
                                                  }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} className="me-2"/>Delete
                                        </MKButton>
                                    </ButtonGroup>
                                </Grid>
                            )
                        )
                        : null
                    }
                </Grid>
            </Container>
        </MKBox>
    );
}

export default PhotoContent;