import {useContext, useEffect} from 'react';
import {AuthContext} from "../contexts/auth/AuthContext";

const SignOut = () =>  {
    const {logout} = useContext(AuthContext);

    useEffect(() => {
        logout();
    }, [logout])

    return null;
}
export default SignOut;