import React, {useEffect, useRef, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import MKBox from "../components/basic/MKBox";
import bgImage from "../assets/images/backgrounds/bg-subscriptions.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Rellax from "rellax";
import PricingCard from "../components/cards/PricingCard";
import MKTypography from "../components/basic/MKTypography";
import useServer from "../hooks/useServer";
import {MEMBERSHIP, PRODUCT} from "../serverUrls";
import {toastConfig} from "../utils/utils";
import {links} from "../utils/constants";

const SubscriptionsPage = () => {
    const headerRef = useRef(null);
    const titleRef = useRef(null);
    const [membershipsResponse, membershipsError, membershipsIsLoading, membershipsSendRequest, membershipsSetError] = useServer(MEMBERSHIP);
    const [productsResponse, productsError, productsIsLoading, productsSendRequest, productsSetError] = useServer(PRODUCT);
    const [memberships, setMemberships] = useState([]);
    const [memberTabs, setMemberTabs] = useState([]);
    const [activeMemberTab, setActiveMemberTab] = useState(0);
    const [packs, setPacks] = useState([]);
    const [packTabs, setPackTabs] = useState([]);
    const [activePackTab, setActivePackTab] = useState(0);

    const handleMemberTab = ({}, index) => setActiveMemberTab(index);

    const handlePackTab = ({}, index) => setActivePackTab(index);

    const getMembershipPrice = index => memberships.length ? memberships[index]?.price : 0;

    const getMembershipDiscount = index => {
        if (memberships.length) {
            const basePrice = memberships[0]?.price ?? 1;
            const targetPrice = memberships[index]?.price;
            return Math.round((1 - targetPrice / basePrice) * 100);
        } else return 0;
    }

    const getPackPrice = index => packs.length ? packs[index]?.price : 0;

    const getSpecOption = title => {
        if (!title) return null;
        const activePack = packs[activePackTab];
        const option = `amountOf${title}`;
        const value = activePack?.content.find(c => c.option === option)?.value;
        return {label: `Send ${value ?? ''} ${title}`, includes: !!value};
    }

    // Setting up rellax
    useEffect(() => {
        const headerParallax = new Rellax(headerRef.current, {
            speed: -6,
        });
        const titleParallax = new Rellax(titleRef.current, {
            speed: -1,
        });
        membershipsSendRequest({method: 'get'});
        productsSendRequest({method: 'get'});

        return () => {
            headerParallax.destroy();
            titleParallax.destroy();
        }
    }, []);

    // On memberships response
    useEffect(() => {
        if (membershipsResponse) {
            const newMemberships = membershipsResponse.data.sort((a, b) => a.months - b.months);
            const newMemberTabs = newMemberships.map(m => ({id: m.id, title: m.months + ' mo'}));
            setMemberTabs(newMemberTabs);
            setMemberships(newMemberships);
        }
    }, [membershipsResponse])

    // On products response
    useEffect(() => {
        if (productsResponse) {
            const newPacks = productsResponse.data.filter(p => p.content?.length > 1);
            const newPackTabs = newPacks.map((p, index) => ({id: p._id, title: ++index + ' pack'}))
            setPackTabs(newPackTabs)
            setPacks(newPacks);
        }
    }, [productsResponse])

    // On Error
    useEffect(() => {
        if (membershipsError) {
            toast.error(membershipsError.data?.message, toastConfig);
            membershipsSetError(null);
        }
        if (productsError) {
            toast.error(productsError.data?.message, toastConfig);
            productsSetError(null);
        }
        return () => {
            membershipsSetError(null);
            productsSetError(null);
        }
    }, [membershipsError, productsError])

    return (
        <div className='main'>
            <ToastContainer/>
            <MKBox
                ref={headerRef}
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        ref={titleRef}
                        container
                        item
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{mx: "auto", textAlign: "center"}}
                    >
                        <MKTypography variant="h3" color="white" mb={2}>
                            Pricing
                        </MKTypography>
                        <MKTypography variant="body2" color="white">
                            There are 3 options: free usage, pay as you go and unlimited access by membership
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Container>
                <MKBox mt={-16}>
                    <Container>
                        <MKBox position="relative" zIndex={2} px={{xs: 1, sm: 0}}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} lg={4}>
                                    <PricingCard
                                        badge={{color: "light", label: "Free"}}
                                        price={{
                                            currency: "$",
                                            value: 0,
                                            type: "mo",
                                            save: 0
                                        }}
                                        specifications={[
                                            {label: "Place your ad for free", includes: true},
                                            {label: "Post 10 photos", includes: true},
                                            {label: "Unlimited searches", includes: true},
                                            {label: "Send greetings", includes: true},
                                            {label: "Lets Match", includes: true},
                                            {label: "Send ice-breakers", includes: true},
                                            {label: 'line'},
                                            {label: "Send letters", includes: false},
                                            {label: "Send gifts", includes: false},
                                            {label: "Send actions", includes: false}
                                        ]}
                                        action={{
                                            isActive: false,
                                            route: "/",
                                            color: "light",
                                            label: "free",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <PricingCard
                                        color="dark"
                                        badge={{color: "info", label: "Pay As You Go"}}
                                        price={{
                                            currency: "$",
                                            value: getPackPrice(activePackTab),
                                            type: "pack",
                                            save: 0
                                        }}
                                        specifications={[
                                            {label: "Place your ad for free", includes: true},
                                            {label: "Post 10 photos", includes: true},
                                            {label: "Unlimited searches", includes: true},
                                            {label: "Send greetings", includes: true},
                                            {label: "Lets Match", includes: true},
                                            {label: "Send ice-breakers", includes: true},
                                            {label: 'line'},
                                            getSpecOption('Letters'),
                                            getSpecOption('Gifts'),
                                            getSpecOption('Actions')
                                        ]}
                                        action={{
                                            isActive: true,
                                            route: links.myProfile,
                                            state: {path: links.cart, product: packs.length ? packs[activePackTab] : null},
                                            color: "info",
                                            label: "buy",
                                        }}
                                        tabs={packTabs}
                                        activeTab={activePackTab}
                                        handleTab={handlePackTab}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <PricingCard
                                        badge={{color: "success", label: "Unlimited"}}
                                        price={{
                                            currency: "$",
                                            value: getMembershipPrice(activeMemberTab),
                                            type: "mo",
                                            save: getMembershipDiscount(activeMemberTab)
                                        }}
                                        specifications={[
                                            {label: "Place your ad for free", includes: true},
                                            {label: "Post 10 photos", includes: true},
                                            {label: "Unlimited searches", includes: true},
                                            {label: "Send greetings", includes: true},
                                            {label: "Lets Match", includes: true},
                                            {label: "Send ice-breakers", includes: true},
                                            {label: 'line'},
                                            {label: "Send unlimited letters", includes: true},
                                            {label: "Send unlimited gifts", includes: true},
                                            {label: "Send 5 actions", includes: true}
                                        ]}
                                        action={{
                                            isActive: true,
                                            route: links.myProfile,
                                            state: {path: links.cart, membership: memberships.length ? memberships[activeMemberTab] : null},
                                            color: "success",
                                            label: "join",
                                        }}
                                        tabs={memberTabs}
                                        activeTab={activeMemberTab}
                                        handleTab={handleMemberTab}
                                    />
                                </Grid>
                            </Grid>
                        </MKBox>
                    </Container>
                </MKBox>
            </Container>
        </div>
    );
};

export default SubscriptionsPage;