import {SCREEN_LG, SCREEN_MD, SCREEN_SM, SCREEN_XL, SCREEN_XS, SCREEN_XXL} from "./ScreenSizeConstants";

const handlers = {
    [SCREEN_XS]: (state, {payload}) => ({...state, screenSizeType: SCREEN_XS, screenSizeValue: payload}),
    [SCREEN_SM]: (state, {payload}) => ({...state, screenSizeType: SCREEN_SM, screenSizeValue: payload}),
    [SCREEN_MD]: (state, {payload}) => ({...state, screenSizeType: SCREEN_MD, screenSizeValue: payload}),
    [SCREEN_LG]: (state, {payload}) => ({...state, screenSizeType: SCREEN_LG, screenSizeValue: payload}),
    [SCREEN_XL]: (state, {payload}) => ({...state, screenSizeType: SCREEN_XL, screenSizeValue: payload}),
    [SCREEN_XXL]: (state, {payload}) => ({...state, screenSizeType: SCREEN_XXL, screenSizeValue: payload}),
    DEFAULT: state => state
}

export const ScreenSizeReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT;
    return handler(state, action);
}