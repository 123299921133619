/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/basic/MKBox";

// Material Kit 2 PRO React examples
import CenteredBlogCard from "../../cards/CenteredBlogCard";

// data
import {aboutUsFacts} from "../../../utils/constants";
import MKTypography from "components/basic/MKTypography";
import {FadeLoader} from "react-spinners";
import React from "react";

function Information({concepts}) {
  return (
    <MKBox component="section" py={3}>
      <MKBox display="flex" justifyContent="center" sx={{mb: 10}}>
        <MKTypography variant="h3">Our Concept</MKTypography>
      </MKBox>
      
      <Container>
        <Grid container spacing={3} alignItems="center">
            {concepts && concepts.length > 0 ?
                concepts.map((concept, index) => (
                    <Grid item xs={12} md={6} lg={4} key={concept.title + index}>
                        <MKBox mb={5}>
                            <CenteredBlogCard
                                image={concept.img ? process.env.REACT_APP_API_URL + '/' + concept.img : ''}
                                title={concept.title}
                                description={concept.description}
                            />
                        </MKBox>
                    </Grid>
                ))
                :
                <MKBox textAlign="center" width="100%" mt={10} mb={20}>
                    <FadeLoader radius={5}/>
                </MKBox>
            }
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
