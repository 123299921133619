import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import MKButton from "components/basic/MKButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import MKBadge from "../basic/MKBadge";
import {AppBar, Tab, Tabs} from "@mui/material";
import React, {useState} from "react";
import Divider from "@mui/material/Divider";

function PricingCard({color, badge, price, specifications, action, shadow, tabs, activeTab, handleTab}) {

    const navigate = useNavigate();
    const renderSpecifications = specifications.map(({label, includes}) => (

            label === 'line' ? <Divider key={label}
                sx={{background: color === "white" ? "black" : "white", margin: 0}}
                /> :
                <MKBox key={label} display="flex" alignItems="center" p={1}>
                    <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="1.5rem"
                        height="1.5rem"
                        mr={2}
                        mt={-0.125}
                    >
                        <MKTypography
                            variant="body1"
                            color={color === "white" ? "text" : "white"}
                            sx={{lineHeight: 0}}
                        >
                            <FontAwesomeIcon icon={includes ? faCheck : faTimes} size="xs"/>
                        </MKTypography>
                    </MKBox>
                    <MKTypography
                        variant="body2"
                        color={color === "white" ? "text" : "white"}
                        fontWeight="regular"
                    >
                        {label}
                    </MKTypography>
                </MKBox>




    ));

    return (
        <Card sx={{boxShadow: ({boxShadows: {lg}}) => (shadow ? lg : "none"), height: '100%'}}>
            <MKBox
                bgColor={color}
                variant="contained"
                borderRadius="xl"
                display="flex"
                flexDirection="column"
                height="100%"
            >
                <MKBox
                    bgColor={badge.color}
                    width="max-content"
                    px={4}
                    pt={0}
                    pb={0.5}
                    mx="auto"
                    mt={-1.375}
                    borderRadius="section"
                    lineHeight={1}
                >
                    <MKTypography
                        variant="caption"
                        textTransform="uppercase"
                        fontWeight="medium"
                        color={badge.color === "light" ? "dark" : "white"}
                    >
                        {badge.label}
                    </MKTypography>
                </MKBox>
                <MKBox pt={3} pb={2} px={2} textAlign="center">
                    <MKBox my={1}>
                        <MKTypography variant="h1" color={color === "white" ? "dark" : "white"}>
                            <MKTypography
                                display="inline"
                                component="small"
                                variant="h5"
                                color="inherit"
                                verticalAlign="top"
                            >
                                {price.currency}
                            </MKTypography>
                            {price.value}
                            <MKTypography display="inline" component="small" variant="h5" color="inherit">
                                /{price.type}
                            </MKTypography>

                            {price.save > 0
                                ? <MKBox bgColor="#ffffff" borderRadius="lg">
                                    <MKTypography color="secondary" sx={{backgroundColor: 'white'}}>
                                        save <MKBadge badgeContent={`-${price.save}%`} size="lg" color="secondary"
                                                      container/>
                                    </MKTypography>
                                </MKBox>
                                : <MKBox bgColor="#ffffff" borderRadius="lg">
                                    <MKTypography sx={{backgroundColor: 'white'}}>
                                        no save
                                    </MKTypography>
                                </MKBox>
                            }
                        </MKTypography>
                    </MKBox>
                </MKBox>


                {tabs && <MKBox style={{padding: 15}}>
                    <Tabs value={activeTab} onChange={handleTab} sx={{height: '2.5rem'}}>
                        {tabs?.length && tabs.map((tab, index) => (
                            <Tab
                                key={tab.id ?? index}
                                label={
                                    <MKBox py={0.5} px={2} color="inherit">
                                        {tab.title ?? ''}
                                    </MKBox>
                                }
                            />
                        ))}
                    </Tabs>
                </MKBox>
                }

                <MKBox pb={3} px={3} display="flex" flexDirection="column" flex="1 0 auto">
                    {renderSpecifications}
                    {action.isActive ? (
                        <MKBox mt="auto">
                            <MKButton
                                variant="contained"
                                color={action.color}
                                fullWidth
                                sx={{mt: 3}}
                                onClick={() => navigate(action.route, {state: action.state})}
                            >
                                {action.label}&nbsp;
                            </MKButton>
                        </MKBox>
                    ) : (<MKBox mt="auto">
                            <MKButton
                                variant="contained"
                                color={action.color}
                                fullWidth
                                sx={{mt: 3}}
                            >
                                {action.label}&nbsp;
                            </MKButton>
                        </MKBox>
                    )}
                </MKBox>
            </MKBox>
        </Card>
    );
}

// Setting default props for the DefaultPricingCard
PricingCard.defaultProps = {
    color: "white",
    shadow: true,
};

// Typechecking props for the DefaultPricingCard
PricingCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "white",
    ]),
    badge: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "light",
            "dark",
        ]).isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    price: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    action: PropTypes.shape({
        isActive: PropTypes.bool.isRequired,
        route: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "light",
            "dark",
        ]).isRequired,
    }).isRequired,
    shadow: PropTypes.bool,
};

export default PricingCard;
