import {faCommentDots, faFilter, faHandHoldingHeart, faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container} from "@mui/material";
import Card from "@mui/material/Card";
import ViewProfile from 'components/sections/myProfile/ViewProfile/ViewProfile';
import {AuthContext} from 'contexts/auth/AuthContext';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import Rellax from "rellax";
import bgImage from "../assets/images/backgrounds/bg-search.jpg";
import breakpoints from "../assets/theme/base/breakpoints";
import MKBadge from "../components/basic/MKBadge";
import MKBox from "../components/basic/MKBox";
import MKButton from "../components/basic/MKButton";
import MKTypography from "../components/basic/MKTypography";
import ProfileList from "../components/sections/search/ProfileList";
import SearchFilterBar from "../components/sections/search/SearchFilterBar";
import SearchSortBar from "../components/sections/search/SearchSortBar";
import {ScreenSizeContext} from "../contexts/screenSize/ScreenSizeContext";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import useServer from "../hooks/useServer";
import {PROFILE, PROFILE_FAVORITE, PROFILE_LIKE, PROFILE_ME} from "../serverUrls";
import {
    ageLimits, defaultActivities,
    heightLimits,
    initialSearchProfilesPagination as initialPagination,
    links, messages,
    weightLimits
} from "../utils/constants";
import {toastConfig} from "../utils/utils";
import {useNavigate} from "react-router-dom";

export const initialFilters = {
    ageRange: [...ageLimits],
    heightRange: [...heightLimits],
    weightRange: [...weightLimits],
    countries: [],
    city: '',
    genders: [],
    goals: [],
    maritalStatuses: [],
    children: [],
    educations: [],
    occupation: '',
    hairColors: [],
    eyeColors: [],
    smokingHabits: [],
    drinkingHabits: [],
    religions: [],
    hobbies: []
};

const SearchPage = () => {
    const headerRef = useRef(null);
    // States
    const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoints.values.lg);
    const [profiles, setProfiles] = useState([]);
    const [onlineFirst, setOnlineFirst] = useState(false);
    const [likedFirst, setLikedFirst] = useState(false);
    const [profileId, setProfileId] = useState();
    const [pagination, setPagination] = useState({total: 0, onPage: 0, pages: 0, currentPage: 0});
    const [paginationSettings, setPaginationSettings] = useState({...initialPagination});
    const [tags, setTags] = useState([]);
    const [filters, setFilters] = useState(initialFilters);
    const [isFiltered, setIsFiltered] = useState(true);
    const [additionalProfiles, setAdditionalProfiles] = useState(false);
    const [sort, setSort] = useState();
    const [myProfile, setMyProfile] = useState();
    const [selectedProfile, setSelectedProfile] = useState();
    // Context
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const {screenSizeType, screenSizeValue} = useContext(ScreenSizeContext);
    const {getUser, setUser} = useContext(AuthContext);
    // Server
    const [profilesResponse, profilesError, profilesIsLoading, profilesSendRequest, profilesSetError] = useServer(PROFILE);
    const [myProfileResponse, myProfileError, myProfileIsLoading, myProfileSendRequest, myProfileSetError] = useServer(PROFILE_ME);
    const [likeResponse, likeError, likeIsLoading, likeSendRequest, likeSetError] = useServer(PROFILE_LIKE);
    const [addFavoriteResponse, addFavoriteError, addFavoriteIsLoading, addFavoriteSendRequest, addFavoriteSetError] = useServer(PROFILE_FAVORITE + '/' + profileId);
    const navigate = useNavigate();

    const handleRemoveTag = tag => {
        const newTags = [...tags];
        const newFilters = {...filters};
        const index = newTags.indexOf(tag);
        if (index > -1) {
            newTags.splice(index, 1);
            newFilters[tag] = initialFilters[tag];
        } else return;
        setTags(newTags);
        setFilters(newFilters);
    }

    const doActivity = (id, link) => {
        if (link === links.myProfile_communication) {
            setProfileId(id);
        } else if (link === links.myProfile_letsMatch) {
            navigate(links.myProfile, {state: {profiles: [selectedProfile], myProfile, path: 'lets-match'}});
        } else navigate(link);
    }

    // Get profiles by filter
    const getProfilesByFilter = () => {
        if (filters.ageRange) {
            if (filters.ageRange[0] < ageLimits[0] || filters.ageRange[0] > ageLimits[1]) filters.ageRange[0] = ageLimits[0];
            if (filters.ageRange[1] < ageLimits[0] || filters.ageRange[1] > ageLimits[1]) filters.ageRange[1] = ageLimits[1];
        }
        if (filters.heightRange) {
            if (filters.heightRange[0] < heightLimits[0] || filters.heightRange[0] > heightLimits[1]) filters.heightRange[0] = heightLimits[0];
            if (filters.heightRange[1] < heightLimits[0] || filters.heightRange[1] > heightLimits[1]) filters.heightRange[1] = heightLimits[1];
        }
        if (filters.weightRange) {
            if (filters.weightRange[0] < weightLimits[0] || filters.weightRange[0] > weightLimits[1]) filters.weightRange[0] = weightLimits[0];
            if (filters.weightRange[1] < weightLimits[0] || filters.weightRange[1] > weightLimits[1]) filters.weightRange[1] = weightLimits[1];
        }

        profilesSendRequest({
            method: 'get',
            params: Object.assign(filters, initialPagination, sort)
        });
        setPaginationSettings(initialPagination);
        setAdditionalProfiles(false);
        setIsFiltered(true);
    }

    // Get more profiles by clicking on appropriate button
    const getMoreProfiles = () => {
        const newPaginationSettings = {
            ...paginationSettings,
            offset: (pagination.currentPage < pagination.pages)
                ? paginationSettings.offset + paginationSettings.limit
                : paginationSettings.offset
        }
        profilesSendRequest({
            method: 'get',
            params: Object.assign(filters, newPaginationSettings, sort)
        });
        setPaginationSettings(newPaginationSettings);
        setAdditionalProfiles(true);
        setIsFiltered(true);
    }

    const clearAllFiltersAndSort = () => {
        setFilters(initialFilters);
        setSort(null);
        setLikedFirst(false);
        setOnlineFirst(false);
        getProfilesByFilter();
    }

    // Like profile
    const handleLike = profile => {
        likeSendRequest({method: 'post', data: profile});
    }

    // Sort profiles to show online first
    const sortProfiles = (profiles, onlineFirst, likedFirst) => {
        if (!onlineFirst && !likedFirst) return profiles;
        let lastDate = new Date();
        let sortedProfiles = [];

        if (onlineFirst) {
            profiles.map(profile => {
                if (profile.user.userLastAction &&
                    profile.user.userLastAction.actionAt &&
                    new Date(profile.user.userLastAction.actionAt) < lastDate) {
                    sortedProfiles.unshift(profile);
                    lastDate = new Date(profile.user.userLastAction.actionAt);
                } else {
                    sortedProfiles.push(profile);
                }
            })
        } else if (likedFirst) {

            let likedProfiles = [];
            let unlikedProfiles = [];

            profiles.map((profile, index) => {
                if (profile.liked?.includes(myProfile._id)) {
                    likedProfiles.push(profile);
                } else {
                    unlikedProfiles.push(profile);
                }
            })
            sortedProfiles = likedProfiles.concat(unlikedProfiles);
        }

        return sortedProfiles;
    }

    useEffect(() => {
        let newTags = [];
        Object.keys(filters).forEach(key => {
            switch (key) {
                case 'ageRange':
                    if (filters[key][0] !== ageLimits[0] || filters[key][1] !== ageLimits[1]) newTags.push(key);
                    break;
                case 'heightRange':
                    if (filters[key][0] !== heightLimits[0] || filters[key][1] !== heightLimits[1]) newTags.push(key);
                    break;
                case 'weightRange':
                    if (filters[key][0] !== weightLimits[0] || filters[key][1] !== weightLimits[1]) newTags.push(key);
                    break;
                case 'name':
                    break;
                case 'order':
                    break;
                case 'column':
                    break;
                default:
                    if (filters[key]?.length) newTags.push(key);
                    break;
            }
        })
        setIsFiltered(false);
        setTags(newTags);
    }, [filters])

    // On load page
    useEffect(() => {
        if (getUser()?.profile) {
            myProfileSendRequest({method: 'get'});
            getProfilesByFilter();
        } else navigate(links.myProfile);
    }, [])

    // On profiles response
    useEffect(() => {
        if (!profilesResponse && !likeResponse) return;
        if (additionalProfiles) {
            const newProfiles = profiles.concat(profilesResponse.data?.profiles)
            setProfiles(newProfiles);
        } else {
            setProfiles(profilesResponse.data?.profiles);
        }
        setPagination(profilesResponse.data?.pagination);
    }, [profilesResponse])

    // On my profile response
    useEffect(() => {
        if (!myProfileResponse) return;
        setMyProfile(myProfileResponse.data);
    }, [myProfileResponse])

    // On Like response
    useEffect(() => {
        if (!likeResponse) return;
        setMyProfile(likeResponse.data?.me ?? null);
        let newProfiles = [...profiles];
        newProfiles.forEach((profile, index) => {
            if (profile._id === likeResponse.data.liked?._id) {
                newProfiles[index].liked = likeResponse.data?.liked?.liked;
            }
        })
        setProfiles(newProfiles);

    }, [likeResponse])

    // On Errors
    useEffect(() => {
        if (!profilesError && !likeError && !myProfileError) return;
        if (profilesError) {
            toast.error(profilesError.data?.message ?? 'Something went wrong', toastConfig);
            profilesSetError(null);
        }
        if (likeError) {
            toast.error(likeError.data?.message ?? 'Something went wrong', toastConfig);
            likeSetError(null);
        }
        if (addFavoriteError) {
            toast.error(addFavoriteError.data?.message ?? 'Something went wrong', toastConfig);
            addFavoriteSetError(null);
        }
        if (myProfileError) {
            if (myProfileError.status === 404) navigate('/my-profile');
            else toast.error(myProfileError.data?.message ?? 'Something went wrong', toastConfig);
            myProfileSetError(null);
        }
        return () => {
            profilesSetError(null);
            likeSetError(null);
            addFavoriteSetError(null);
            myProfileSetError(null);
        }
    }, [profilesError, likeError, myProfileError, addFavoriteError])

    useEffect(() => {
        getProfilesByFilter();
    }, [sort])

    // On Loading
    useEffect(() => {
        if (profilesIsLoading || likeIsLoading || myProfileIsLoading || addFavoriteIsLoading) {
            setShowSpinner(true);
        } else if (showSpinner) setShowSpinner(false);

        return () => setShowSpinner(false);
    }, [profilesIsLoading, likeIsLoading, myProfileIsLoading, addFavoriteIsLoading])

    // Setting up rellax
    useEffect(() => {
        const parallax = new Rellax(headerRef.current, {
            speed: -6,
        });
        return () => parallax.destroy();
    }, []);

    // Detect isMobile (if screen width lower than 768px)
    useEffect(() => {
        if (!screenSizeType) return;
        if (screenSizeValue < breakpoints.values.lg) setIsMobile(true);
        else if (isMobile) setIsMobile(false);
    }, [screenSizeType])

    // Add favorite to profile
    useEffect(() => {
        if (profileId && getUser()?.activated) addFavoriteSendRequest({method: 'patch', data: myProfile});
    }, [profileId])

    // On add favorite response
    useEffect( () => {
        if (addFavoriteResponse) {
            const fetchData = async () => {
                await setUser({...getUser(), profile: addFavoriteResponse.data});
            }
            fetchData().then(() => {
                navigate(links.myProfile, {
                    state:
                        {
                            path: links.communication,
                            myProfile: addFavoriteResponse.data,
                            profileId: profileId
                        }
                });
            });
        }
    }, [addFavoriteResponse])

    return (
        <div className='main'>
            <ToastContainer/>
            <MKBox
                position="absolute"
                ref={headerRef}
                minHeight="100vh"
                width="100%"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.3),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
            </MKBox>
            <Container>
                <MKBox display="flex" flexDirection={isMobile ? 'column' : 'row'} sx={{mt: 14}}>
                    {!selectedProfile
                        ? <MKBox margin={1} minWidth={{xs: 'auto', lg: '260px'}} maxWidth={{xs: 'auto', lg: '260px'}}>
                            <Card
                                sx={{
                                    p: 2,
                                    mx: {xs: 0, lg: 0},
                                    mt: -2,
                                    mb: 2,
                                    boxShadow: ({boxShadows: {xxl}}) => xxl,
                                }}
                            >
                                {
                                    isFiltered
                                        ? <MKTypography
                                            variant="h5"
                                            color="warning"
                                            fontWeight="bold"
                                            sx={{
                                                textAlign: 'center',
                                                mb: 2,
                                                position: isMobile ? 'absolute' : 'static',
                                                left: '50%',
                                                top: '50%',
                                                transform: isMobile ? 'translate(-50%, -50%)' : 'none'
                                            }}
                                        >
                                            Filters
                                        </MKTypography>
                                        : <MKButton
                                            color="info"
                                            size="small"
                                            className="filter-button"
                                            onClick={getProfilesByFilter}
                                            sx={{
                                                mb: 2,
                                                position: isMobile ? 'absolute' : 'static',
                                                left: '50%',
                                                top: '50%',
                                                transform: isMobile ? 'translate(-50%, -50%)' : 'none'
                                            }}
                                        >
                                            Apply Filters
                                        </MKButton>
                                }
                                {myProfile ?
                                    <SearchFilterBar
                                        isMobile={isMobile}
                                        filters={filters}
                                        setFilters={setFilters}
                                        sort={sort}
                                        onSort={v => setSort(v)}
                                        onlineFirstToggle={[onlineFirst, setOnlineFirst]}
                                        myLikesToggle={[likedFirst, setLikedFirst]}
                                    />
                                    : null
                                }
                            </Card>
                        </MKBox>
                        : ''
                    }

                    <MKBox margin={1} display="flex" flex="1 1 auto">
                        <Card
                            sx={{
                                p: 2,
                                mx: {xs: 0, lg: 0},
                                mt: -2,
                                mb: 2,
                                boxShadow: ({boxShadows: {xxl}}) => xxl,
                                width: '100%'
                            }}
                        >
                            {selectedProfile
                                ? <ViewProfile
                                    active={getUser()?.activated}
                                    profile={selectedProfile}
                                    back={() => setSelectedProfile(null)}
                                    activities={defaultActivities}
                                    onClickingOnActivity={(id, link) => doActivity(id, link)}
                                />
                                : ''
                            }
                            {!isMobile && !selectedProfile &&
                                <SearchSortBar
                                    isMobile={isMobile}
                                    sort={sort}
                                    onSort={v => setSort(v)}
                                    onlineFirstToggle={[onlineFirst, setOnlineFirst]}
                                    myLikesToggle={[likedFirst, setLikedFirst]}
                                />
                            }
                            {!selectedProfile &&
                                <>
                                    {tags && tags.length ?
                                        <MKBox sx={{mb: 2}} display="flex" alignItems="baseline">
                                            <MKBox>
                                                {
                                                    tags.map((tag, index) =>
                                                        <MKBadge key={'tag' + index}
                                                                 badgeContent={tag + ' x'}
                                                                 variant="contained"
                                                                 color="success"
                                                                 container
                                                                 circular
                                                                 style={{cursor: 'pointer', marginRight: '5px'}}
                                                                 onClick={() => handleRemoveTag(tag)}
                                                        />
                                                    )
                                                }
                                            </MKBox>
                                            <MKButton
                                                sx={{ml: 'auto'}}
                                                color="error"
                                                size="small"
                                                circular
                                                onClick={clearAllFiltersAndSort}
                                            >
                                                <FontAwesomeIcon icon={faFilter} style={{marginRight: '10px'}}/>
                                                clear
                                            </MKButton>
                                        </MKBox>
                                        : null}

                                    {!getUser()?.activated ?
                                        <MKBox mb={1} textAlign="center">
                                            <MKTypography variant="button" color="error" sx={{m: 'auto'}}>
                                                {messages.notActivateAccount}
                                            </MKTypography>
                                        </MKBox>
                                        : null
                                    }
                                    <ProfileList
                                        active={getUser()?.activated}
                                        profiles={sortProfiles(profiles, onlineFirst, likedFirst)}
                                        likes={myProfile && myProfile.likes ? myProfile.likes : []}
                                        onLike={handleLike}
                                        onAddFavorite={profile => setProfileId(profile._id)}
                                        onViewProfile={profile => setSelectedProfile(profile)}
                                    />
                                    {pagination.pages !== 0 && pagination.pages !== pagination.currentPage &&
                                        pagination.total > 0 ?
                                        <MKButton variant="gradient" color="info" sx={{mt: 2, w: 'auto'}}
                                                  onClick={getMoreProfiles}>
                                            <FontAwesomeIcon icon={faSync}/>&nbsp; More
                                        </MKButton>
                                        : ''
                                    }
                                </>
                            }
                        </Card>
                    </MKBox>
                </MKBox>
            </Container>
        </div>
    )
}

export default SearchPage;