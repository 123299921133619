import React, {useContext, useEffect, useState} from 'react';
import MKButton from "../../basic/MKButton";
import {
    Autocomplete, Checkbox,
    Chip,
    Grid,
    Slider,
    SwipeableDrawer
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSortAmountDown} from "@fortawesome/free-solid-svg-icons";
import MKBox from "../../basic/MKBox";
import MKTypography from "../../basic/MKTypography";
import MKInput from "../../basic/MKInput";
import Box from "@mui/material/Box";
import {ageLimits, heightLimits, weightLimits} from "../../../utils/constants";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SearchSortBar from "./SearchSortBar";
import useServer from "../../../hooks/useServer";
import {GET_COMMON_DATA} from "../../../serverUrls";
import {toast, ToastContainer} from "react-toastify";
import {toastConfig} from "../../../utils/utils";
import {SpinnerContext} from "../../../contexts/spinner/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const SearchFilterBar = ({isMobile, filters, setFilters, sort, onSort, onlineFirstToggle, myLikesToggle}) => {
    const [commonData, setCommonData] = useState();
    const [response, error, isLoading, sendRequest, setError] = useServer(GET_COMMON_DATA);
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    const [showSortDrawer, setShowSortDrawer] = useState(false);


    const handleAutocompleteFilters = (e, value, name) => {
        e.preventDefault();
        const newFilters = {...filters};
        newFilters[name] = value;
        setFilters(newFilters);
    }

    const handleInputFilters = (e, option) => {
        e.preventDefault();
        let value = e.target.value;
        let newFilters = {...filters};
        if (option) {
            if (option === 'ageRangeStart') {
                newFilters.ageRange[0] = value ? +value : '';
                setFilters(newFilters);
            }
            if (option === 'ageRangeEnd') {
                newFilters.ageRange[1] = value ? +value : '';
                setFilters(newFilters);
            }

            if (option === 'heightRangeStart') {
                newFilters.heightRange[0] = value ? +value : '';
                setFilters(newFilters);
            }
            if (option === 'heightRangeEnd') {
                newFilters.heightRange[1] = value ? +value : '';
                setFilters(newFilters);
            }

            if (option === 'weightRangeStart') {
                newFilters.weightRange[0] = value ? +value : '';
                setFilters(newFilters);
            }
            if (option === 'weightRangeEnd') {
                newFilters.weightRange[1] = value ? +value : '';
                setFilters(newFilters);
            }
        } else {
            newFilters[e.target.name] = value;
            setFilters(newFilters);
        }
    }

    // Download common data on load page
    useEffect(() => {
        sendRequest({method: 'get', data: null});
    }, [])

    // On response
    useEffect(() => {
        if (!response) return;
        setCommonData(response.data);
    }, [response])

    // On loading
    useEffect(() => {
        if (isLoading) setShowSpinner(true);
        else if (showSpinner === true) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [isLoading])

    // On error
    useEffect(() => {
        if (!error) return;
        toast.error(error.data?.message ?? 'Something went wrong', toastConfig);
        return () => setError(null);
    }, [error])

    const toggleFilterDrawer = e => {
        if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return;
        setShowFilterDrawer(!showFilterDrawer);
    }
    const toggleSortDrawer = e => {
        if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return;
        setShowSortDrawer(!showSortDrawer);
    }

    const fields = (
        <MKBox component="section">
            <ToastContainer/>
            {/*  Age   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Age
                </MKTypography>
                <Grid container spacing={2} alignItems="center" display="flex">
                    <Grid item width="40%">
                        <MKInput
                            type="number"
                            variant="standard"
                            value={filters.ageRange[0]}
                            onChange={e => handleInputFilters(e, 'ageRangeStart')}
                            size="small"
                        />
                    </Grid>
                    <Grid item width="20%">-</Grid>
                    <Grid item width="40%">
                        <MKInput
                            type="number"
                            variant="standard"
                            value={filters.ageRange[1]}
                            onChange={e => handleInputFilters(e, 'ageRangeEnd')}
                            size="small"
                        />
                    </Grid>
                </Grid>
                <Box width="100%">
                    <Slider
                        size="small"
                        min={ageLimits[0]}
                        max={ageLimits[1]}
                        value={[filters.ageRange[0] ?? 0, filters.ageRange[1] ?? 0]}
                        valueLabelDisplay="off"
                        sx={{zIndex: 1}}
                        onChange={(e, v) => handleAutocompleteFilters(e, v, 'ageRange')}
                    />
                </Box>
            </MKBox>
            {/*  Height   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Height <span>, cm</span>
                </MKTypography>
                <Grid container spacing={2} alignItems="center" display="flex">
                    <Grid item width="40%">
                        <MKInput
                            type="number"
                            variant="standard"
                            value={filters.heightRange[0]}
                            onChange={e => handleInputFilters(e, 'heightRangeStart')}
                            size="small"
                        />
                    </Grid>
                    <Grid item width="20%">-</Grid>
                    <Grid item width="40%">
                        <MKInput
                            type="number"
                            variant="standard"
                            value={filters.heightRange[1]}
                            onChange={e => handleInputFilters(e, 'heightRangeEnd')}
                            size="small"
                        />
                    </Grid>
                </Grid>
                <Box width="100%">
                    <Slider
                        size="small"
                        min={heightLimits[0]}
                        max={heightLimits[1]}
                        value={[filters.heightRange[0] ?? 0, filters.heightRange[1] ?? 0]}
                        valueLabelDisplay="off"
                        sx={{zIndex: 1}}
                        onChange={(e, v) => handleAutocompleteFilters(e, v, 'heightRange')}
                    />
                </Box>
            </MKBox>
            {/*  Weight   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Weight. kg
                </MKTypography>
                <Grid container spacing={2} alignItems="center" display="flex">
                    <Grid item width="40%">
                        <MKInput
                            type="number"
                            variant="standard"
                            value={filters.weightRange[0]}
                            onChange={e => handleInputFilters(e, 'weightRangeStart')}
                            size="small"
                        />
                    </Grid>
                    <Grid item width="20%">-</Grid>
                    <Grid item width="40%">
                        <MKInput
                            type="number"
                            variant="standard"
                            value={filters.weightRange[1]}
                            onChange={e => handleInputFilters(e, 'weightRangeEnd')}
                            size="small"
                        />
                    </Grid>
                </Grid>
                <Box width="100%">
                    <Slider
                        size="small"
                        min={weightLimits[0]}
                        max={weightLimits[1]}
                        value={[filters.weightRange[0] ?? 0, filters.weightRange[1] ?? 0]}
                        valueLabelDisplay="off"
                        sx={{zIndex: 1}}
                        onChange={(e, v) => handleAutocompleteFilters(e, v, 'weightRange')}
                    />
                </Box>
            </MKBox>
            {/*   Country   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Country
                </MKTypography>
                <Autocomplete
                    options={commonData?.countries ?? []}
                    id="countries"
                    autoHighlight
                    multiple
                    limitTags={1}
                    value={filters.countries ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'countries')}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <MKBox component="li"
                               sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt={option.name}
                            />
                            {option.name}
                        </MKBox>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   City   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    City
                </MKTypography>
                <MKInput
                    name="city"
                    variant="standard"
                    size="small"
                    autoComplete="new-password"
                    fullWidth
                    value={filters.city ?? ''}
                    onChange={handleInputFilters}
                />
            </MKBox>
            {/*   Gender   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Gender
                </MKTypography>
                <Autocomplete
                    options={commonData?.genders ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.genders ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'genders')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Goal   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Goal
                </MKTypography>
                <Autocomplete
                    options={commonData?.goals ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.goals ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'goals')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Marital Status   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Marital Status
                </MKTypography>
                <Autocomplete
                    options={commonData?.maritalStatuses ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.maritalStatuses ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'maritalStatuses')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Children   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Children
                </MKTypography>
                <Autocomplete
                    options={commonData?.children ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.children ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'children')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Education Level   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Education Level
                </MKTypography>
                <Autocomplete
                    options={commonData?.educations ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.educations ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'educations')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Occupation   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Occupation
                </MKTypography>
                <MKInput
                    name="occupation"
                    variant="standard"
                    size="small"
                    autoComplete="new-password"
                    fullWidth
                    value={filters.occupation ?? ''}
                    onChange={handleInputFilters}
                />
            </MKBox>
            {/*   Hair Color   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Hair Color
                </MKTypography>
                <Autocomplete
                    options={commonData?.hairColors ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.hairColors ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'hairColors')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Eye Color   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Eye Color
                </MKTypography>
                <Autocomplete
                    options={commonData?.eyeColors ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.eyeColors ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'eyeColors')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                                sx={{mr: 1, p: 0}}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Smoking Habits   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Smoking Habits
                </MKTypography>
                <Autocomplete
                    options={commonData?.smokingHabits ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.smokingHabits ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'smokingHabits')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Drinking Habits   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Drinking Habits
                </MKTypography>
                <Autocomplete
                    options={commonData?.drinkingHabits ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.drinkingHabits ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'drinkingHabits')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Religion   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Religion
                </MKTypography>
                <Autocomplete
                    options={commonData?.religions ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.religions ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'religions')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
            {/*   Hobbies   */}
            <MKBox>
                <MKTypography variant="h6" color="text">
                    Hobbies
                </MKTypography>
                <Autocomplete
                    options={commonData?.hobbies ?? []}
                    autoHighlight
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={filters.hobbies ?? []}
                    onChange={(e, v) => handleAutocompleteFilters(e, v, 'hobbies')}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1, p: 0}}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) =>
                        <MKInput
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password"
                            }}
                        />
                    }
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({index})}
                                label={option.name}
                                className="autocomplete-tag"
                            />
                        ));
                    }}
                />
            </MKBox>
        </MKBox>
    )

    if (isMobile) {
        return (
            <>
                <Grid display="flex" justifyContent="space-between">
                    <MKButton onClick={toggleFilterDrawer} variant="outlined" color="info" iconOnly>
                        <FontAwesomeIcon icon={faFilter}/>
                    </MKButton>
                    <MKButton onClick={toggleSortDrawer} variant="outlined" color="info" iconOnly>
                        <FontAwesomeIcon icon={faSortAmountDown}/>
                    </MKButton>
                </Grid>


                <SwipeableDrawer
                    anchor="left"
                    open={showFilterDrawer}
                    onClose={toggleFilterDrawer}
                    onOpen={toggleFilterDrawer}
                >
                    <Grid padding={3}>
                        {fields}
                    </Grid>

                </SwipeableDrawer>

                <SwipeableDrawer
                    anchor="right"
                    open={showSortDrawer}
                    onClose={toggleSortDrawer}
                    onOpen={toggleSortDrawer}
                >
                    <Grid padding={3}>
                        <SearchSortBar
                            isMobile={isMobile}
                            sort={sort}
                            onSort={onSort}
                            onlineFirstToggle={onlineFirstToggle}
                            myLikesToggle={myLikesToggle}
                        />
                    </Grid>

                </SwipeableDrawer>
            </>
        )
    } else return fields;
}

export default SearchFilterBar;