import { faGem, faCoffee } from "@fortawesome/free-solid-svg-icons";
const faSafari = require("@fortawesome/free-brands-svg-icons/faSafari");
export const homeLabel = "Discover Online Dating with Ideal Couple";
export const homeTitle = 'Start your jorney with us';
export const homeDescription =
    "Are you looking for love, romance, or just want to meet or date some fascinating people?<br><br>" +
    "Idealcouple.com has what you're looking for!<br><br>" +
    "It takes only a few minutes to open an amazing world where your dreams may come true. Don't lose your chance!<br><br>" +
    "Use our Free Services: Database Search, Icebreaker, Greetings, Photo Sending services. Let people to know you are interested in them. " +
    "When you are ready to communicate, pick the payment plan that suits you.<br><br> " +
    "Be proactive! Connect to date!";

export const advantages = [
    {
        color: 'warning',
        icon: 'faGem',
        title: 'Unlimited revisions',
        description: 'The time is now to be okay to be the greatest you. Would you believe in what you believe in?',
        direction: 'center'
    },
    {
        color: 'info',
        icon: 'faSafari',
        title: 'Support 24/7',
        description: 'We get insulted by others, lose trust for those others. We get back here to follow my dreams',
        direction: 'center'
    },
    {
        color: 'warning',
        icon: 'faSafari',
        title: 'Unlimited revisions',
        description: 'The time is now to be okay to be the greatest you. Would you believe in what you believe in?',
        direction: 'center'
    },
    {
        color: 'primary',
        icon: 'faCoffee',
        title: 'Check our team',
        description: 'People in this world shun people for being great. For being a bright color. For standing out.',
        direction: 'center'
    },
    {
        color: 'warning',
        icon: 'faGem',
        title: 'Unlimited revisions',
        description: 'The time is now to be okay to be the greatest you. Would you believe in what you believe in?',
        direction: 'center'
    },
    {
        color: 'warning',
        icon: 'faGem',
        title: 'Unlimited revisions',
        description: 'The time is now to be okay to be the greatest you. Would you believe in what you believe in?',
        direction: 'center'
    },

];

export const demoStories = [
    {
        image: 'uploads/testimonials/happy_story_1.jpg',
        title: 'Excellent service. You guys are the best!',
        review: 'Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every other idea alone. This is the way to success.',
        author: 'Author\'s Name 1'
    },
    {
        image: 'uploads/testimonials/happy_story_2.jpg',
        title: 'Excellent service. You guys are the best!',
        review: 'Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every '+
        'other idea alone. This is the way to success.',
        author: 'Author\'s Name 2'
    },
    {
        image: 'uploads/testimonials/happy_story_3.jpg',
        title: 'Excellent service. You guys are the best!',
        review: 'Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every '+
            'other idea alone. This is the way to success.',
        author: 'Author\'s Name 3'
    }
];

export const demoTeam = [
    {
        image: 'uploads/team/team1.jpg',
        name: 'Emma Roberts',
        position: 'UI Designer',
        description: 'Artist is a term applied to a person who engages in an activity deemed to be an art.'
    },
    {
        image: 'uploads/team/team2.jpg',
        name: 'Emma Roberts',
        position: 'UI Designer',
        description: 'Artist is a term applied to a person who engages in an activity deemed to be an art.'
    },
    {
        image: 'uploads/team/team3.jpg',
        name: 'Emma Roberts',
        position: 'UI Designer',
        description: 'Artist is a term applied to a person who engages in an activity deemed to be an art.'
    },
    {
        image: 'uploads/team/team4.jpg',
        name: 'Emma Roberts',
        position: 'UI Designer',
        description: 'Artist is a term applied to a person who engages in an activity deemed to be an art.'
    }
];