import React, {useContext, useEffect, useRef, useState} from "react";
import MKBox from "../../../basic/MKBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faBars,
    faEnvelope,
    faGifts,
    faGrinHearts,
    faHandPeace,
    faHandPointLeft,
    faPaperPlane,
    faPlus,
    faSnowflake
} from "@fortawesome/free-solid-svg-icons";
import MKTypography from "components/basic/MKTypography";
import Chat from "./Chat";
import MKButton from "components/basic/MKButton";
import {
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Modal,
    Radio,
    RadioGroup,
    Slide,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    TextareaAutosize
} from "@mui/material";
import ContactsList from "./ContactsList";
import useServer from "hooks/useServer";
import {
    ACTION,
    GIFT,
    GREETING,
    ICE_BREAKER,
    MESSAGE,
    MESSAGE_ALL,
    PROFILE,
    PROFILE_BLOCK,
    PROFILE_FAVORITE
} from "serverUrls";
import {SpinnerContext} from "contexts/spinner/Spinner";
import {defaultActivities, initialMessagesPagination as initialPagination, links} from "utils/constants";
import moment from "moment";
import {toast} from "react-toastify";
import {toastConfig} from "utils/utils";
import {useLocation, useNavigate} from "react-router-dom";
import routes from "../../../../routesForTopMenu";
import Swal from "sweetalert2";
import {AuthContext} from "../../../../contexts/auth/AuthContext";
import ViewProfile from "../ViewProfile/ViewProfile";
import Card from "@mui/material/Card";
import {NotificationContext} from "../../../../contexts/notifications/NotificationContext";

const Communication = ({myProfileState, screenSizeValue}) => {
    const {getUser, setUser} = useContext(AuthContext);
    const [selectedContact, setSelectedContact] = useState();
    const [viewProfileId, setViewProfileId] = useState();
    const [viewProfile, setViewProfile] = useState();
    const [contacts, setContacts] = useState([]);
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [showCircularProgress, setShowCircularProgress] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [deletedContactId, setDeletedContactId] = useState();

    const [messagesResponse, messagesError, messagesIsLoading, messagesSendRequest, messagesSetError] = useServer(MESSAGE + '/' + selectedContact?._id);
    const [sendMessageResponse, sendMessageError, sendMessageIsLoading, sendMessageSendRequest, sendMessageSetError] = useServer(MESSAGE);
    const [viewProfileResponse, viewProfileError, viewProfileIsLoading, viewProfileSendRequest, viewProfileSetError] = useServer(PROFILE + '/' + viewProfileId);
    const [greetingsResponse, greetingsError, greetingsIsLoading, greetingsSendRequest, greetingsSetError] = useServer(GREETING);
    const [iceBreakersResponse, iceBreakersError, iceBreakersIsLoading, iceBreakersSendRequest, iceBreakersSetError] = useServer(ICE_BREAKER);
    const [giftsResponse, giftsError, giftsIsLoading, giftsSendRequest, giftsSetError] = useServer(GIFT);
    const [actionsResponse, actionsError, actionsIsLoading, actionsSendRequest, actionsSetError] = useServer(ACTION);
    const [deleteContactResponse, deleteContactError, deleteContactIsLoading, deleteContactSendRequest, deleteContactSetError] = useServer(MESSAGE_ALL + '/' + deletedContactId);
    const [blockContactResponse, blockContactError, blockContactIsLoading, blockContactSendRequest, blockContactSetError] = useServer(PROFILE_BLOCK);
    const [removeFavoriteResponse, removeFavoriteError, removeFavoriteIsLoading, removeFavoriteSendRequest, removeFavoriteSetError] = useServer(PROFILE_FAVORITE);
    const [pagination, setPagination] = useState();
    const [more, setMore] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [greetings, setGreetings] = useState(null);
    const [iceBreakers, setIceBreakers] = useState(null);
    const [actions, setActions] = useState(null);
    const [gifts, setGifts] = useState(null);
    const [actionId, setActionId] = useState('');
    const [giftId, setGiftId] = useState('');
    const [greetingId, setGreetingId] = useState('');
    const [iceBreakerId, setIceBreakerId] = useState('');
    const [openContactList, setOpenContactList] = useState(false);
    const navigate = useNavigate();
    const {state} = useLocation();
    const toggleRef = useRef(null);
    const {notification, clearMessagesBySender} = useContext(NotificationContext);

    // Send Message
    const sendMessage = e => {
        e.preventDefault();
        if (selectedContact &&
            (message.trim().length > 0 ||
                actionId.length > 0 ||
                giftId.length > 0 ||
                greetingId.length > 0 ||
                iceBreakerId.length > 0
            )) {

            const data = {};
            data.recipient = selectedContact;
            data.letter = message;
            if (actionId.length > 0 && actions) data.action = actions.find(action => action._id === actionId);
            if (giftId.length > 0 && gifts) data.gift = gifts.find(gift => gift._id === giftId);
            if (greetingId.length > 0 && greetings) data.greeting = greetings.find(greeting => greeting._id === greetingId);
            if (iceBreakerId.length > 0 && iceBreakers) data.iceBreaker = iceBreakers.find(iceBreaker => iceBreaker._id === iceBreakerId);

            sendMessageSendRequest({method: 'post', data});
            closeModal();
        }
    }

    // Load More Messages
    const loadMoreMessages = () => {
        if (pagination.currentPage < pagination.pages) {
            const newPaginationRequest = getPaginationForPreviousPage(pagination);
            setMore(true);
            messagesSendRequest(
                {
                    method: 'get',
                    params: Object.assign(newPaginationRequest)
                });
        }
    }

    // Generate pagination for previous page of messages
    const getPaginationForPreviousPage = inputPagination => {
        const {total, onPage, pages, currentPage} = inputPagination;
        let offset = initialPagination.offset;
        let limit = initialPagination.limit;
        if (currentPage < pages) {
            offset = currentPage * onPage;
        }
        return {offset, limit};
    }

    // On View Mosal With Activities
    const viewModalWithActivities = activity => {
        switch (activity) {
            case 'greeting':
                greetingsSendRequest({method: 'get'});
                break;
            case 'ice-breaker':
                iceBreakersSendRequest({method: 'get'});
                break;
            case 'gift':
                giftsSendRequest({method: 'get'});
                break;
            default:
                actionsSendRequest({method: 'get'});
                break;
        }
    }

    // On Modal Close
    const closeModal = () => {
        if (greetings) setGreetings(null);
        if (iceBreakers) setIceBreakers(null);
        if (gifts) setGifts(null);
        if (actions) setActions(null);
        if (message.length > 0) setMessage('');
        if (actionId.length > 0) setActionId('');
        if (giftId.length > 0) setGiftId('');
        if (greetingId.length > 0) setGreetingId('');
        if (iceBreakerId.length > 0) setIceBreakerId('');
    }

    // Convert Favorites To Contacts
    const convertFavoritesToContacts = profile => {
        if (!profile) return [];
        let favorites = profile.favorites;
        const blocked = profile.blocked;
        if (favorites && favorites.length > 0) {
            if (blocked && blocked.length > 0) {
                favorites = favorites.filter(favorite => {
                    let isBlocked = false;
                    blocked.forEach(block => {
                        if (block.profile?._id === favorite.profile?._id) {
                            isBlocked = true;
                        }
                    })
                    return !isBlocked ? favorite : null;
                })
            }
            const sortedFavorites = favorites.sort((a, b) => new Date(b.lastActivity) - new Date(a.lastActivity));

            return sortedFavorites.map(favorite => {
                if (favorite.profile) {
                    if (notification?.messages?.length) {
                        favorite.profile['newMessages'] = notification?.messages.filter(message => message.sender === favorite.profile._id);
                    } else favorite.profile['newMessages'] = null;
                    return favorite.profile;
                } else return favorite._id;
            });
        } else return [];
    }

    // On delete contact click
    const onDeleteContact = selectedContact => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Please, confirm your choice!',
            text: "You really won't to delete all history with this user?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                if (typeof selectedContact === "object") setDeletedContactId(selectedContact?._id);
                else if (typeof selectedContact === "string") {
                    removeFavoriteSendRequest({method: 'delete', data: {id: selectedContact}});
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'You canceled operation.',
                    'error'
                )
            }
        })
    }

    // On Block Contact
    const onBlockContact = selectedContact => {
        if (selectedContact) {
            blockContactSendRequest({method: 'post', data: {profileId: selectedContact?._id}});
        }
    }

    // On View Profile
    const onViewContact = contact => setViewProfileId(contact?._id);

    // On block contact response
    useEffect(() => {
        if (blockContactResponse) {
            setUser({...getUser(), profile: blockContactResponse.data});
            setSelectedContact(null);
            myProfileState.setMyProfile(blockContactResponse.data);
        }
    }, [blockContactResponse])

    // On remove favorite response
    useEffect(() => {
        if (removeFavoriteResponse) {
            setUser({...getUser(), profile: removeFavoriteResponse.data});
            setSelectedContact(null);
            myProfileState.setMyProfile(removeFavoriteResponse.data);
        }
    }, [removeFavoriteResponse])

    // Open Contact List
    useEffect(() => {
        toggleRef.current?.focus();
    }, [openContactList])

    // Get profile and his contacts
    useEffect(() => {
            if (myProfileState.myProfile) {
                const profiles = convertFavoritesToContacts(myProfileState.myProfile);
                setContacts(profiles);
                if (!selectedContact) setSelectedContact(profiles[0]);
            }
        }, [myProfileState.myProfile, notification]
    )

    // On selected contact
    useEffect(() => {
        if (selectedContact && typeof selectedContact === 'object') {
            setMore(false);
            if (selectedContact.newMessages) {
                clearMessagesBySender(selectedContact._id);
            }
            messagesSendRequest({
                method: 'get',
                params: Object.assign(initialPagination)
            });
        } else {
            setMessages([]);
        }
    }, [selectedContact])

    // On get messages Response
    useEffect(() => {
        if (messagesResponse) {
            let newMessages = [];
            if (more) newMessages = messagesResponse.data.items.reverse().concat(messages);
            else newMessages = messagesResponse.data.items.reverse();
            setPagination(messagesResponse.data.pagination);
            setMessages(newMessages);
            setShowMoreButton(messagesResponse?.data?.pagination?.currentPage < messagesResponse?.data?.pagination?.pages);
        }
    }, [messagesResponse])

    // On sendMessage Response
    useEffect(() => {
        if (sendMessageResponse) {
            const newMessages = [...messages];
            newMessages.push(sendMessageResponse.data.message);
            setMessages(newMessages);
            myProfileState.setMyProfile(sendMessageResponse.data.profile);
        }
    }, [sendMessageResponse])

    // On get greetings Response
    useEffect(() => {
        if (greetingsResponse) setGreetings(greetingsResponse.data);
    }, [greetingsResponse])

    // On get ice-breakers Response
    useEffect(() => {
        if (iceBreakersResponse) setIceBreakers(iceBreakersResponse.data);
    }, [iceBreakersResponse])

    // On get gifts Response
    useEffect(() => {
        if (giftsResponse) setGifts(giftsResponse.data);
    }, [giftsResponse])

    // On get action Response
    useEffect(() => {
        if (actionsResponse) setActions(actionsResponse.data);
    }, [actionsResponse])

    // On Loading
    useEffect(() => {
        if (greetingsIsLoading || iceBreakersIsLoading || giftsIsLoading || actionsIsLoading ||
            deleteContactIsLoading || blockContactIsLoading || viewProfileIsLoading || removeFavoriteIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [greetingsIsLoading, iceBreakersIsLoading, giftsIsLoading, actionsIsLoading,
        deleteContactIsLoading, blockContactIsLoading, viewProfileIsLoading, removeFavoriteIsLoading])

    // On messages Loading
    useEffect(() => {
        if (messagesIsLoading) setShowCircularProgress(true);
        else if (showCircularProgress) setShowCircularProgress(false);

        return () => setShowCircularProgress(false);
    }, [messagesIsLoading])

    // On Errors
    useEffect(() => {
        if (messagesError) {
            toast.error(messagesError.data?.message ?? 'Messages NOT loaded', toastConfig);
            messagesSetError(null);
        }
        if (sendMessageError) {
            toast.error(sendMessageError.data?.message ?? 'Message NOT sent', toastConfig);
            sendMessageSetError(null);
        }
        if (greetingsError) {
            toast.error(greetingsError.data?.message ?? 'Greetings NOT loaded', toastConfig);
            greetingsSetError(null);
        }
        if (iceBreakersError) {
            toast.error(iceBreakersError.data?.message ?? 'Ice-breakers NOT loaded', toastConfig);
            iceBreakersSetError(null);
        }
        if (giftsError) {
            toast.error(giftsError.data?.message ?? 'Gifts NOT loaded', toastConfig);
            giftsSetError(null);
        }
        if (actionsError) {
            toast.error(actionsError.data?.message ?? 'Actions NOT loaded', toastConfig);
            actionsSetError(null);
        }
        if (deleteContactError) {
            toast.error(deleteContactError.data?.message ?? 'Contact NOT deleted', toastConfig);
            deleteContactSetError(null);
        }
        if (blockContactError) {
            toast.error(blockContactError.data?.message ?? 'Contact NOT blocked', toastConfig);
            blockContactSetError(null);
        }
        if (viewProfileError) {
            toast.error(viewProfileError.data?.message ?? 'Profile NOT loaded', toastConfig);
            viewProfileSetError(null);
        }
        if (removeFavoriteError) {
            toast.error(removeFavoriteError.data?.message ?? 'Contact NOT removed', toastConfig);
            removeFavoriteSetError(null);
        }
        return () => {
            messagesSetError(null);
            sendMessageSetError(null);
            greetingsSetError(null);
            iceBreakersSetError(null);
            giftsSetError(null);
            actionsSetError(null);
            deleteContactSetError(null);
            blockContactSetError(null);
            viewProfileSetError(null);
            removeFavoriteSetError(null);
        }
    }, [messagesError, sendMessageError, greetingsError, iceBreakersError, giftsError,
        actionsError, deleteContactError, blockContactError, viewProfileError, removeFavoriteError])

    // Show Modal
    useEffect(() => {
        if (greetings || iceBreakers || gifts || actions) {
            if (greetings) setModalTitle('Send Greeting');
            if (iceBreakers) setModalTitle('Send Ice Breaker');
            if (gifts) setModalTitle('Send Gift');
            if (actions) setModalTitle('Send Action');
            setShowModal(true)
        } else if (showModal) setShowModal(false);
        return () => setShowModal(false);
    }, [greetings, iceBreakers, gifts, actions])

    // Send delete contact request
    useEffect(() => {
        if (deletedContactId) deleteContactSendRequest({method: 'delete'});
    }, [deletedContactId])

    // On Delete Contact Response
    useEffect(() => {
        if (deleteContactResponse) {
            setUser({...getUser(), profile: deleteContactResponse.data});
            setSelectedContact(null);
            myProfileState.setMyProfile(deleteContactResponse.data);

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire(
                'Deleted!',
                'All messages have been deleted.',
                'success'
            )
        }
    }, [deleteContactResponse])

    // If state exists
    useEffect(() => {
        if (state) {
                if (state.selectedProfileId) {
                    let profiles = convertFavoritesToContacts(myProfileState.myProfile);
                    let foundContact = profiles.find(profile => profile._id === state.selectedProfileId);
                    setSelectedContact(foundContact);
                }
        }
    }, [state])

    // On View Profile Send request to get profile by id
    useEffect(() => {
        if (viewProfileId) {
            viewProfileSendRequest({method: 'get'})
        }
    }, [viewProfileId])

    // On View Profile Response
    useEffect(() => {
        if (viewProfileResponse) {
            setViewProfile(viewProfileResponse.data);
        }
    }, [viewProfileResponse])

    const doActivity = (id, link) => {
        if (link === links.myProfile_communication) {
            setViewProfileId(null);
            setViewProfile(null);
        } else if (link === links.myProfile_letsMatch) {
            navigate(link, {state: {profiles: [viewProfile]}});
        } else navigate(link);
    }

    if (!myProfileState.myProfile) return null;
    else if (viewProfile) return <ViewProfile
        active={getUser()?.activated}
        profile={viewProfile}
        back={() => {
            setViewProfile(null);
            setViewProfileId(null)
        }}
        activities={defaultActivities}
        onClickingOnActivity={(id, link) => doActivity(id, link)}
    />;
    else return (
            <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
                <MKBox height="100%" display="flex" flexDirection="column">
                    <MKBox display="flex">
                        <MKBox width="65px" bgColor="light" display="flex" borderRadius="0.75rem 0 0 0">
                            <MKButton
                                ref={toggleRef}
                                onClick={() => setOpenContactList(!openContactList)}
                                color="info"
                                variant="contained"
                                circular
                                iconOnly
                                sx={{m: 'auto'}}
                            >
                                <FontAwesomeIcon icon={openContactList ? faAngleLeft : faAngleRight}/>
                            </MKButton>
                        </MKBox>

                        <MKBox
                            display="flex"
                            flexDirection="column"
                            flex="1 1 auto"
                            justifyContent="center"
                            alignItems="center"
                            bgColor="light"
                            padding="5px 0"
                            borderRadius="0 0.75rem 0 0"
                        >

                            <MKTypography variant="body2"
                                          fontWeight="bold">{selectedContact?.firstname ?? ''}</MKTypography>
                            <MKTypography variant="button" color="text">
                                {selectedContact ?
                                    (selectedContact?.user?.userLastAction?.actionAt
                                        ? (new Date() - new Date(selectedContact.user.userLastAction.actionAt) > (10 * 60 * 1000) // 10 minutes
                                                ? moment(selectedContact.user.userLastAction.actionAt).calendar()
                                                : 'online'
                                        )
                                        : 'Last visit: some time ago')
                                    : ''
                                }
                            </MKTypography>
                            <SpeedDial
                                ariaLabel="Menu"
                                direction="left"
                                sx={{position: 'absolute', top: 16, right: 24}}
                                icon={
                                    <SpeedDialIcon
                                        icon={<FontAwesomeIcon icon={faBars}/>}
                                        openIcon={<FontAwesomeIcon icon={faHandPointLeft} size="lg"
                                                                   style={{padding: 0, margin: 0}}/>}

                                    />}
                            >
                                {(routes.reverse()).map((route) => (
                                    (route.name !== 'Login' && route.name !== 'Sign Up' && route.name !== 'My Profile')
                                        ?
                                        <SpeedDialAction
                                            key={route.name}
                                            icon={route.icon}
                                            tooltipTitle={route.name}
                                            onClick={() => {
                                                navigate(route.href ?? '/')
                                            }}
                                        />
                                        : null
                                ))}
                            </SpeedDial>
                        </MKBox>
                    </MKBox>
                    <MKBox display="flex" sx={{overflow: "hidden"}} flex="1 1 auto">
                        <MKBox display="flex" flexDirection="column" bgColor="light">
                            <MKBox sx={{position: 'relative', overflowY: 'auto'}}>
                                <ContactsList
                                    contacts={contacts}
                                    selectedContact={selectedContact}
                                    selectContact={i => setSelectedContact(contacts[i])}
                                    toggle={[openContactList, setOpenContactList]}
                                    screenSizeValue={screenSizeValue}
                                    deleteContact={onDeleteContact}
                                    blockContact={onBlockContact}
                                    viewContact={onViewContact}
                                />
                            </MKBox>
                        </MKBox>
                        <MKBox display="flex" flexDirection="column"
                               sx={{height: '100%', width: '100%', overflowY: 'auto', overflowX: 'hidden'}}>
                            <MKBox display="flex" flexDirection="column" flexGrow="1"
                            >
                                <MKBox flexGrow="1"
                                       sx={{px: {xs: 0, md: 1, lg: 2}, overflowY: "hidden", overflowX: 'hidden'}}>
                                    {showCircularProgress &&
                                        <CircularProgress sx={{ml: 'calc(50% - 20px)'}}/>
                                    }

                                    {
                                        selectedContact
                                            ? <Chat
                                                messages={messages}
                                                avatar={selectedContact?.avatar}
                                                profileId={myProfileState.myProfile?._id}
                                                loadMoreMessages={loadMoreMessages}
                                                showMoreButton={showMoreButton}
                                            />
                                            : null
                                    }

                                </MKBox>
                            </MKBox>
                        </MKBox>
                    </MKBox>

                    <MKBox bgColor="light" borderRadius="0 0 0.75rem 0.75rem">
                        <MKBox sx={{px: 1, pt: 1}}>
                            <MKButton color="info" sx={{mr: 2, my: 1}} iconOnly circular
                                      onClick={() => navigate(links.myProfile_cart)}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </MKButton>
                            <MKButton size="small" color="info" sx={{mr: 1}}
                                      onClick={() => viewModalWithActivities('ice-breaker')}
                                      disabled={myProfileState.myProfile?.amountOfIceBreakers === 0 || !selectedContact}>
                                <FontAwesomeIcon icon={faSnowflake} style={{marginRight: '.5em', color: 'yellow'}}/>
                                ice-breakers
                                ({myProfileState.myProfile?.isPremium ? '∞' : (myProfileState.myProfile?.amountOfIceBreakers ?? '∞')})
                            </MKButton>

                            <MKButton size="small" color="info" sx={{mr: 1}}
                                      onClick={() => viewModalWithActivities('greeting')}
                                      disabled={myProfileState.myProfile?.amountOfGreetings === 0 || !selectedContact}>
                                <FontAwesomeIcon icon={faHandPeace} style={{marginRight: '.5em', color: 'yellow'}}/>
                                greetings
                                ({myProfileState.myProfile?.isPremium ? '∞' : (myProfileState.myProfile?.amountOfGreetings ?? '∞')})
                            </MKButton>

                            <MKButton size="small" color="info" sx={{mr: 1}}
                                      onClick={() => viewModalWithActivities('gift')}
                                      disabled={(!myProfileState.myProfile?.isPremium && myProfileState.myProfile?.amountOfGifts === 0) || !selectedContact}>
                                <FontAwesomeIcon icon={faGifts} style={{marginRight: '.5em', color: 'yellow'}}/>
                                gifts
                                ({myProfileState.myProfile?.isPremium ? '∞' : (myProfileState.myProfile?.amountOfGifts ?? '∞')})
                            </MKButton>

                            <MKButton size="small" color="info" sx={{mr: 1}}
                                      onClick={() => viewModalWithActivities('action')}
                                      disabled={myProfileState.myProfile?.amountOfActions === 0 || !selectedContact}>
                                <FontAwesomeIcon icon={faGrinHearts}
                                                 style={{marginRight: '.5em', color: 'yellow'}}/>
                                actions ({myProfileState.myProfile?.amountOfActions})
                            </MKButton>
                        </MKBox>
                        <MKBox component="form" display="flex" alignItems="end" sx={{p: 1, borderRadius: '4px'}}>
                            <MKBox
                                display="flex"
                                flexWrap="nowrap"
                                alignItems="center"
                                variant="contained"
                                bgColor="info"
                                color="white"
                                borderRadius="lg"
                                marginRight=".4em"
                                shadow="lg"
                                p='.55em'
                                fontSize='.8em'
                            >
                                <FontAwesomeIcon icon={faEnvelope} style={{color: 'yellow'}}/>
                                <MKTypography variant="button" fontWeight="bold" color="white"
                                              sx={{ml: 1}}>{myProfileState.myProfile?.isPremium ? '∞' : myProfileState.myProfile?.amountOfLetters}</MKTypography>
                            </MKBox>
                            <TextareaAutosize
                                style={{
                                    width: '100%',
                                    fontSize: '.8em',
                                    color: 'white',
                                    background: 'rgb(63, 81, 181)',
                                    borderRadius: '0.5em',
                                    padding: '0.4em'
                                }}
                                disabled={(!myProfileState.myProfile?.isPremium && myProfileState.myProfile?.amountOfLetters === 0) || !selectedContact}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                            />
                            <MKButton color="info" size="medium" variant="contained" sx={{ml: 1}}
                                      onClick={sendMessage}
                                      disabled={sendMessageIsLoading || !selectedContact}
                            >
                                {sendMessageIsLoading
                                    ? <MKBox><CircularProgress size={16} color="light" sx={{mr: 1}}/></MKBox>
                                    : <FontAwesomeIcon icon={faPaperPlane} style={{marginRight: '.7rem'}}/>
                                } send
                            </MKButton>
                        </MKBox>
                    </MKBox>

                    <Modal open={showModal} onClose={closeModal}
                           sx={{display: "grid", placeItems: "center", overflow: "auto"}}>
                        <Slide direction="down" in={showModal} timeout={500}>
                            <MKBox
                                position="relative"
                                width="500px"
                                display="flex"
                                flexDirection="column"
                                borderRadius="xl"
                                bgColor="white"
                                shadow="xl"
                            >
                                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                                    <MKTypography variant="h5">{modalTitle}</MKTypography>
                                    {/* <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} /> */}
                                </MKBox>
                                <Divider sx={{my: 0}}/>
                                <MKBox p={2}>

                                    {greetings ?
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={greetingId}
                                                onChange={(e, v) => setGreetingId(v)}
                                            >
                                                {greetings.map((greeting, i) => (
                                                    <FormControlLabel
                                                        key={'greeting-' + i}
                                                        value={greeting._id}
                                                        control={<Radio/>}
                                                        label={greeting.name}/>
                                                ))}

                                            </RadioGroup>
                                        </FormControl>
                                        : ''
                                    }

                                    {iceBreakers ?
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={iceBreakerId}
                                                onChange={(e, v) => setIceBreakerId(v)}
                                            >
                                                {iceBreakers.map((iceBreaker, i) => (
                                                    <FormControlLabel
                                                        key={'iceBreaker-' + i}
                                                        value={iceBreaker._id}
                                                        control={<Radio/>}
                                                        label={iceBreaker.name}/>
                                                ))}

                                            </RadioGroup>
                                        </FormControl>
                                        : ''
                                    }

                                    {gifts ?
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                value={giftId}
                                                onChange={(e, v) => setGiftId(v)}
                                            >
                                                {gifts.map((gift, i) => (
                                                    <FormControlLabel key={'gift-' + i}
                                                                      value={gift._id}
                                                                      control={<Radio sx={{display: 'none'}}/>}
                                                                      className="gifts-form-label"
                                                                      label={
                                                                          <MKBox component="img"
                                                                                 width="58px" height="58px"
                                                                                 margin="5px"
                                                                                 padding="8px"
                                                                                 src={process.env.REACT_APP_API_URL + '/' + gift.img}
                                                                                 alt="gift photo"
                                                                                 className={gift._id === giftId ? 'gift-radio-img-checked' : 'gift-radio-img'}
                                                                          />
                                                                      }
                                                    />
                                                ))}

                                            </RadioGroup>
                                        </FormControl>
                                        : ''
                                    }

                                    {actions ?
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                value={actionId}
                                                onChange={(e, v) => setActionId(v)}
                                            >
                                                {actions.map((action, i) => (
                                                    <FormControlLabel key={'action-' + i}
                                                                      value={action._id}
                                                                      control={<Radio sx={{display: 'none'}}/>}
                                                                      label={
                                                                          <MKBox component="img"
                                                                                 width="58px" height="58px"
                                                                                 margin="5px"
                                                                                 padding="8px"
                                                                                 src={process.env.REACT_APP_API_URL + '/' + action.img}
                                                                                 alt="action photo"
                                                                                 className={action._id === actionId ? 'gift-radio-img-checked' : 'gift-radio-img'}
                                                                          />
                                                                      }
                                                    />
                                                ))}

                                            </RadioGroup>
                                        </FormControl>
                                        : ''
                                    }


                                </MKBox>
                                <Divider sx={{my: 0}}/>
                                <MKBox display="flex" justifyContent="space-around" p={1.5}>
                                    <MKButton variant="outlined" color="error" size="small" onClick={closeModal}>
                                        cancel
                                    </MKButton>
                                    <MKButton variant="contained" size="small" color="info" onClick={sendMessage}
                                              disabled={
                                                  message.length === 0 &&
                                                  giftId.length === 0 &&
                                                  greetingId.length === 0 &&
                                                  iceBreakerId.length === 0 &&
                                                  actionId.length === 0
                                              }>
                                        send
                                    </MKButton>
                                </MKBox>
                            </MKBox>
                        </Slide>
                    </Modal>
                </MKBox>
            </Card>
        )
}
export default Communication;