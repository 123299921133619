import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutUsPage';
import SearchPage from './pages/SearchPage';
import AuthPage from './pages/AuthPage';
import MyProfilePage from './pages/MyProfilePage';
import Policy from "./pages/Policy";
import SignOut from "./pages/SignOut";
import {AuthContext} from "./contexts/auth/AuthContext";
import Header from "./components/blocks/Header/Header";
import Footer from "./components/blocks/Footer/Footer";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import ProfilePage from "./pages/ProfilePage";
import {links} from "./utils/constants";

const MainRoutes = () => {
    const {autoLogin, isAuthenticated} = useContext(AuthContext);
    const [rendered, setRendered] = useState(false);
    useEffect(() => {
        autoLogin();
        setRendered(true);
    }, []);

    if (!rendered) return null;
    else return (
        <Fragment>
            <Header/>
            <Routes>
                <Route path={links.main} element={<HomePage/>}/>
                <Route path={links.about} element={<AboutPage/>}/>
                <Route path={links.policy} element={<Policy/>}/>
                <Route path={links.search} element={isAuthenticated ? <SearchPage/> : <Navigate replace to={links.main} />}/>
                <Route path={links.myProfile + '/*'} element={isAuthenticated ? <MyProfilePage/> : <Navigate replace to={links.main} />}/>
                <Route path={links.profile + '/:id'} element={isAuthenticated ? <ProfilePage /> : <Navigate replace to={links.main} />}/>
                <Route path={links.subscriptions} element={isAuthenticated ? <SubscriptionsPage/> : <Navigate replace to={links.main} />}/>
                <Route path={links.login} element={isAuthenticated ? <Navigate replace to={links.main}/> : <AuthPage/>}/>
                <Route path={links.register} element={isAuthenticated ? <Navigate replace to={links.main} /> : <AuthPage/>}/>
                <Route path={links.reset} element={isAuthenticated ? <Navigate replace to={links.main}/> : <AuthPage/>}/>
                <Route path={links.signOut} element={isAuthenticated ? <SignOut/> : <Navigate replace to={links.main} />}/>
                <Route path={links.main + '*'} element={<HomePage/>}/>
            </Routes>
            <Footer/>
        </Fragment>

    );
}

export default MainRoutes;