import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Container from "@mui/material/Container";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import ComplexReviewCard from "components/cards/ComplexReviewCard";
import React, {useRef} from "react";
import SwiperCore, {Autoplay, Navigation} from "swiper";
import 'swiper/css';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FadeLoader} from "react-spinners";

function HappyStories({stories}) {
    // install SwiperJS modules
    SwiperCore.use([Autoplay, Navigation]);

    // Swiper navigation buttons styles
    const navigationStyles = {
        position: "absolute",
        top: 0,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "15%",
        height: "100%",
        textAlign: "center",
        opacity: 0.5,
        cursor: "pointer",
        transition: "opacity 0.15s ease",

        "&:hover, &:focus": {
            opacity: 1,
        },
    };

    // SwiperJS navigation buttons ref
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    return (
        <MKBox py={4} position="relative" display="flex">
            {stories?.length > 0 ?
                <Swiper
                    onInit={({params, navigation}) => {
                        const {navigation: nav} = params;

                        nav.prevEl = navigationPrevRef.current;
                        nav.nextEl = navigationNextRef.current;
                        navigation.init();
                        navigation.update();
                    }}
                    autoplay={{delay: 3500}}
                    speed={1800}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop
                >
                    {stories.map((story, index) => (
                        <SwiperSlide key={'story-' + index}>
                            <Container>
                                <ComplexReviewCard
                                    image={story.img ? process.env.REACT_APP_API_URL + '/' + story.img : ''}
                                    title={story.title}
                                    review={story.review}
                                    author={story.author}
                                />
                            </Container>
                        </SwiperSlide>
                    ))}

                    <MKTypography
                        variant="h2"
                        color="dark"
                        sx={{
                            ...navigationStyles,
                            left: 0,
                        }}
                        ref={navigationPrevRef}
                    >
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </MKTypography>
                    <MKTypography
                        variant="h2"
                        color="dark"
                        sx={{
                            ...navigationStyles,
                            right: 0,
                        }}
                        ref={navigationNextRef}
                    >
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </MKTypography>
                </Swiper>
                :
                <MKBox textAlign="center" width="100%" my={20}>
                    <FadeLoader radius={5}/>
                </MKBox>
            }

        </MKBox>
    );
}

export default HappyStories;