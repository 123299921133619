import aboutImg1 from "assets/images/about/about-us-1.jpg";
import aboutImg2 from "assets/images/about/about-us-2.jpg";
import aboutImg3 from "assets/images/about/about-us-3.jpg";
import aboutImg4 from "assets/images/about/about-us-4.jpg";
import aboutImg5 from "assets/images/about/about-us-5.jpg";
import aboutImg6 from "assets/images/about/about-us-6.jpg";
import aboutImg7 from "assets/images/about/about-us-7.jpg";
import aboutImg8 from "assets/images/about/about-us-8.jpg";
import aboutImg9 from "assets/images/about/about-us-9.jpg";
import {faCommentDots, faHandHoldingHeart} from "@fortawesome/free-solid-svg-icons";

export const formFields = [
    "firstname",
    "lastname",
    "email",
    "country",
    "city",
    "birthday",
    "goals",
    "marital status",
    "amount of children",
    "education level",
    "Occupation",
    "height",
    "weight",
    "hair color",
    "eye color",
    "smoking habit",
    "drinking habit",
    "religion",
    "hobbies",
    "photos",
    "description"
];

export const aboutUsPhrases = [
    "We have a great experience in dating people",
    "We still keep in touch with our prior clients",
    "There is nothing impossible in life if there is a desire to change it for better",
    "We receive a great level of satisfaction by helping our clients"
];

export const aboutUsFacts = [
    {
        title: "great experience",
        img: aboutImg1,
        description: "Our agency An \"Ideal Couple\" has been in business since 1997 as an off line introduction agency. " +
            "Idealcouple.com was launched in Australia in 2003 by a couple who’s romantic story began with help of The Ideal Couple agency."
    },
    {
        title: "long term support",
        image: aboutImg2,
        description: "Ideal Couple, known for its successful introductions, brings together people from around the world. " +
            "We still keep in touch with our prior clients and are happy for their success in creating families with children " +
            "born in these international marriages."
    },
    {
        title: "understanding of clients",
        image: aboutImg3,
        description: "Our staff holds the same views, understanding the wishes and desires of people trying to find their second half. " +
            "We know the important our clients place on finding suitable partners for a happy and fulfilling marriage and family life."
    },
    {
        title: "flawless reputation",
        image: aboutImg4,
        description: "Over the years we have earned a flawless reputation by trying to care for our client first. For us it`s not " +
            "just a money making business but we receive a great level of satisfaction by helping our clients find their soul mates."
    },
    {
        title: "personal experience",
        image: aboutImg5,
        description: "Because we have been through the system ourselves, we understand clearly what our clients need are and any problems they may encounter."
    },
    {
        title: "everything is possible",
        image: aboutImg6,
        description: "Our task is to help people to find their second half, to make an ideal couple and a family in harmony." +
            " Some people are lucky to find it the first time around, but others have to make greater effort to get a positive result."
    },
    {
        title: "It's important",
        image: aboutImg7,
        description: "It's important to make quality and expressive pictures, to master a foreign language, to pay attention to the contents of " +
            "letters and to express sincere interest in a partner. "
    },
    {
        title: "it's exciting",
        image: aboutImg8,
        description: "It's not difficult on the contrary, it's very interesting and exciting. There are many people who manage to " +
            "follow through until the end, until they find their true happiness."
    },
    {
        title: "be happy",
        image: aboutImg9,
        description: "We consider OUR CLIENTS ARE WORTH FINDING HAPPINESS. Remember, there is nothing impossible in life if there is a desire to change it for better"
    },

];

export const ageLimits = [18, 100];

export const heightLimits = [120, 250];

export const weightLimits = [30, 300]

export const emailValidFormat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordMinLength = 6;

export const initialSearchProfilesPagination = {limit: 12, offset: 0};

export const initialMessagesPagination = {limit: 15, offset: 0};

export const initialPaymentHistoryPaginationSettings = { limit: 10, offset: 0 };

export const links = {
    // MY PROFILE
    myProfile: '/my-profile',
    edit: '/edit',
    balance: '/balance',
    cart: '/cart',
    communication: '/communication',
    letsMatch: '/lets-match',
    settings: '/settings',
    myProfile_edit: '/my-profile/edit',
    myProfile_balance: '/my-profile/balance',
    myProfile_cart: '/my-profile/cart',
    myProfile_communication: '/my-profile/communication',
    myProfile_letsMatch: '/my-profile/lets-match',
    myProfile_settings: '/my-profile/settings',
    login: '/login',
    register: '/register',
    reset: '/reset-password',
    signOut: '/sign-out',
    subscriptions: '/subscriptions',
    search: '/search',
    about: '/about',
    policy: '/policy',
    profile: '/profile',
    main: '/'

}

export const messages = {
    notActivateAccount: 'Activate your account in settings to interact'
}

export const defaultActivities = [
    {label: 'Start chat', icon: faCommentDots, link: links.myProfile_communication},
    {label: 'Lets match', icon: faHandHoldingHeart, link: links.myProfile_letsMatch}
];