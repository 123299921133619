import React, {useRef, useState} from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./PhotoCropper.scss";

// @mui material components
import {ButtonGroup, Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";

// Material Kit 2 PRO React components
import MKBox from "components/basic/MKBox";

// Icons
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowsAlt,
    faRedo,
    faSave,
    faSearchMinus,
    faSearchPlus,
    faTh,
    faTimes,
    faUndo
} from "@fortawesome/free-solid-svg-icons";

const PhotoCropper = ({photo, isAvatar, onClose, onSave}) => {
    const [cropper, setCropper] = useState();
    const cropRef = useRef();

    const getCropData = () => {
        if (typeof cropper !== "undefined") onSave(cropper.getCroppedCanvas().toDataURL());
    };

    const changeDragMode = (mode) => cropRef.current.cropper.setDragMode(mode);
    const rotateCrop = degree => cropRef.current.cropper.rotate(degree);
    const zoomCrop = value => cropRef.current.cropper.zoom(value);

    return (
        <MKBox>
            <MKBox>
                <Cropper
                    ref={cropRef}
                    style={{height: 400, width: "100%"}}
                    zoomTo={0}
                    initialAspectRatio={isAvatar ? 1 : NaN}
                    aspectRatio={isAvatar ? 1 : NaN}
                    preview=".img-preview"
                    src={photo}
                    viewMode={2}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={true}
                />
            </MKBox>

            <MKBox sx={{textAlign: "center"}}>
                <ButtonGroup size="small" variant={"text"} className={"bg-dark"}>
                    <Button variant={"outlined"} onClick={getCropData}><FontAwesomeIcon icon={faSave}/></Button>
                    <Button variant={"outlined"} onClick={() => changeDragMode('move')}><FontAwesomeIcon
                        icon={faArrowsAlt}/></Button>
                    <Button variant={"outlined"} onClick={() => changeDragMode('crop')}><FontAwesomeIcon
                        icon={faTh}/></Button>
                    <Button variant={"outlined"} onClick={() => zoomCrop(0.1)}><FontAwesomeIcon
                        icon={faSearchPlus}/></Button>
                    <Button variant={"outlined"} onClick={() => zoomCrop(-0.1)}><FontAwesomeIcon icon={faSearchMinus}/></Button>
                    <Button variant={"outlined"} onClick={() => rotateCrop(-90)}><FontAwesomeIcon
                        icon={faUndo}/></Button>
                    <Button variant={"outlined"} onClick={() => rotateCrop(90)}><FontAwesomeIcon
                        icon={faRedo}/></Button>
                    <Button variant={"outlined"} onClick={onClose}><FontAwesomeIcon icon={faTimes}/></Button>
                </ButtonGroup>
            </MKBox>
            {isAvatar &&
            <Grid container display="flex" justifyContent="space-between" padding={4}>
                <Grid item xs={12} sm={6} lg={3} mb={3}>
                    <div className="img-preview"
                         style={{width: "100%", minHeight: "100px", margin: "auto", borderRadius: "50px"}}/>
                    <Typography textAlign="center" marginTop={2} fontSize={17} fontWeight="500">Small size</Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} mb={3}>
                    <div className="img-preview"
                         style={{width: "100%", minHeight: "200px", margin: "auto", borderRadius: ".75rem"}}/>
                    <Typography textAlign="center" marginTop={2} fontSize={17} fontWeight="500">Medium size</Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={5} mb={3}>
                    <div className="img-preview"
                         style={{width: "100%", minHeight: "350px", margin: "auto", borderRadius: ".75rem"}}/>
                    <Typography textAlign="center" marginTop={2} fontSize={17} fontWeight="500">Large size</Typography>
                </Grid>
            </Grid>
            }

        </MKBox>
    );
};

export default PhotoCropper;
