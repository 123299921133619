import {ADD_NOTIFICATION} from "./NotificationConstants";

const handlers = {
    [ADD_NOTIFICATION]: (state, {payload}) => ({...state, notification: payload}),
    DEFAULT: state => state
}

export const NotificationReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT;
    return handler(state, action);
}