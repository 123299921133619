import {ImageList, ImageListItem, Modal, Slide} from "@mui/material";
import MKBox from "../../../basic/MKBox";
import React, {useState} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MasonryImageList = ({itemData}) => {
    const [show, setShow] = useState(false);
    const [image, setImage] = useState();
    const toggleModal = (image) => {
        setImage(image);
        setShow(!show);
    };
    return (
        <MKBox sx={{width: '100%', height: 'auto'}}>
            <ImageList variant="masonry" cols={3} gap={8}>
                {itemData.map((item, index) => (
                    <ImageListItem key={'profile-photos' + index} onClick={() => toggleModal(item)}>
                        <img
                            src={`${process.env.REACT_APP_API_URL}/${item}?w=248&fit=crop&auto=format`}
                            srcSet={`${process.env.REACT_APP_API_URL}/${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={`image-${index}`}
                            loading="lazy"
                            style={{cursor: 'pointer'}}
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Modal open={show} onClose={() => toggleModal(null)} sx={{display: "grid", placeItems: "center"}}>
                <Slide direction="down" in={show} timeout={500}>
                    <MKBox
                        position="relative"
                        width="auto"
                        display="flex"
                        flexDirection="column"
                        borderRadius="xl"
                        bgColor="white"
                        shadow="xl"
                    >
                        <MKBox sx={{position: 'absolute', top: '5px', right: '10px', cursor: 'pointer'}}
                               onClick={() => toggleModal(null)}>
                            <FontAwesomeIcon icon={faTimes} className="image-close-icon"/>
                        </MKBox>
                        {image ?
                            <MKBox
                                component="img"
                                src={process.env.REACT_APP_API_URL + '/' + image}
                                alt={"user photo"}
                                width="auto"
                                maxWidth="100%"
                                maxHeight="100vh"
                                borderRadius="xl"
                            />
                            : null
                        }
                    </MKBox>
                </Slide>
            </Modal>
        </MKBox>
    );
}

export default MasonryImageList;