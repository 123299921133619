import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import TopNavbarDropdown from "components/blocks/TopNavbar/TopNavbarDropdown";
import TopNavbarMobile from "components/blocks/TopNavbar/TopNavbarMobile";
import breakpoints from "assets/theme/base/breakpoints";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ScreenSizeContext} from "../../../contexts/screenSize/ScreenSizeContext";
import logo from "../../../assets/logo/logo.png";
import Notification from "../Notification/Notification";

function TopNavbar({brand, routes, transparent, light, sticky, relative, center, notification}) {
    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    const {screenSizeType, screenSizeValue} = useContext(ScreenSizeContext);

    useEffect(() => {
        if (!screenSizeType) return;
        if (screenSizeValue < breakpoints.values.lg) {
            setMobileView(true);
            setMobileNavbar(false);
        } else {
            setMobileView(false);
            setMobileNavbar(false);
        }
    }, [screenSizeType])

    const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

    const renderNavbarItems = routes.map(({name, icon, href, route}) => (
        <TopNavbarDropdown
            key={name + href}
            name={name}
            icon={icon}
            href={href}
            route={route}
            light={light}
        />
    ));

    return (
        <MKBox sx={sticky ? {position: "sticky", top: -16, zIndex: 11} : null} py={0} mt={0}>
            <MKBox
                py={1}
                px={{xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2}}
                my={relative ? 0 : 2}
                mx={relative ? 0 : 3}
                width={relative ? "100%" : "calc(100% - 48px)"}
                borderRadius="xl"
                shadow={transparent ? "none" : "md"}
                color={light ? "white" : "dark"}
                position={relative ? "relative" : "absolute"}
                left={0}
                zIndex={3}
                sx={({palette: {transparent: transparentColor, white}, functions: {rgba}}) => ({
                    backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
                    backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
                })}
            >
                <Container>
                    <MKBox display="flex" justifyContent="space-between" alignItems="center">
                        <MKBox
                            component={Link}
                            to="/"
                            lineHeight={1}
                            py={transparent ? 1.5 : 0.75}
                            pl={relative || transparent ? 0 : {xs: 0, lg: 1}}
                        >
                            <MKBox display="flex">
                                <MKBox
                                    component="img"
                                    src={logo}
                                    width="50px"
                                />
                                <MKBox>
                                    <MKTypography component="span" className="logo-text logo-text-light" sx={{fontSize: '20px!important'}}>
                                        Ideal
                                    </MKTypography>
                                    <MKTypography component="span" className="logo-text logo-text-dark" sx={{fontSize: '20px!important'}}>
                                        Couple
                                    </MKTypography>
                                </MKBox>
                            </MKBox>

                        </MKBox>
                        <MKBox
                            color="inherit"
                            display={{xs: "none", lg: "flex"}}
                            alignItems="center"
                            ml="auto"
                            mr={center ? "auto" : 0}
                        >
                            {renderNavbarItems}
                            <Notification color={light} notification={notification}/>
                        </MKBox>
                        <MKBox
                            display={{xs: "inline-block", lg: "none"}}
                            lineHeight={0}
                            py={1.5}
                            pl={1.5}
                            color={transparent ? "white" : "inherit"}
                            sx={{cursor: "pointer"}}
                            onClick={openMobileNavbar}
                        >
                            <FontAwesomeIcon icon={mobileNavbar ? faTimes : faBars}/>
                        </MKBox>
                    </MKBox>
                    <MKBox
                        bgColor={transparent ? "white" : "transparent"}
                        shadow={transparent ? "lg" : "none"}
                        borderRadius="xl"
                        px={transparent ? 2 : 0}
                    >
                        {mobileView &&
                            <TopNavbarMobile routes={routes} open={mobileNavbar} onClick={openMobileNavbar}/>}
                    </MKBox>
                </Container>
            </MKBox>
        </MKBox>
    );
}

// Setting default values for the props of DefaultNavbar
TopNavbar.defaultProps = {
    brand: "Ideal couple",
    transparent: false,
    light: false,
    sticky: false,
    relative: false,
    center: false,
};

// Typechecking props for the DefaultNavbar
TopNavbar.propTypes = {
    brand: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    transparent: PropTypes.bool,
    light: PropTypes.bool,
    sticky: PropTypes.bool,
    relative: PropTypes.bool,
    center: PropTypes.bool,
};

export default TopNavbar;
