import {faAward, faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CircularProgress} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import bgImage from "assets/images/backgrounds/bg-balance.jpg";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import useServer from "../../../../hooks/useServer";
import {PAYMENT} from "../../../../serverUrls";
import {toastConfig} from "../../../../utils/utils";
import MKBadge from "../../../basic/MKBadge";
import MKBox from "../../../basic/MKBox";
import MKButton from "../../../basic/MKButton";
import MKTypography from "../../../basic/MKTypography";
import PaymentHistoryTable from "./PaymentHistoryTable";
import {initialPaymentHistoryPaginationSettings as initialPaginationSettings} from "utils/constants";
import Card from "@mui/material/Card";

const ViewProfile = ({myProfileState}) => {
    const {state, search} = useLocation();
    const [payments, setPayments] = useState([]);
    const [pagination, setPagination] = useState({total: 0, onPage: 0, pages: 0, currentPage: 0});
    const [rows, setRows] = useState([]);
    const [paginationSettings, setPaginationSettings] = useState({...initialPaginationSettings});
    const [additionalPayments, setAdditionalPayments] = useState(false);
    const [paymentsResponse, paymentsError, paymentsIsLoading, paymentsSendRequest, paymentsSetError] = useServer(PAYMENT);

    const membership = profile => {
        if (!profile) return '';
        if (profile.isPremium) return 'Premium';
        else if (!profile.amountOfActions &&
            !profile.amountOfGifts &&
            !profile.amountOfGreetings &&
            !profile.amountOfIceBreakers &&
            !profile.amountOfLetsMatch &&
            !profile.amountOfLetters) return 'Free';
        else return 'Pay as you go';
    }

    const premiumEnds = profile => {
        if (profile && profile.isPremium && profile.premiumEnds) return moment(profile.premiumEnds).format("MMM Do YY");
        else return '-';
    }

    const balanceList = profile => {
        if (profile) {
            return [
                {name: 'Your membership is', value: membership(profile), label: 'upgrade', link: '/subscriptions'},
                {name: 'Membership is until', value: premiumEnds(profile), label: 'prolong', link: '/subscriptions'},
                {name: 'Greetings', value: profile.amountOfGreetings, label: null, link: null},
                {name: 'Lets Match', value: profile.amountOfLetsMatch, label: null, link: null},
                {name: 'Ice Breakers', value: profile.amountOfIceBreakers, label: null, link: null},
                {
                    name: 'Letters',
                    value: profile.isPremium ? 'unlimited' : profile.amountOfLetters,
                    label: profile.isPremium ? null : 'add letters',
                    link: '/my-profile/cart'
                },
                {
                    name: 'Gifts',
                    value: profile.isPremium ? 'unlimited' : profile.amountOfGifts,
                    label: profile.isPremium ? null : 'add gifts',
                    link: '/my-profile/cart'
                },
                {name: 'Actions', value: profile.amountOfActions, label: 'add actions', link: '/my-profile/cart'}
            ];
        } else return [];
    }

    const createRows = payments => {
        const rows = [];
        payments.map(payment => (rows.push({
            name: [<FontAwesomeIcon icon={faAward}/>, payment.description],
            status: <MKBadge variant="contained"
                             badgeContent={payment.status}
                             color={
                                 payment.status === 'succeeded'
                                     ? 'success'
                                     : (payment.status === 'pending'
                                         ? 'info'
                                         : (payment.status === 'paid'
                                             ? 'secondary'
                                             : 'error'))}
                             size="xs"
                             container/>,
            amount: '$' + payment.amount,
            date: moment(payment.paidAt).format('MM/DD/YY, h:mm a')
        })));

        return rows;
    }

    const getMorePayments = () => {
        const newPaginationSettings = {
            ...paginationSettings,
            offset: (pagination.currentPage < pagination.pages)
                ? paginationSettings.offset + paginationSettings.limit
                : paginationSettings.offset
        }
        paymentsSendRequest({
            method: 'get',
            params: Object.assign(newPaginationSettings)
        });
        setPaginationSettings(newPaginationSettings);
        setAdditionalPayments(true);
    }

    // On load page
    useEffect(() => {
        paymentsSendRequest({method: 'get', params: Object.assign(initialPaginationSettings)});
    }, [])

    // On state and search change
    useEffect(() => {
        if (state) {
            myProfileState.setMyProfile(state.myProfile);
        }
        if (search) {
            if (search.includes('show')) {
                const message = decodeURI(search.substring(6));
                toast.success(message, toastConfig);
            }
        }
    }, [state, search])

    // Response with payments
    useEffect(() => {
        if (paymentsResponse) {
            const paymentsToSet = additionalPayments
                ? payments.concat(paymentsResponse.data?.payments)
                : paymentsResponse.data?.payments;
            const rowsToSet = createRows(paymentsToSet);
            setRows(rowsToSet);
            setPayments(paymentsToSet);
            setPagination(paymentsResponse.data?.pagination);
        }
    }, [paymentsResponse])

    // Errors for payments
    useEffect(() => {
        if (paymentsError) {
            toast.error(paymentsError.data?.message ?? 'Payment history failed');
            paymentsSetError(null);
        }
        return () => {
            paymentsSetError(null);
        }
    }, [paymentsError])

    return (
        <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
            <MKBox
                display="flex"
                my={0}
                py={3}
                sx={{
                    backgroundImage: ({
                                          palette: {gradients},
                                          functions: {linearGradient, rgba}
                                      }) => `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <Container>
                    {myProfileState.myProfile && balanceList(myProfileState.myProfile).map((balance, index) =>
                        <Grid container alignItems="center" key={'row-' + index}>
                            <Grid item xs={12} md={8} lg={9}>
                                <MKTypography variant="body2" color="white" fontWeight="bold" sx={{display: 'flex'}}>
                                    {balance.name}:
                                    <MKBadge badgeContent={balance.value?.toString() ?? 'unlimited'} size="lg" container
                                             color="light"
                                             sx={{ml: 'auto', mr: {sx: 0, md: 3}}}/>
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3} sx={{ml: "auto"}}>
                                <MKBox ml="auto" sx={{py: 2, textAlign: 'center', color: 'yellow'}}>
                                    {
                                        balance.label
                                            ? <Link to={balance.link}>
                                                <MKButton variant="gradient" color="warning" fullWidth
                                                          sx={{boxShadow: "none"}}>
                                                    {balance.label}
                                                </MKButton>
                                            </Link>
                                            : <FontAwesomeIcon icon={faCheck}/>
                                    }
                                </MKBox>
                            </Grid>
                        </Grid>
                    )}
                </Container>
            </MKBox>

            <MKTypography variant="h4" color="text" fontWeight="bold" sx={{mx: 'auto', my: 3}}>
                Payment History
            </MKTypography>

            <MKBox display="flex">
                <PaymentHistoryTable
                    columns={[
                        {name: "name", align: "left"},
                        {name: "status", align: "center"},
                        {name: "amount", align: "center"},
                        {name: "date", align: "center"},
                    ]}
                    rows={rows}
                />
            </MKBox>

            {pagination.pages === 0 || pagination.pages === pagination.currentPage ? null :
                <MKButton variant="gradient" color="info" sx={{mt: 2, w: 'auto'}} onClick={getMorePayments}
                          disabled={paymentsIsLoading}
                >
                    <CircularProgress color="white" size={24} sx={{mr: 2, opacity: paymentsIsLoading ? 1 : 0}}/>
                    More {`${pagination.onPage} (${pagination.currentPage * pagination.onPage} of ${pagination.total})`}
                </MKButton>
            }
        </Card>
    )
}
export default ViewProfile;