import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { InView } from 'react-intersection-observer';

function DefaultCounterCard({ color, count, title, description, ...rest }) {
  return (
    <MKBox p={2} textAlign="center" lineHeight={1}>
      <MKTypography variant="h1" color={color} textGradient>
        <CountUp end={count} redraw={true}>
          {({ countUpRef, start }) => (
            <InView as="div" onChange={start}>
              +<MKBox component="span" ml={1} ref={countUpRef} />
            </InView>
          )}
        </CountUp>
      </MKTypography>
      {title && (
        <MKTypography variant="h4" mt={2} mb={1} color="success">
          {title}
        </MKTypography>
      )}
      {description && (
        <MKTypography variant="body2" color={color}>
          {description}
        </MKTypography>
      )}
    </MKBox>
  );
}

// Setting default props for the DefaultCounterCard
DefaultCounterCard.defaultProps = {
  color: "light",
  description: "",
  title: "",
};

// Typechecking props for the DefaultCounterCard
DefaultCounterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  count: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DefaultCounterCard;
