import React, {useContext, useEffect, useState} from 'react';
import bgImage from "assets/images/backgrounds/bg-login.jpg";
import Card from "@mui/material/Card";
import MKBox from "../components/basic/MKBox";
import MKTypography from "../components/basic/MKTypography";
import MKInput from "../components/basic/MKInput";
import Switch from "@mui/material/Switch";
import MKButton from "../components/basic/MKButton";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import BasicLayout from "../components/layouts/BasicLayout";
import {toast, ToastContainer} from 'react-toastify';
import {toastConfig} from "../utils/utils";
import {Checkbox, FormHelperText} from "@mui/material";
import {emailValidFormat, links, passwordMinLength} from "../utils/constants";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../contexts/auth/AuthContext";
import logo from "../assets/logo/logo.png";

const initialErrors = {
    email: '',
    password: '',
    repeat: ''
};

const initialTouches = {
    email: false,
    password: false,
    repeat: false
}

const AuthPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const emailParam = searchParams.get("email");
    const isLoginPage = location.pathname === links.login;
    const isRegisterPage = location.pathname === links.register;
    const isResetPasswordPage = location.pathname === links.reset;
    const title = isLoginPage ? 'sign in' : (isResetPasswordPage ? 'reset password' : (isRegisterPage ? 'sing up' : ''));
    const [email, setEmail] = useState(isResetPasswordPage && emailParam ? emailParam : '');
    const [password, setPassword] = useState('');
    const [repeat, setRepeat] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [agreement, setAgreement] = useState(true);
    const [formErrors, setFormErrors] = useState(initialErrors);
    const [formTouches, setFormTouches] = useState(initialTouches);
    const [showErrors, setShowErrors] = useState(false);
    const {login, register, isLoading, error, setError, forgotPasswordRequest, resetPassword} = useContext(AuthContext);

    const handleInputChange = (name, value) => {
        let error = '';
        switch (name) {
            case 'email':
                if (!value.match(emailValidFormat)) error = 'incorrect email format!';
                if (value.trim().length === 0) error = 'email is required!';
                setFormErrors({...formErrors, email: error});
                setEmail(value);
                setFormTouches({...formTouches, email: true});
                if (error) return error;
                break;
            case 'password':
                if (value.trim().length === 0) error = 'password is required!';
                if (value.length < passwordMinLength) error = 'password is to short!';
                setFormErrors({...formErrors, password: error});
                setPassword(value);
                setFormTouches({...formTouches, password: true});
                if (error) return error;
                break;
            case 'repeat':
                if (value !== password) error = 'not match!';
                if (value.trim().length === 0) error = 'password confirm is required!';
                setFormErrors({...formErrors, repeat: error});
                setRepeat(value);
                setFormTouches({...formTouches, repeat: true});
                if (error) return error;
                break;
            default:
                break;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        const checkRepeatPassword = handleInputChange('repeat', repeat);
        const checkPassword = handleInputChange('password', password);
        const checkEmail = handleInputChange('email', email);

        if (isLoginPage) {
            if (checkEmail) {
                toast.error(checkEmail, toastConfig);
                return;
            }
            if (checkPassword) {
                toast.error(checkPassword, toastConfig);
                return;
            }
        } else {
            if (checkEmail) {
                toast.error(checkEmail, toastConfig);
                return;
            }
            if (checkPassword) {
                toast.error(checkPassword, toastConfig);
                return;
            }
            if (checkRepeatPassword) {
                toast.error(checkRepeatPassword, toastConfig);
                return;
            }
        }

        const errorsAmount = countErrorsAmount(formErrors);
        if (errorsAmount > 0) setShowErrors(true);
        else {
            if (isLoginPage) login({email, password, rememberMe});
            else if (isResetPasswordPage) resetPassword({password, token});
            else if (isRegisterPage) register({email, password});
        }
    }

    const handleForgotPassword = e => {
        e.preventDefault();
        if (!email.match(emailValidFormat)) setError({data: {message: 'incorrect email format!'}});
        else if (email.trim().length === 0) setError({data: {message: 'email is required!'}});
        else forgotPasswordRequest(email).then(response => {
                if (response?.status === 200) {
                    toast.success("A reset password link has been sent to your email", toastConfig);
                    setTimeout(() => {navigate(links.main);}, toastConfig.autoClose);
                }
            });
    }

    const countErrorsAmount = errors => {
        let errorsAmount = 0;
        Object.keys(errors).forEach(errorField => {
            if (errors[errorField]) errorsAmount += 1;
        });
        return errorsAmount;
    }

    useEffect(() => {
        if (isResetPasswordPage) {
            if (!email || !token) {
                navigate(links.main);
            }
        }
    }, [])

    useEffect(() => {
        if (error) {
            toast.error(error.data?.message ?? error.message ?? 'Something went wrong', toastConfig);
            setError(null);
        }
        return () => setError(null);
    }, [error])

    return (
        <div className="main">
            <ToastContainer/>
            <BasicLayout image={bgImage}>
                <MKBox component={Link} to="/">
                    <MKBox display="flex" justifyContent="center" mb={5} mt={2}>
                        <MKBox
                            component="img"
                            src={logo}
                            width="70px"
                        />
                        <MKBox>
                            <MKTypography component="span" className="logo-text logo-text-light"
                                          sx={{fontSize: '30px!important'}}>
                                Ideal
                            </MKTypography>
                            <MKTypography component="span" className="logo-text logo-text-dark"
                                          sx={{fontSize: '30px!important'}}>
                                Couple
                            </MKTypography>
                        </MKBox>
                    </MKBox>
                </MKBox>
                <Card>
                    <MKBox mt={3} textAlign="center">
                        <MKTypography variant="h4" fontWeight="medium" color="dark" textTransform="capitalize">
                            {title}
                        </MKTypography>
                    </MKBox>
                    <MKBox pt={4} pb={3} px={3}>
                        <MKBox component="form" role="form" onSubmit={handleSubmit} noValidate>
                            <MKBox mb={2}>
                                <MKInput type="email" label="Email" fullWidth name="email" autoComplete="on"
                                         disabled={isResetPasswordPage}
                                         value={email} onChange={e => handleInputChange(e?.target?.name, e?.target?.value)}
                                         success={!formErrors.email && formTouches.email}
                                />
                                <FormHelperText>{formErrors.email ?? ''}</FormHelperText>
                            </MKBox>
                            <MKBox mb={2}>
                                <MKInput type="password" label={isResetPasswordPage ? 'New Password' : 'Password'}
                                         fullWidth name="password" autoComplete="on"
                                         value={password} onChange={e => handleInputChange(e?.target?.name, e?.target?.value)}
                                         success={!formErrors.password && formTouches.password}
                                />
                                <FormHelperText>{formErrors.password ?? ''}</FormHelperText>
                            </MKBox>
                            {isLoginPage
                                ?
                                <>
                                    <MKBox display="flex" alignItems="center" ml={-1}>
                                        <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}/>
                                        <MKTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="text"
                                            onClick={() => setRememberMe(!rememberMe)}
                                            sx={{cursor: "pointer", userSelect: "none", ml: -1}}
                                        >
                                            &nbsp;&nbsp;Remember me
                                        </MKTypography>
                                    </MKBox>
                                    <MKBox display="flex" justifyContent="center" mt={2}>
                                        <MKTypography
                                            component="a"
                                            to="/register"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                            onClick={handleForgotPassword}
                                            sx={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            Forgot password?
                                        </MKTypography>
                                    </MKBox>
                                </>
                                :
                                <>
                                    <MKBox mb={2}>
                                        <MKInput type="password" label="Repeat Password" fullWidth name="repeat"
                                                 autoComplete="on"
                                                 value={repeat} onChange={e => handleInputChange(e?.target?.name, e?.target?.value)}
                                                 success={!formErrors.repeat && formTouches.repeat}
                                        />
                                        <FormHelperText>{formErrors.repeat ?? ''}</FormHelperText>
                                    </MKBox>

                                    {
                                        isRegisterPage ?
                                            <>
                                                <MKBox display="flex" alignItems="center" ml={-1}>
                                                    <Checkbox
                                                        checked={agreement}
                                                        onChange={e => setAgreement(e.target.checked)}
                                                    />
                                                    <MKTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{cursor: "pointer", userSelect: "none", ml: -1}}
                                                        onClick={() => setAgreement(!agreement)}
                                                    >
                                                        &nbsp;&nbsp;I agree the&nbsp;
                                                    </MKTypography>
                                                    <MKTypography
                                                        component="a"
                                                        href={'/policy'}
                                                        target={'_blank'}
                                                        variant="button"
                                                        fontWeight="bold"
                                                        color="info"
                                                        textGradient
                                                    >
                                                        Terms and Conditions
                                                    </MKTypography>
                                                </MKBox>
                                                <FormHelperText>{!agreement ? 'required!' : ''}</FormHelperText>
                                            </>
                                            : ''
                                    }

                                </>
                            }
                            {
                                countErrorsAmount(formErrors) > 0 && showErrors && formTouches && (
                                    <MKBox display="flex">
                                        <MKTypography margin="auto" variant="button" color="error" fontWeight="bold">
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="me-2"/>
                                            {countErrorsAmount(formErrors)} error(s) found
                                        </MKTypography>
                                    </MKBox>
                                )
                            }
                            <MKBox mt={4} mb={1}>
                                <MKButton type="submit" variant="gradient" color="info" fullWidth
                                          disabled={!agreement || isLoading}>
                                    {title}
                                </MKButton>
                            </MKBox>
                            {isLoginPage
                                ? (<MKBox mt={3} mb={1} textAlign="center">
                                    <MKTypography variant="button" color="text">
                                        Don&apos;t have an account?{" "}
                                        <MKTypography
                                            component={Link}
                                            to="/register"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                        >
                                            Sign Up
                                        </MKTypography>
                                    </MKTypography>
                                </MKBox>)
                                : (
                                    isRegisterPage ?
                                        <MKBox mt={3} mb={1} textAlign="center">
                                            <MKTypography variant="button" color="text">
                                                Already have an account?{" "}
                                                <MKTypography
                                                    component={Link}
                                                    to="/login"
                                                    variant="button"
                                                    color="info"
                                                    fontWeight="medium"
                                                    textGradient
                                                >
                                                    Sign In
                                                </MKTypography>
                                            </MKTypography>
                                        </MKBox>
                                        : ''
                                )
                            }
                        </MKBox>
                    </MKBox>
                </Card>
            </BasicLayout>
        </div>
    );
}

export default AuthPage;