import {CHANGE_USER, LOGIN, LOGOUT, REGISTER} from "./AuthConstants";

const handlers = {
    [LOGIN]: (state, {payload}) => (
        {...state, token: payload.token, user: payload.user, expirationDate: payload.expirationDate, isAuthenticated: true}
    ),
    [CHANGE_USER]: (state, {payload}) => ({...state, user: payload}),
    [LOGOUT]: () => ({token: null, user: null, isAuthenticated: false, expirationDate: null}),
    [REGISTER]: (state) => ({...state, justRegistered: true}),
    DEFAULT: state => state
}

export const AuthReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT;
    return handler(state, action);
}