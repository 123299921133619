import React, {useContext, useMemo, useState} from 'react';
import MKBox from "../../basic/MKBox";
import {Menu, MenuItem} from "@mui/material";
import MKBadge from "../../basic/MKBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import MKTypography from "../../basic/MKTypography";
import {useNavigate} from "react-router-dom";
import {links} from "../../../utils/constants";
import MKButton from "../../basic/MKButton";
import {NotificationContext} from "../../../contexts/notifications/NotificationContext";

const getNotificationLength = notification => {
    return (notification?.messages?.length ?? 0) + (notification?.interested?.length ?? 0);
}

const Notification = ({notification, color}) => {
    const [dropdown, setDropdown] = useState(null);
    const {clearAllNotification} = useContext(NotificationContext);
    const notificationLength = useMemo(() => getNotificationLength(notification), [notification]);
    const openDropdown = ({currentTarget}) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);
    const navigation = useNavigate();
    const handleClick = e => {
        const type = e.target.id;
        closeDropdown();
        navigation(links.myProfile, {state: {path: type === 'messages' ? links.communication : links.letsMatch}});
    }

    const clearAllNotifications = () => {
        closeDropdown();
        clearAllNotification();
    }

    // Styles
    const iconStyles = {
        ml: 1,
        fontWeight: "bold",
        transition: "transform 200ms ease-in-out",
    };

    const dropdownIconStyles = {
        transform: dropdown ? "scale(1.2)" : "rotate(0)",
        ...iconStyles,
    };

    const bellStyles = {
        fontSize: '18px',
        color: color ? 'white' : '#344767'
    }

    return notificationLength > 0 ? (
        <MKBox color="white">
            <MKBadge
                style={dropdownIconStyles}
                color="secondary"
                circular
                size="xs"
                badgeContent={notificationLength}
                onClick={openDropdown}
            >
                <FontAwesomeIcon
                    icon={faBell}
                    style={bellStyles}
                />
            </MKBadge>
            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
                <MenuItem onClick={handleClick} id="messages"
                          sx={{display: 'flex', alignItems: 'canter', justifyContent: 'space-between'}}>
                    Messages
                    {
                        notification?.messages ?
                            <MKBadge color="secondary" badgeContent={notification?.messages.length} />
                            : <MKTypography variant="button">0</MKTypography>
                    }
                </MenuItem>
                <MenuItem onClick={handleClick} id="interested"
                          sx={{display: 'flex', alignItems: 'canter', justifyContent: 'space-between'}}>
                    Interested
                    {
                        notification?.interested ?
                            <MKBadge color="secondary" badgeContent={notification?.interested.length}/>
                            : <MKTypography variant="button">0</MKTypography>
                    }
                </MenuItem>
                <MenuItem>
                    <MKButton size="small" id="clearAll" color="secondary" variant="outlined" fullWidth onClick={clearAllNotifications}>
                        clear all
                    </MKButton>
                </MenuItem>
            </Menu>

        </MKBox>
    ) : null;
}

export default Notification;