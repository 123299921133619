export const GET_COMMON_DATA = '/api/common-data';

export const ACTIVATE_ACCOUNT = '/api/auth/activate';
export const LOGIN_URL = '/api/auth/signin';
export const REGISTER_URL = '/api/auth/signup';
export const CONFIRM_EMAIL = '/api/auth/confirm-email';
export const FORGOT_PASSWORD = '/api/auth/forgot-password'
export const RESET_PASSWORD = '/api/auth/reset-password'
export const CHANGE_SUBSCRIPTION = '/api/auth/change-subscription';
export const DELETE_ME = '/api/auth/me';

export const PRODUCT = '/api/product';
export const STRIPE_CHARGE = '/api/stripe/charge';
export const PAYMENT = '/api/payment';
export const MEMBERSHIP = '/api/membership';
export const CHANGE_PASSWORD = '/api/auth/change-password';
export const MESSAGE = '/api/message';
export const MESSAGE_ALL = '/api/message/all';

export const PROFILE = '/api/profile';
export const PROFILE_ME = '/api/profile/me';
export const PROFILE_LIKE = '/api/profile/like';
export const PROFILE_FAVORITE = '/api/profile/favorite';
export const PROFILE_BLOCK = '/api/profile/block';
export const PROFILE_RANDOM = '/api/profile/random';

export const GREETING = '/api/greeting';
export const ICE_BREAKER = '/api/ice-breaker';
export const GIFT = '/api/gift';
export const ACTION = '/api/action';

export const MATCH = '/api/match';

export const ADVANTAGE = '/api/advantage';
export const STORY = '/api/story';
export const CONCEPT = '/api/concept';
export const MEMBER = '/api/member';
export const ARTICLE = '/api/article';

export const NOTIFICATION = '/api/notification';