import React, {useEffect, useState} from 'react';
import MKBox from "../../../basic/MKBox";
import MKTypography from "../../../basic/MKTypography";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCards} from "swiper";
import {convertDateToAge} from "../../../../utils/utils";
import MKBadge from "../../../basic/MKBadge";
import {Modal, Slide, Stack} from "@mui/material";
import MKButton from "../../../basic/MKButton";
import EmptyCard from "../../../cards/RotatingCard/EmptyCard";
import emptyCardImage from "../../../../assets/images/defaults/default-avatar.jpg";
import Divider from "@mui/material/Divider";
import {faBell, faEye, faHeartBroken, faHourglassHalf, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {faComment} from "@fortawesome/free-solid-svg-icons/faComment";

const LetsMatchViewList = ({title, listType, list, changeList, checkMatch, answer, startChat}) => {
    const [show, setShow] = useState(false);
    const toggleModal = () => setShow(!show);
    const [currentProfile, setCurrentProfile] = useState();

    useEffect(() => {
        if (answer) setShow(true);
        return () => setShow(false);
    }, [answer])

    return (
        <MKBox mb={3}>
            {list.length !== 0 ?
                <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="card-swiper"
                >
                    {list.map((profile, index) => (
                        <SwiperSlide
                            key={'card-' + index}
                            className="card-swiper-slide"
                            style={{display: 'flex', flexDirection: 'column'}}
                        >
                            <MKBox
                                component="img"
                                src={process.env.REACT_APP_API_URL + '/' + profile.avatar}
                                sx={{width: '100%'}}
                            />
                            {
                                profile.isNew ? <MKBadge badgeContent="new" size="lg" color="success" circular
                                                         sx={{position: 'absolute', top: 10, right: 10}}
                                /> : null
                            }
                            <MKBox
                                display="flex"
                                flexDirection="column"
                                width="100%"
                                sx={{my: 1}}
                            >
                                <MKBox
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mx={1}
                                >
                                    <MKTypography variant="body1">
                                        {profile.firstname}, {convertDateToAge(profile.birthday)}
                                    </MKTypography>
                                    <MKBox>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            height="auto"
                                            src={`https://flagcdn.com/w20/${profile.country?.code?.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${profile.country?.code?.toLowerCase()}.png 2x`}
                                            alt={profile.country?.name}
                                        />
                                    </MKBox>
                                </MKBox>
                                <MKBox
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mx={1}
                                >
                                    <MKBox width="70%" display="flex" sx={{flexWrap: 'wrap'}}>
                                        {profile.languages?.length > 0 && profile.languages.map((language, index) =>
                                            <MKBadge key={'language-' + index}
                                                     color="info"
                                                     variant="contained"
                                                     size="xs"
                                                     badgeContent={language.name}
                                                     sx={{ml: -1, mr: 1, my: .5}}
                                            />
                                        )}
                                    </MKBox>
                                    <MKBox width="30%" textAlign="end">
                                        <MKTypography variant="body2">
                                            {profile.city}
                                        </MKTypography>
                                    </MKBox>
                                </MKBox>
                                <Stack direction="row" justifyContent="space-evenly" sx={{m: 1}}>
                                    {(listType === 'waiting' || listType === 'interested') &&
                                        <MKButton size="small" color="success" variant="contained"
                                                  onClick={() => changeList('favorite', 'add', profile._id)}
                                        >
                                            Match
                                        </MKButton>
                                    }

                                    {listType === 'favorite' &&
                                        <MKButton size="small" color="success" variant="contained"
                                                  onClick={() => {
                                                      setCurrentProfile(profile);
                                                      checkMatch(profile._id);
                                                  }}
                                        >
                                            What {profile.firstname} said
                                        </MKButton>
                                    }
                                    <MKButton size="medium" color="info" variant="contained" iconOnly
                                              onClick={() => startChat(profile._id)}
                                    >
                                        <FontAwesomeIcon icon={faComment}/>
                                    </MKButton>

                                    <Link to={`/profile/${profile._id}`} target="_blank">
                                        <MKButton size="medium" color="warning" variant="contained" iconOnly>
                                            <FontAwesomeIcon icon={faEye}/>
                                        </MKButton>
                                    </Link>
                                    <MKButton size="medium" color="error" variant="contained" iconOnly
                                              onClick={() => changeList(listType, 'remove', profile._id)}
                                    >
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </MKButton>
                                </Stack>
                            </MKBox>
                        </SwiperSlide>
                    ))}
                </Swiper> :
                <MKBox width="320px" mx="auto" mb={6}>
                    <EmptyCard color="light" title="no cards" image={emptyCardImage}/>
                </MKBox>
            }
            <MKTypography variant="h4" sx={{textAlign: 'center', textTransform: 'capitalize'}}>
                {title}
            </MKTypography>
            {answer &&
                <Modal open={show} onClose={toggleModal} sx={{display: "grid", placeItems: "center"}}>
                    <Slide direction="down" in={show} timeout={500}>
                        <MKBox
                            position="relative"
                            width="320px"
                            display="flex"
                            flexDirection="column"
                            borderRadius="xl"
                            variant="gradient"
                            bgColor={answer.found ? "success" : (answer.found === null ? "info" : "error")}
                            shadow="sm"
                        >
                            <MKBox
                                display="flex"
                                alginItems="center"
                                justifyContent="end"
                                py={2}
                                px={2}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        color: "white",
                                        fontSize: "medium",
                                        cursor: "pointer"
                                    }}
                                    onClick={toggleModal}
                                    icon={faTimes}
                                />
                            </MKBox>
                            <Divider light sx={{my: 0}}/>
                            <MKBox p={6} textAlign="center" color="white">
                                <FontAwesomeIcon size="lg" color="inherit"
                                                 icon={answer.found ? faBell : (answer.found === null ? faHourglassHalf : faHeartBroken)}/>
                                <MKTypography variant="h5" color="white" mt={3} mb={1}>
                                    {answer.found ? `${currentProfile?.firstname ?? 'User'} said YES!` : (answer.found === null ?
                                        `${currentProfile?.firstname ?? 'User'} will reply soon`
                                        :
                                        `${currentProfile?.firstname ?? 'User'} was not interested in your action`)}
                                </MKTypography>
                            </MKBox>
                            <Divider light sx={{my: 0}}/>
                            <MKBox display="flex" justifyContent="center" py={2} px={1.5}>
                                <MKButton color="white" size="small" onClick={toggleModal}>ok, got it</MKButton>
                                {answer && answer.found &&
                                    <MKButton variant="outlined" color="success" sx={{ml: 2}}
                                              onClick={() => {
                                                  changeList('second chance', 'add', currentProfile._id);
                                                  toggleModal();
                                              }}
                                    >
                                        second chance
                                    </MKButton>
                                }
                            </MKBox>
                        </MKBox>
                    </Slide>
                </Modal>
            }
        </MKBox>
    )
};

export default LetsMatchViewList;