import PropTypes from "prop-types";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import {convertDateToAge, convertDateToZodiacSign} from "../../../utils/utils";
import React, {Fragment} from "react";
import {faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MKButton from "../../basic/MKButton";

function RotatingCardFront({color, profile}) {
    return (
        <MKBox
            display="flex"
            justifyContent="center"
            alignContent="center"
            borderRadius="lg"
            coloredShadow={color}
            width="100%"
            position="relative"
            zIndex={2}
            sx={{
                // backgroundImage: `url(${process.env.REACT_APP_API_URL}/${profile.avatar})`,
                backgroundImage: ({palette: {gradients}, functions: {linearGradient, rgba}}) =>
                    `${linearGradient(
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
                    )}, url(${process.env.REACT_APP_API_URL}/${profile.avatar})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backfaceVisibility: "hidden",
            }}
        >
            <MKBox height="420px" display="flex" flexDirection="column" justifyContent="center" px={3} width="100%">
                <MKTypography variant="h3" color="white" gutterBottom>
                    {profile.firstname}, {profile.birthday ? convertDateToAge(profile.birthday) : profile.age}
                </MKTypography>

                <MKBox display="flex" justifyContent="space-between" mb={2}>
                    <MKTypography variant="body2" color="white" fontWeight="bold">from:</MKTypography>
                    <MKTypography variant="body2" color="white" fontWeight="bold"
                                  textAlign="end">{profile.city} ({profile.country?.name})</MKTypography>
                </MKBox>

                <MKBox display="flex" justifyContent="space-between" mb={2}>
                    <MKTypography variant="body2" color="white" fontWeight="bold">speaks:</MKTypography>
                    <MKTypography variant="body2" color="white" fontWeight="bold" textAlign="end">
                        {profile.languages && profile.languages.map((l, i) =>
                            profile.languages?.length !== (i + 1)
                                ? <Fragment key={'lang-' + i}>{l.name}, </Fragment>
                                : <Fragment key={'lang-' + i}>{l.name}</Fragment>)}
                    </MKTypography>
                </MKBox>

                <MKBox display="flex" justifyContent="space-between" mb={2}>
                    <MKTypography variant="body2" color="white" fontWeight="bold">hobbies:</MKTypography>
                    <MKTypography variant="body2" color="white" fontWeight="bold" textAlign="end">
                        {profile.hobbies && profile.hobbies.map((h, i) =>
                            profile.hobbies?.length !== (i + 1)
                                ? <Fragment key={'front-' + i}>{h.name}, </Fragment>
                                : <Fragment key={'front-' + i}>{h.name}</Fragment>)}
                    </MKTypography>
                </MKBox>

                <MKBox display="flex" justifyContent="space-between" mb={2}>
                    <MKTypography variant="body2" color="white" fontWeight="bold">goal:</MKTypography>
                    <MKTypography variant="body2" color="white" fontWeight="bold"
                                  textAlign="end">{profile.goal?.name ?? ''}</MKTypography>
                </MKBox>

                <MKBox display="flex" justifyContent="space-between" mb={2}>
                    <MKTypography variant="body2" color="white" fontWeight="bold">zodiac:</MKTypography>
                    <MKTypography variant="body2" color="white" fontWeight="bold" textAlign="end">
                        {profile.birthday ? convertDateToZodiacSign(profile.birthday)?.name : profile.zodiac}
                    </MKTypography>
                </MKBox>

                <MKButton variant="outlined" sx={{mt: 4}}>
                    <FontAwesomeIcon icon={faUndo} style={{marginRight: "8px"}}/>
                    Turn Around
                </MKButton>

            </MKBox>
        </MKBox>
    );
}

// Setting default props for the RotatingCardFront
RotatingCardFront.defaultProps = {
    color: "info",
    icon: "",
};

// Typechecking props for the RotatingCardFront
RotatingCardFront.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    profile: PropTypes.object.isRequired
};

export default RotatingCardFront;
