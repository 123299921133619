import {Fragment, useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {Badge, Drawer, ListItem, Menu, MenuItem} from '@mui/material';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MKTypography from 'components/basic/MKTypography';
import MKAvatar from 'components/basic/MKAvatar';
import breakpoints from "../../../../assets/theme/base/breakpoints";
import MKBox from 'components/basic/MKBox';
import MKButton from "../../../basic/MKButton";
import {faBan, faEllipsisV, faEye, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import deletedAccountAvatar from "../../../../assets/images/defaults/deleted-account.jpeg";

const drawerWidth = 260;
const screenSizeLimit = breakpoints.values.md;

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        padding: '0 3px',
        height: '15px',
        minWidth: '15px',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
        },
    }
}));

const openedMixin = (theme) => ({
    background: 'transparent',
    position: 'relative',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    background: 'transparent',
    position: 'relative',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const MobileDrawer = Drawer;
const DesktopDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const ContactsList = ({
                          contacts,
                          selectedContact,
                          selectContact,
                          toggle,
                          screenSizeValue,
                          deleteContact,
                          blockContact,
                          viewContact
                      }) => {
    const [openDrawer, setOpenDrawer] = toggle;
    const [DrawerView, setDrawerView] = useState(screenSizeValue < screenSizeLimit ? MobileDrawer : DesktopDrawer);
    // Variables and functions for dropdown menu
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event, index) => {
        selectContact(index);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const listButton = (contact, index) =>
        <ListItemButton
            key={`list-button-${index}`}
            selected={contacts.indexOf(selectedContact) === index}
            onClick={() => selectContact(index)}
        >
            <ListItemIcon>
                {
                    contact.newMessages && contact.newMessages.length ?
                        <StyledBadge badgeContent={contact.newMessages.length}
                                     size="xs"
                                     color="info"
                                     anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        >
                            <MKAvatar
                                src={contact?.avatar ? process.env.REACT_APP_API_URL + '/' + contact?.avatar : deletedAccountAvatar}
                                alt={contact?.firstname ?? 'avatar'} size="sm"/>
                        </StyledBadge>
                        :
                        <MKAvatar
                            src={contact?.avatar ? process.env.REACT_APP_API_URL + '/' + contact?.avatar : deletedAccountAvatar}
                            alt={contact?.firstname ?? 'avatar'} size="sm"/>
                }


            </ListItemIcon>
            <ListItemText primary={
                <MKTypography variant="body2" sx={{fontWeight: "500"}}>
                    {contact?.firstname ?? 'Deleted'}
                </MKTypography>
            }
            />
        </ListItemButton>

    useEffect(() => {
        setDrawerView(screenSizeValue < screenSizeLimit ? MobileDrawer : DesktopDrawer)
    }, [screenSizeValue])

    const list = contacts => {
        return (
            <List component="nav" sx={{minWidth: `${drawerWidth}px`}}>
                {contacts.length ? contacts.map((contact, index) =>
                        <Fragment key={`contact-${index}`}>
                            {openDrawer
                                ?
                                <ListItem
                                    secondaryAction={
                                        <MKButton
                                            iconOnly
                                            size="small"
                                            circular
                                            aria-controls={openMenu ? 'long-menu' : undefined}
                                            aria-expanded={openMenu ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={e => handleClick(e, index)}
                                            style={{margin: '0 10px', padding: '15px'}}
                                        >
                                            <FontAwesomeIcon icon={faEllipsisV} size="sm"/>
                                        </MKButton>
                                    }
                                    disablePadding
                                >
                                    {listButton(contact, index)}
                                </ListItem>
                                :
                                listButton(contact, index)
                            }
                        </Fragment>
                    )
                    : null
                }
            </List>
        )
    }

    return (
        <>
            <CssBaseline/>
            <DrawerView
                sx={{zIndex: 100}}
                variant={screenSizeValue < screenSizeLimit ? 'temporary' : 'permanent'}
                open={openDrawer}
                onClose={() => setOpenDrawer(!openDrawer)}
            >
                <MKBox
                    display={screenSizeValue < screenSizeLimit ? 'flex' : 'none'}
                    alignItems="center"
                    justifyContent="center" sx={{py: 1}}
                >
                    <MKTypography
                        variant="body1"
                    >
                        Contacts
                    </MKTypography>
                </MKBox>
                {list(contacts)}
            </DrawerView>

            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
            >
                {typeof selectedContact === "object" ?
                    <MenuItem onClick={() => {
                        setOpenDrawer(false);
                        viewContact(selectedContact)
                    }}>
                        <FontAwesomeIcon icon={faEye} style={{marginRight: '10px'}}/>View
                    </MenuItem>
                    : null
                }
                <MenuItem onClick={() => {
                    setOpenDrawer(false);
                    deleteContact(selectedContact)
                }}>
                    <FontAwesomeIcon icon={faTrash} style={{marginRight: '10px'}}/>Delete
                </MenuItem>
                {typeof selectedContact === "object" ?
                    <MenuItem onClick={() => {
                        setOpenDrawer(false);
                        blockContact(selectedContact)
                    }}>
                        <FontAwesomeIcon icon={faBan} style={{marginRight: '10px'}}/>Block
                    </MenuItem>
                    : null
                }
            </Menu>
        </>
    );
}

export default ContactsList;