import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    List,
    Grid,
    ListItem,
    Switch,
    TextField,
} from "@mui/material";
import MKBox from "../../basic/MKBox";
import MKTypography from "../../basic/MKTypography";
import Card from "@mui/material/Card";

// icons
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignal, faHeart} from "@fortawesome/free-solid-svg-icons";

const sortBy = [
    {column: '', order: '', name: 'default'},
    {column: 'birthday', order: 'desc', name: 'age (lower to higher)'},
    {column: 'birthday', order: 'asc', name: 'age (higher to lower)'},
    {column: 'createdAt', order: 'desc', name: 'newest'},
    {column: 'likes', order: 'desc', name: 'popular'}
];

const SearchSortBar = ({isMobile, sort, onSort, onlineFirstToggle, myLikesToggle}) => {
    const [showOnline, setShowOnline] = onlineFirstToggle;
    const [showMyLikes, setShowMyLikes] = myLikesToggle;

    const toggleSwitch = (toggler, value) => {
        switch (toggler) {
            case 'online':
                if (value) {
                    setShowOnline(true);
                    if (showMyLikes) setShowMyLikes(false);
                } else setShowOnline(false);
                break;
            case 'like':
                if (value) {
                    setShowMyLikes(true);
                    if (showOnline) setShowOnline(false);
                } else setShowMyLikes(false);
                break;
            default:
                break;
        }
    }

    const sortByTool = (
        <MKBox display="flex" alignItems="center" xs={{w: '100%'}}>
            {!isMobile &&
                <MKTypography fontSize={14} color={"text"}>Sort by</MKTypography>
            }

            <Autocomplete
                sx={{
                    ml: 1,
                    mt: 1,
                    '& input': {
                        color: '#aaa',
                        fontSize: isMobile ? 16 : 12,
                        width: isMobile ? '100%!important' : ''
                    }
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={isMobile ? "sort by" : null}
                        variant="standard"
                        size="small"

                        style={{
                            backgroundColor: 'white',
                            borderRadius: 5,
                            minWidth: "180px",
                            paddingLeft: '5px'
                        }}
                    />
                }
                renderOption={(props, option, {selected}) => (
                    <li {...props} style={{padding: 0, margin: 0}}>
                        <MKTypography sx={{
                            color: '#aaa',
                            fontSize: isMobile ? 16 : 12,
                            padding: 1
                        }}
                        >
                            {option.name}
                        </MKTypography>
                    </li>
                )}
                options={sortBy}
                value={sort ?? sortBy[0]}
                onChange={(e, v) => onSort(v)}
                getOptionLabel={(option) => option.name ?? ''}
                isOptionEqualToValue={(option, value) => option.name === value.name}
            />
        </MKBox>
    );

    if (isMobile) return (
        <List>
            <MKTypography variant="h5" color="warning" fontWeight="bold" sx={{textAlign: 'center', mb: 1}}>
                Sort
            </MKTypography>
            <ListItem sx={{my: 2}}>
                {sortByTool}
            </ListItem>
            <ListItem sx={{my: 2}}>
                <FontAwesomeIcon icon={faSignal} style={{marginRight: '.75em'}}/>
                <MKTypography color="text" fontSize={15}>Online first</MKTypography>
                <Switch
                    sx={{ml: 'auto'}}
                    edge="end"
                    checked={showOnline}
                    onChange={() => toggleSwitch('online', !showOnline)}
                />
            </ListItem>
            <ListItem sx={{my: 2}}>
                <FontAwesomeIcon icon={faHeart} style={{marginRight: '.75em'}}/>
                <MKTypography color="text" fontSize={15}>Liked first</MKTypography>
                <Switch
                    sx={{ml: 'auto'}}
                    edge="end"
                    checked={showMyLikes}
                    onChange={() => toggleSwitch('like', !showMyLikes)}
                />
            </ListItem>
        </List>
    )
    else return (
        <Card
            sx={{
                px: 2,
                mt: 0,
                mb: 2,
                borderRadius: 2,
                boxShadow: ({boxShadows: {xxl}}) => xxl,
                backgroundColor: '#e7e7e7'
            }}
        >
            <Grid display="flex" alignItems="center" justifyContent="space-between">
                {sortByTool}

                <MKBox display="flex">


                    <MKBox display="flex" alignItems="center" marginRight={2}>
                        <Switch checked={showMyLikes} onChange={() => toggleSwitch('like', !showMyLikes)}/>
                        <MKTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            ml={1}
                            sx={{cursor: "pointer", userSelect: "none", fontWeight: showMyLikes ? 'bold' : 'regular'}}
                            onClick={() => toggleSwitch('like', !showMyLikes)}
                        >
                            Liked first
                        </MKTypography>
                    </MKBox>

                    <MKBox display="flex" alignItems="center">
                        <Switch checked={showOnline} onChange={() => toggleSwitch('online', !showOnline)}/>
                        <MKTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            ml={1}
                            sx={{cursor: "pointer", userSelect: "none", fontWeight: showOnline ? 'bold' : 'regular'}}
                            onClick={() => toggleSwitch('online', !showOnline)}
                        >
                            Online first
                        </MKTypography>
                    </MKBox>
                </MKBox>
            </Grid>
        </Card>
    );
};

export default SearchSortBar;