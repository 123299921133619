import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/basic/MKBox";
import MKTypography from "components/basic/MKTypography";
import MKInput from "components/basic/MKInput";
import MKButton from "components/basic/MKButton";
import macbook from "assets/images/macbook.png";
import React, {useEffect, useState} from "react";
import {emailValidFormat} from "../../../utils/constants";
import {REGISTER_URL} from "../../../serverUrls";
import useServer from "../../../hooks/useServer";
import {toast, ToastContainer} from "react-toastify";
import {toastConfig} from "../../../utils/utils";
import {DotLoader} from "react-spinners";

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [canSubscribe, setCanSubscribe] = useState(false);
    const [subscribeResponse, subscribeError, subscribeIsLoading, subscribeSendRequest, subscribeSetError] = useServer(REGISTER_URL);

    const handleEmailChange = e => {
        const value = e.target.value;
        if (value.trim().length > 0 && value.match(emailValidFormat)) {
            setCanSubscribe(true);
        } else if (canSubscribe) setCanSubscribe(false);
        setEmail(e.target.value);
    }
    const handleSubscribe = () => {
        if (email) {
            subscribeSendRequest({method: 'post', data: {email, password: '123456'}});
            setEmail('');
            setCanSubscribe(false);
        }
    }

    // On Subscribe response
    useEffect(() => {
        if (subscribeResponse) {
            toast.success('You have successfully subscribed', toastConfig);
        }
    }, [subscribeResponse])

    // On Error
    useEffect(() => {
        if (subscribeError) {
            toast.error(subscribeError.data?.message ?? subscribeError.message ?? 'Something went wrong', toastConfig);
            subscribeSetError(null);
        }
        return () => subscribeSetError(null);
    }, [subscribeError])

    return (
        <MKBox component="section" my={2}>
            <ToastContainer/>
            <Container>
                <Grid container alignItems="center">
                    <Grid item sx={12} md={6} sx={{ml: {xs: 0, lg: 3}, mb: {xs: 12, md: 0}}}>
                        <MKTypography variant="h4" color="white">Be the first to see the news</MKTypography>
                        <MKTypography variant="body2" color="white" mb={3}>
                            Your company may not be in the software business, but eventually, a software company
                            will be in your business.
                        </MKTypography>
                        <Grid container spacing={1}>
                            {subscribeIsLoading ?
                                <MKBox display="flex" mx="auto"><DotLoader color="white"/></MKBox>
                                :
                                <>
                                    <Grid item xs={8}>
                                        <MKInput type="email" label="Email Here..." fullWidth
                                                 value={email}
                                                 onChange={handleEmailChange}
                                                 inputProps={{style: {color: "white"}}}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MKButton variant="contained" color="success" sx={{height: "100%"}}
                                                  disabled={!canSubscribe} onClick={handleSubscribe}>
                                            Subscribe
                                        </MKButton>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ml: "auto"}}>
                        <MKBox position="relative">
                            <MKBox component="img" src={macbook} alt="macbook" width="100%"/>
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Newsletter;
