import React, {useContext, useEffect, useRef, useState} from 'react';
import {AppBar, Tab, Tabs} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiceSix, faGlassCheers, faHeart, faQuestion, faSmileWink} from "@fortawesome/free-solid-svg-icons";
import MKBox from "../../../basic/MKBox";
import 'swiper/css';
import StartGame from "./StartGame";
import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css";
import LetsMatchViewList from "./LetsMatchViewList";
import useServer from "../../../../hooks/useServer";
import {MATCH, PROFILE_FAVORITE} from "../../../../serverUrls";
import {SpinnerContext} from "../../../../contexts/spinner/Spinner";
import {toast} from "react-toastify";
import {ScreenSizeContext} from "../../../../contexts/screenSize/ScreenSizeContext";
import breakpoints from "../../../../assets/theme/base/breakpoints";
import {useLocation, useNavigate} from "react-router-dom";
import {links} from "../../../../utils/constants";
import {toastConfig} from "../../../../utils/utils";
import {AuthContext} from "../../../../contexts/auth/AuthContext";
import Card from "@mui/material/Card";
import MKBadge from "../../../basic/MKBadge";
import {NotificationContext} from "../../../../contexts/notifications/NotificationContext";

const LetsMatch = ({myProfileState}) => {
    const swiperRef = useRef(null);
    const {state} = useLocation();
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [activeTab, setActiveTab] = useState(0);
    const [match, setMatch] = useState();
    const [checkMatchProfileId, setCheckMatchProfileId] = useState();
    const [checkMatchAnswer, setCheckMatchAnswer] = useState();
    const [chatProfileId, setChatProfileId] = useState();
    const [addFavoriteResponse, addFavoriteError, addFavoriteIsLoading, addFavoriteSendRequest, addFavoriteSetError] = useServer(PROFILE_FAVORITE + '/' + chatProfileId);
    const [matchResponse, matchError, matchIsLoading, matchSendRequest, matchSetError] = useServer(MATCH);
    const [checkMatchResponse, checkMatchError, checkMatchIsLoading, checkMatchSendRequest, checkMatchSetError] = useServer(MATCH + '/' + checkMatchProfileId);
    const {screenSizeValue} = useContext(ScreenSizeContext);
    const [profiles, setProfiles] = useState(null);
    const {getUser, setUser} = useContext(AuthContext);
    const {notification, clearInterested} = useContext(NotificationContext);
    const navigate = useNavigate();

    const handleTabType = (event, newValue) => {
        swiperRef.current?.swiper.slideTo(newValue);
        setActiveTab(newValue)
    };

    const onChangeList = (listName, operation, profileId) => {
        if (getUser()?.activated && listName && operation && profileId) {
            matchSendRequest({method: 'post', data: {profileId, operation, listName}});
        }
    }

    useEffect(() => {
        if (activeTab === 3) {
            clearInterested();
        }
    }, [activeTab])

    // Add favorite to profile
    useEffect(() => {
        if (chatProfileId && getUser()?.activated) addFavoriteSendRequest({method: 'patch', data: myProfileState.myProfile});
    }, [chatProfileId])

    // On add favorite response
    useEffect(() => {
        if (addFavoriteResponse) {
            setUser({...getUser(), profile: addFavoriteResponse.data});
            navigate(links.myProfile, {
                state:
                    {
                        path: links.communication,
                        myProfile: addFavoriteResponse.data,
                        profileId: chatProfileId
                    }
            });
        }
    }, [addFavoriteResponse])

    // Send request to check if favorite contact removed users profile from interested list
    useEffect(() => {
        if (checkMatchProfileId) {
            checkMatchSendRequest({method: 'get'});
        }
    }, [checkMatchProfileId])

    // On check match response
    useEffect(() => {
        if (checkMatchResponse) {
            setCheckMatchAnswer(checkMatchResponse.data);
            setCheckMatchProfileId(null);
        }
    }, [checkMatchResponse])

    // On Load Page
    useEffect(() => {
        matchSendRequest({method: 'get'});
    }, [])

    // On Match Response or Change response
    useEffect(() => {
        if (matchResponse) {
            const favorite = matchResponse.data?.favorite;
            const interested = matchResponse.data?.interested;
            if (favorite && interested && favorite.length > 0 && interested.length > 0) {
                let pairs = [];
                interested.map(interestedItem => favorite.map(favoriteItem => {
                    if (notification?.interested?.length && notification.interested.includes(favoriteItem._id)) {
                        interestedItem.isNew = true;
                    }
                    if (interestedItem._id === favoriteItem._id) pairs.push(interestedItem)
                }));
                setMatch({...matchResponse.data, pairs});
            } else setMatch(matchResponse.data);
        }
    }, [matchResponse])

    // On Error
    useEffect(() => {
        if (checkMatchError) {
            toast.error(checkMatchError.data?.message ?? 'Match not checked');
            checkMatchSetError(null);
        }
        if (matchError) {
            toast.error(matchError.data?.message ?? 'Match is NOT loaded');
            matchSetError(null);
        }
        if (addFavoriteError) {
            toast.error(addFavoriteError.data?.message ?? 'Profile NOT added', toastConfig);
            addFavoriteSetError(null);
        }
        return () => {
            checkMatchSetError(null);
            matchSetError(null);
            addFavoriteSetError(null);
        }
    }, [checkMatchError, matchError, addFavoriteSetError])

    // On Loading
    useEffect(() => {
        if (matchIsLoading || checkMatchIsLoading || addFavoriteIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [matchIsLoading, checkMatchIsLoading, addFavoriteIsLoading])

    // If state exists
    useEffect(() => {
        if (state) {
            if (state.profiles) setProfiles(state.profiles);
        }
    }, [state])

    return (
        <Card sx={{p: 2, mx: 0, mt: -2, mb: 2, minHeight: '100%', height: '100%', boxShadow: 'xxl'}}>
            <MKBox justifyContent="center" mx="auto" mb={3} sx={{zIndex: 1}}>
                <AppBar position="static">
                    <Tabs value={activeTab} onChange={handleTabType} variant="fullWidth">
                        <Tab label="Play" icon={<FontAwesomeIcon icon={faDiceSix}/>} sx={{px: 2}}/>
                        <Tab label={screenSizeValue > breakpoints.values.md ? "Draft" : null}
                             icon={<FontAwesomeIcon icon={faQuestion}/>} sx={{px: 2}}/>
                        <Tab label={screenSizeValue > breakpoints.values.md ? "Favorites" : null}
                             icon={<FontAwesomeIcon icon={faHeart}/>} sx={{px: 2}}/>
                        <Tab label={screenSizeValue > breakpoints.values.md ? "Interested" : null}
                             icon={
                                 notification?.interested?.length ?
                                     <MKBadge badgeContent={notification.interested.length} color="success" size="md"
                                              circular sx={{ml: -2, mb: -2, mr: 1}}/>
                                     : <FontAwesomeIcon icon={faSmileWink}/>
                             }
                             sx={{px: 2}}/>
                        <Tab label={screenSizeValue > breakpoints.values.md ? "Matches" : null}
                             icon={<FontAwesomeIcon icon={faGlassCheers}/>} sx={{px: 2}}/>
                    </Tabs>
                </AppBar>
            </MKBox>
            <Swiper
                style={{width: '100%'}}
                ref={swiperRef}
                allowTouchMove={false}
                slidesPerView="auto"
                onSlideChange={e => setActiveTab(e.realIndex)}
            >
                <SwiperSlide>
                    <StartGame myProfile={myProfileState.myProfile} changeList={onChangeList} inputProfiles={profiles}
                               inputMatches={match} startChat={id => setChatProfileId(id)} active={getUser()?.activated}/>
                </SwiperSlide>
                <SwiperSlide>
                    <LetsMatchViewList title="Your waiting list" listType="waiting" list={match?.waiting ?? []}
                                       changeList={onChangeList}
                                       startChat={id => setChatProfileId(id)}/>
                </SwiperSlide>
                <SwiperSlide>
                    <LetsMatchViewList title="Your favorites" listType="favorite" list={match?.favorite ?? []}
                                       changeList={onChangeList}
                                       checkMatch={profileId => setCheckMatchProfileId(profileId)}
                                       answer={checkMatchAnswer}
                                       startChat={id => setChatProfileId(id)}/>
                </SwiperSlide>
                <SwiperSlide>
                    <LetsMatchViewList title="Who interested you" listType="interested" list={match?.interested ?? []}
                                       changeList={onChangeList}
                                       startChat={id => setChatProfileId(id)}/>
                </SwiperSlide>
                <SwiperSlide>
                    <LetsMatchViewList title="Matches" listType="matches" list={match?.pairs ?? []}
                                       changeList={onChangeList}
                                       startChat={id => setChatProfileId(id)}/>
                </SwiperSlide>
            </Swiper>
        </Card>
    );
};

export default LetsMatch;