import {createContext, useState} from "react";
import {css} from "@emotion/react";
import {RiseLoader} from "react-spinners";
import Grid from "@mui/material/Grid";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const SpinnerContext = createContext(undefined);

const SpinnerProvider = ({children}) => {
    const [showSpinner, setShowSpinner] = useState(false);

    return (
        <SpinnerContext.Provider value={[showSpinner, setShowSpinner]}>
            {showSpinner
                ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            minHeight: '100vh',
                            position: 'fixed', left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: "#000000",
                            opacity: .6,
                            zIndex: 1000
                        }}
                    >
                        <RiseLoader
                            color={'#e3d843'} loading={true} css={override} size={15} margin={2}/>
                    </Grid>
                )
                : null
            }
            {children}
        </SpinnerContext.Provider>
    );
}

export default SpinnerProvider;